import clsx from 'clsx';
import React from 'react';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import Tooltip from '../../libs/context-tooltip/tooltip';
import { useReportAddCollaboratorMutation } from '../../trpcHooks/useReportAddCollaboratorMutation';
import { useReportDeleteCollaboratorMutation } from '../../trpcHooks/useReportDeleteCollaboratorMutation';
import {
  ServerAddCollaborator,
  ServerReport,
  ServerReportUser,
  ServerUser,
  trpc
} from '../../utils/trpc';
import Icon from '../icon/icon';
import { TrashIcon } from '../icons/icons';
import styles from './users.module.scss';

interface UsersProps extends React.HTMLProps<HTMLDivElement> {
  report: ServerReport;
  user: ServerUser;
}

const canModifyUsersFn = (me: ServerUser | undefined | null, report: ServerReport,) => {
  if (me == null) return false;
  if (me.isSuperAdmin) return true;
  if (report.userId === me._id) return true;

  const colab = report.collaborators?.find((c) => c.userId === me._id);
  if (colab?.permission === 'a') return true;

  return false;
};

export const Users: React.FC<UsersProps> = React.memo(({ report, className, user }) => {
  const reportId = report._id.toString();
  const reportUsersQuery = trpc.reportUsers.get.useQuery({ id: reportId });
  const amICollaborator = report.collaborators?.find((c) => c.userId && c.userId === user?._id);
  const canModifyUsers = canModifyUsersFn(user, report);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.scrollable}>
        {reportUsersQuery.isLoading && (
          <div>
            <WaveSpinner />
          </div>
        )}
        <div className={styles.content}>
          <Icon fakeText={user?.email?.[0]} rounded className={styles.icon} />
          <div className={styles.email}>{user?.email}</div>
          {!amICollaborator && <div className={styles.userType}>me</div>}
          {amICollaborator && <div className={styles.userType}>{amICollaborator?.permission}</div>}
          <div />

          {reportUsersQuery.data && (
            <Collaborators
              report={report}
              usersById={reportUsersQuery.data}
              canModifyUsers={canModifyUsers}
              user={user}
            />
          )}

          {canModifyUsers && <AddUser reportId={report._id.toHexString()} />}
        </div>
      </div>
    </div>
  );
});

interface CollaboratorsProps {
  report: ServerReport;
  usersById: Record<string, ServerReportUser>;
  canModifyUsers: boolean;
  user: ServerUser;
}
const Collaborators: React.FC<CollaboratorsProps> = ({ report, canModifyUsers, usersById, user }) => {
  const deleteCollaboratorMutation = useReportDeleteCollaboratorMutation();

  return (
    <>
      {report.collaborators?.map((collaborator) => {
        const collaboratorId = collaborator.userId;
        if (collaboratorId == null) return null;
        if (collaboratorId === user?._id) return null;
        const userColaborator = usersById?.[collaboratorId];

        const userEmail = userColaborator?.email || userColaborator?._id || collaborator?.userId;
        return (
          <React.Fragment key={collaboratorId}>
            <Icon fakeText={userEmail?.[0]} rounded className={styles.icon} />
            <div className={styles.email}>{userEmail}</div>
            {!canModifyUsers && <div className={styles.userType}>{collaborator.permission}</div>}
            {canModifyUsers && (
              <>
                <div className={styles.userType}>
                  <select value={collaborator.permission} disabled>
                    <option value="a">admin</option>
                    <option value="w">write</option>
                    <option value="r">read</option>
                  </select>
                </div>
                <div
                  className={styles.trash}
                  onClick={() => {
                    deleteCollaboratorMutation.mutate({
                      id: report._id.toHexString(),
                      collaboratorId,
                    });
                  }}>
                  <Tooltip tagName={TrashIcon} content="remove member" />
                </div>
              </>
            )}
            {!canModifyUsers && <div />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Users;

interface AddUserProps {
  reportId: string;
}

const AddUser: React.FC<AddUserProps> = ({ reportId }) => {
  const [data, setData] = React.useState<ServerAddCollaborator>({ email: '', permission: 'r' });
  const addCollaboratorMutation = useReportAddCollaboratorMutation();

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addCollaboratorMutation.mutate({ id: reportId, data });
  };
  const onMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setData((data) => ({ ...data, email }));
  };
  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const permission = e.target.value;
    if (permission === 'r' || permission === 'w' || permission === 'a') {
      setData((data) => ({ ...data, permission }));
    }
  };

  return (
    <form onSubmit={submit} className={styles.addMember}>
      <div />
      <input
        placeholder="add member"
        className={styles.input}
        value={data.email || ''}
        onChange={onMemberChange}
      />
      <div>
        <select value={data.permission} onChange={onSelectChange}>
          <option value="a">admin</option>
          <option value="w">write</option>
          <option value="r">read</option>
        </select>
      </div>
      <input type="submit" value="add" className={styles.button} />
    </form>
  );
};
