import clsx from 'clsx';
import { ReviewCard } from '../../../PoseidonComponents/ReviewCard/ReviewCard';
import { DraggerHandle, TrashCan } from '../../../PoseidonComponents/StrokeIcons';
import { SvgIconButton } from '../../../PoseidonComponents/SvgIconButton/SvgIconButton';
import { useRemoveReview } from '../../../trpcHooks/useReportInsightSummary';
import { useSortReviewIds } from '../../../trpcHooks/useReportInsightSummary';
import { reOrder } from '../../../utils/dragAndDrop';
import { ReportCategory, ReportInsightSummaryReview, ServerReport } from '../../../utils/trpc';
import styles from './ReportInsightSection.module.scss';
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Droppable } from '../Droppable';
import { Draggable } from '../Draggable';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface ReviewDragAndDropProps {
  sectionId: string;
  report: ServerReport;
  reviews: ReportInsightSummaryReview[];
  conceptsById: Record<string, ReportCategory>;
}

export const ReviewDragAndDrop: React.FC<ReviewDragAndDropProps> = ({
  reviews,
  sectionId,
  report,
  conceptsById,
}) => {
  const sortReviewIdsMutation = useSortReviewIds();
  const removeReviewMutation = useRemoveReview();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const onDragEnd = async (event: DragEndEvent) => {
    const activeId = event.active.id;
    const overId = event.over?.id;

    if (overId == null) return;
    if (overId == activeId) return;

    const massById = reviews?.map((el) => el._id.toHexString());

    const activeIndex = massById.findIndex((el) => el === activeId);
    const overIndex = massById.findIndex((el) => el === overId);

    const reviewIds = arrayMove(massById, activeIndex, overIndex);

    await sortReviewIdsMutation.mutateAsync({
      id: sectionId,
      reportId: report._id.toHexString(),
      reviewIds,
    });
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={onDragEnd}>
      <SortableContext
        items={reviews.map((el) => el._id.toHexString())}
        strategy={verticalListSortingStrategy}>
        <Droppable className={styles.reviews} id={sectionId}>
          {reviews?.map((review) => {
            const id = review._id.toHexString();
            return (
              <Draggable
                id={id}
                key={id}
                sectionId={sectionId}
                review={review}
                report={report}
                conceptsById={conceptsById}
              />
            );
          })}
        </Droppable>
      </SortableContext>
    </DndContext>
  );
};
