import { isString } from 'lodash';
import React from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';
import { IAggregatedCounts } from '../../models/aggModels/aggregatedCounts.entity';
import { SentimentFacets } from '../../utils/trpc';
import { getRangeColor2, sentimentWords } from '../../utils2';
import styles from './RatingsSection.module.scss';
import { Row } from './Row';

interface SentimentRatingSectionProps {
  data: IAggregatedCounts | undefined;
  facetData?: SentimentFacets;
}
export const SentimentRatingSection: React.FC<SentimentRatingSectionProps> = ({
  data,
  facetData,
}) => {
  const [search, setSearch] = useRouterQuery();

  const toggleSearch = (v: '5' | '4' | '3' | '2' | '1') => {
    setSearch((s) => ({ ...s, sRating: s.sRating === v ? undefined : v, page: undefined }));
  };

  // generate a counts array from facetData, to match data.bktd
  const facetBKTDData = facetData?.buckets.reduce<[number[], number]>(
    (acc, b) => {
      if (isString(b._id)) {
        return acc;
      }
      if (b._id < -0.6) {
        acc[0][0] = b.count; // sent bucket
        acc[1] += b.count; // total count
      } else if (b._id < -0.2) {
        acc[0][1] = b.count; // sent bucket
        acc[1] += b.count; // total count
      } else if (b._id < 0.2) {
        acc[0][2] = b.count; // sent bucket
        acc[1] += b.count; // total count
      } else if (b._id < 0.6) {
        acc[0][3] = b.count; // sent bucket
        acc[1] += b.count; // total count
      } else if (b._id < 1.1) {
        acc[0][4] = b.count; // sent bucket
        acc[1] += b.count; // total count
      }
      return acc;
    },
    [[0, 0, 0, 0, 0], 0],
  );
  const facetBKTD = facetBKTDData?.[0];
  const totalCountUnsafe = facetBKTDData != null ? facetBKTDData[1] : data?.count;
  const totalCount =
    totalCountUnsafe != null && totalCountUnsafe <= 0 ? undefined : totalCountUnsafe;

  // in the incoming data the sentiment buckets are orders lowest
  // to highest, starting at index 0 to index 4
  // const sentiments = [ 5, 4, 3, 2, 1 ] as const
  const counts = [5, 4, 3, 2, 1] as const;

  return (
    <div className={styles.ratings}>
      {counts?.map((s, i) => {
        // go through each sentiment bucket

        const color = getRangeColor2(s * 20);

        // const count = data?.byReviewSentiment?.[s - 1];
        const countForBucket = facetBKTD != null ? facetBKTD[s - 1] || 0 : data?.bktd?.[s - 1] || 0;

        // what percentage of all the values are in this sentiment bucket
        const percentageOfTotal =
          totalCount != null && totalCount > 0 ? (100 * countForBucket) / totalCount : 0;

        const style = {
          gridRowStart: i + 1,
        } as React.CSSProperties;

        return (
          <Row
            key={s}
            index={i}
            name={sentimentWords[s] || ''}
            style={style}
            count={countForBucket}
            color={color}
            rating={percentageOfTotal}
            selected={search?.sRating === `${s}`}
            tooltip={<div>Click to see {sentimentWords[s]} Reviews</div>}
            onClick={() => toggleSearch(`${s}`)}
          />
        );
      })}
    </div>
  );
};

export default SentimentRatingSection;
