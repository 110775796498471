import { Comment } from '../models/comment.model';
import { Review as ReviewModel } from '../models/review.model';
import { PaginatedArray } from '../paginatedArray';
import { Meta, getDetailRangeColor, getScore, isNotNull } from '../utils2';

const sigmoid = (t: number) => 1 / (1 + Math.pow(Math.E, -t));

export type ReviewContextPropsState = {
  groupedReviews?: Record<string, PaginatedArray<string>>,
  reviewsById: Record<string, Meta<ReviewModel>>,
  commentsByReviewId?: Record<string, Comment[]>,
  reviewsByTags?: Record<string, any>,
}

export type OrdersAction = 
  | ({type: "FETCHED_TAGGED_REVIEWS", reviews: any[], reportId: string, tag: string})

  | ({type: "UPDATE_REVIEW_START", reportId: string, reviewId: string, text: string })
  | ({type: "UPDATE_REVIEW_SUCCESS", reportId: string, reviewId: string, review: ReviewModel })
  | ({type: "UPDATE_REVIEW_ERROR", reportId: string, reviewId: string, error: string, text: string })

  | { type: "RESET" }


export const initialState: ReviewContextPropsState = {
  groupedReviews: undefined,
  reviewsById: {},
  commentsByReviewId: undefined,
  reviewsByTags: undefined
};

export const reducer = (state: ReviewContextPropsState, action: OrdersAction): ReviewContextPropsState => {
  switch(action.type) {
    case 'UPDATE_REVIEW_START': {
      const currentReview = state.reviewsById?.[action.reviewId]

      const currentReviewData: ReviewModel = Object.assign({},
        currentReview?.data,
        { responseMeta: Object.assign({}, currentReview?.data?.responseMeta, {status: 'loading', text: action.text}) }
      )
      const reviewsById: Record<string, Meta<ReviewModel>> = {
        ...state.reviewsById,
        [action.reviewId]: Object.assign({}, currentReview, {data: currentReviewData})
      }

      return { ...state, reviewsById }
    }
    case 'UPDATE_REVIEW_SUCCESS': {
      const reviewsById = {
        ...state.reviewsById,
        [action.reviewId]: {
          data: correctReviewRanges(action.review)
        }
       }
      return { ...state, reviewsById }
    }
    case 'UPDATE_REVIEW_ERROR': {
      const currentReview = state.reviewsById?.[action.reviewId]
      const currentReviewData = Object.assign({},
        currentReview?.data,
        {
          responseMeta: Object.assign({}, currentReview?.data?.responseMeta, {
            status: 'error',
            text: action.text,
            error: action.error
          })
        }
      )
      const reviewsById: Record<string, Meta<ReviewModel>> = {
        ...state.reviewsById,
        [action.reviewId]: Object.assign({}, currentReview, {data: currentReviewData})
      }
      return { ...state, reviewsById }
    }
    
    case 'FETCHED_TAGGED_REVIEWS': {
      const filteredReviews = action.reviews.map(r => {
        return {...r, sm: r.magnitude ?? r.sm, ss: r.sentiment ?? r.ss}
      }).filter(r => r.ss != null && r.sm != null)
      const magAvg = filteredReviews.reduce((p, r) => {
        if (r.sm == null) { return p }
        return p + (r.sm);
      }, 0) / filteredReviews.length;
  
      const reviews = filteredReviews.map(r => {
        return {
          ...r,
          sigSm: sigmoid(r.sm - magAvg) * 2 - 1,
          time: new Date(r.date).getTime(),
          color: getDetailRangeColor(getScore(r.ss)),
        }
      });
  
      const key = `${action.reportId}_${action.tag}`
      const reviewsByTags = { ...state.reviewsByTags, [key]: reviews }
      return { ...state, reviewsByTags }
    }
    
    case 'RESET': {
      return initialState
    }
    default: { return state }
  }
}

const correctReviewRanges = (review: ReviewModel) => {
  if(review == null) return review;

  const ranges = review.ranges || review.sentimentRanges
  if(review.classOverrides != null && review.classOverrides.length > 0) {
    let joinedRanges = [];

    const filteredRanges = (ranges && ranges.filter((r:any) => {
      for(var i = 0; i< review.classOverrides.length; i++) {
        const classOverride = review.classOverrides[i];
        if(classOverride != null && classOverride.b >= r.b && classOverride.b < r.e) {
          return false;
        } else if(classOverride != null && classOverride.b < r.e && classOverride.e >= r.b) {
          return false;
        }
      }
      return true;
    })) || []

    
    let classOverridesIndex = 0;
    for(var i = 0; i< filteredRanges.length; i++) {
      const range = filteredRanges[i];
      for(var j = classOverridesIndex; j<review.classOverrides.length; j++) {
        const classOverride = review.classOverrides[j];
        if(range?.e != null && classOverride.b > range.e) {
          break;
        } else if(range?.b != null && classOverride.e < range.b){
          const correctedOverride = {cats:classOverride.catIds.map((c:any) => ({c, s: classOverride.score})), b: classOverride.b, e: classOverride.e};
          joinedRanges.push(correctedOverride);
          classOverridesIndex+=1;
        }
      }
      joinedRanges.push(range);
    }

    for(var k = classOverridesIndex; k<review.classOverrides.length; k++) {
      const classOverride = review.classOverrides[k];
      const correctedOverride = {cats:classOverride.catIds.map((c: any) => ({c, s: classOverride.score})), b: classOverride.b, e: classOverride.e};
      joinedRanges.push(correctedOverride);
    }
    review.joinedRanges = joinedRanges.filter(isNotNull);
  } else {
    review.joinedRanges = ranges || [];
  }
  return review;
}