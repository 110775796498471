import {
  autoPlacement,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import React, { CSSProperties } from 'react';

export interface Props {
  label: React.ReactNode;
  placement?: Placement;
  children: JSX.Element;
  background?: string;
  color?: string;
  boxShadow?: string;
  autoPlace?: boolean;
  padding?: string;
}

export const DeleteTooltip: React.FC<Props> = (props) => {
  return <Tooltip {...props} background="hsla(0, 65%, 61%, 0.9)" />;
};

export const Tooltip: React.FC<Props> = ({
  children,
  label,
  background,
  color,
  boxShadow,
  padding,
  placement = 'top',
  autoPlace = true,
}) => {
  const [open, setOpen] = React.useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange(open) {
      setOpen(open);
    },
    middleware: [offset(5), autoPlace ? autoPlacement({ padding: 8 }) : flip({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { mouseOnly: true }),
    useRole(context, { role: 'tooltip' }),
  ]);

  const _background = background || 'hsla(0, 0%, 10%, 0.9)';

  const style: CSSProperties = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
    borderRadius: 4,
    zIndex: 1000000,
    fontWeight: 'normal',
    transform: 'unset',
    opacity: 1,
    transition: 'opacity 200ms',
    padding: padding || '4px 6px',
    fontSize: 14,
    color: color || '#fff',
    maxWidth: 400,
    background: background || 'hsla(0, 0%, 10%, 0.9)',
    boxShadow: boxShadow || `0px 2px 4px ${_background}`,
    pointerEvents: 'none',
    overflow: 'hidden',
  };
  return (
    <>
      {React.cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
      <FloatingPortal id="tooltip">
        {label && (
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, scale: 0.85 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', damping: 20, stiffness: 300 }}
                {...getFloatingProps({ ref: floating, style: style })}>
                {label}
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </FloatingPortal>
    </>
  );
};
