import React from 'react'
import clsx from 'clsx'

import styles from './filterSection.module.scss'

interface FilterSectionProps extends React.HTMLProps<HTMLDivElement> {
  name: string,
  showingMore?: boolean,
  setShowingMore?: any,
  minimized?: boolean,
}

export const FilterSection: React.FC<FilterSectionProps> = ({name, showingMore, setShowingMore, minimized, children}) => {
  return <div className={clsx(styles.section, minimized ? null : styles.opened)}>
    <div className={styles.sectionHeader}>
      <span>{name}</span>
      {setShowingMore != null && <>
        {!showingMore && <button className={styles.button}  onClick={() => setShowingMore(!showingMore)}>more</button>}
        {showingMore && <button className={styles.button} onClick={() => setShowingMore(!showingMore)}>less</button>}
      </>}
    </div>
    {children}
  </div>
}

export default FilterSection