import useComponentSize from '@rehooks/component-size';
import { keyBy } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import Card from '../../components/card/card';
import InsightPolarChart from '../../components/insightPolarChart/insightPolarChart';
import { Point } from '../../models/point.model';
import {
  ReportCategory,
  ServerDataCounts,
  ServerReport,
  ServerReportBrand,
} from '../../utils/trpc';
import { BrandPicker } from './BrandPicker';
import { BrandPopup } from './BrandPopup';
import styles from './PolarCard2.module.scss';

interface PolarCardProps {
  report: ServerReport;
  concepts?: ReportCategory[];
  sortedBrands?: ServerReportBrand[];
  dataCounts?: ServerDataCounts;
}

export const PolarCard: React.FC<PolarCardProps> = ({
  dataCounts,
  report,
  concepts,
  sortedBrands,
}) => {
  if (sortedBrands == null) return <LoadingCard />;
  if (dataCounts == null) return <LoadingCard />;
  if (concepts == null) return <LoadingCard />;

  return (
    <PolarCardWithBrands
      report={report}
      concepts={concepts}
      sortedBrands={sortedBrands}
      dataCounts={dataCounts}
    />
  );
};

interface PolarCardWithBrandsData {
  report: ServerReport;
  concepts: ReportCategory[];
  sortedBrands: ServerReportBrand[];
  dataCounts: ServerDataCounts;
}
export const PolarCardWithBrands: React.FC<PolarCardWithBrandsData> = React.memo(
  ({ dataCounts, report, concepts, sortedBrands }) => {
    const ref = React.useRef(null);
    const navigate = useNavigate();
    const { width, height } = useComponentSize(ref);
    const [showingPopupIndex, setShowingPopupIndex] = React.useState<number>();
    const [selectedBrandIds, setSelectedBrandIds] = React.useState<(string | null | undefined)[] >( sortedBrands.slice(0, 2).map((d) => d._id.toHexString()) );
    const reportId = report._id.toHexString();

    const brandById = React.useMemo(
      () => keyBy(sortedBrands, (b) => b._id.toHexString()),
      [sortedBrands],
    );

    const selectedBrands = selectedBrandIds?.map((id) => (id != null ? brandById?.[id] : id));

    const size = Math.min(width, height);
    const filters = (
      <BrandPicker
        selectedBrands={selectedBrands}
        setShowingPopupIndex={setShowingPopupIndex}
        brandCounts={dataCounts?.brands}
      />
    );

    const brandPopupSelect = (index: number, data: string | null | undefined) => {
      setSelectedBrandIds((ids) => ids?.map((d, i) => (i === index ? data : d)));
    };

    const clickCircle = (point: Point) => {
      if (point?.conceptId && point?.brandId) {
        if (navigate)
          navigate(`/reports/${reportId}/brands/${point.brandId}?category=${point.conceptId}`);
      }
    };

    return (
      <Card
        header="Polar Sentiment"
        description="We capture sentiment across key concepts mentioned across customer feedback"
        fileName={`${report.name}_polar.png`}
        filters={filters}>
        <div className={styles.container} ref={ref}>
          {size !== 0 && (
            <InsightPolarChart
              brands={selectedBrands}
              width={size}
              height={size}
              concepts={concepts.filter((c) => c.countInScore)}
              onClickPoint={clickCircle}
              dataCounts={dataCounts}
            />
          )}
        </div>
        {showingPopupIndex != null && (
          <BrandPopup
            setSelectedBrand={brandPopupSelect}
            showingPopupIndex={showingPopupIndex}
            onClose={() => setShowingPopupIndex(undefined)}
            brands={sortedBrands ?? []}
          />
        )}
      </Card>
    );
  },
);

const LoadingCard = () => (
  <Card
    header="Polar Sentiment"
    description="We capture sentiment across key concepts mentioned across customer feedback">
    <WaveSpinner />
  </Card>
);

export default PolarCard;
