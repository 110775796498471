import Color from 'color';
import React, { CSSProperties } from 'react';
import Icon from '../../components/icon/icon';
import { Market } from '../../components/icons/icons';
import { Popup } from '../../libs/popup/popup';
import { ServerReportBrand } from '../../utils/trpc';
import { getColor } from '../../utils2';
import styles from './PolarCard2.module.scss';

interface BrandPopupProps {
  showingPopupIndex: number;
  setSelectedBrand: (index: number, data: string | undefined | null) => void;
  onClose: () => void;
  brands: ServerReportBrand[];
}

export const BrandPopup: React.FC<BrandPopupProps> = ({
  setSelectedBrand,
  showingPopupIndex,
  onClose,
  brands,
}) => {
  const updateSelectedBrandsAtIndex = (index: number, value?: string | null | undefined) => {
    setSelectedBrand(index, value);
    onClose();
  };

  const backgroundColor = Color(getColor(showingPopupIndex ?? 0))
    .lighten(0.1)
    .toString();

  const style = {
    '--color': getColor(showingPopupIndex ?? 0),
    '--background': backgroundColor,
  } as CSSProperties;

  return (
    <Popup onClose={() => onClose()}>
      <div className={styles.popup}>
        <div
          className={styles.brandRow}
          style={style}
          onClick={() => updateSelectedBrandsAtIndex(showingPopupIndex, undefined)}>
          <div className={styles.brandPlaceholderIcon} />
          <div className={styles.brandName}>none</div>
        </div>
        <div
          className={styles.brandRow}
          style={style}
          onClick={() => updateSelectedBrandsAtIndex(showingPopupIndex, null)}>
          <Icon src={Market} rounded />
          <div className={styles.brandName}>Market Average</div>
        </div>

        {brands?.map((brand) => {
          const brandId = brand?._id.toHexString();
          if (brandId == null) return null;
          return (
            <div
              className={styles.brandRow}
              key={brandId}
              style={style}
              onClick={() =>
                updateSelectedBrandsAtIndex(showingPopupIndex, brand._id.toHexString())
              }>
              <Icon src={brand?.imageUrl} rounded background={brand?.dominantColor} />
              <div className={styles.brandName}>{brand?.name}</div>
            </div>
          );
        })}
      </div>
    </Popup>
  );
};
