import React, { KeyboardEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { SearchIcon } from '../icons/icons';

import styles from './search.module.scss';

const CloseIcon: React.FC<React.SVGProps<SVGElement>> = ({ className, onClick }) => (
  <svg viewBox="0 0 64 64" fillRule="evenodd" className={className} onClick={onClick}>
    <path d="M32,64 C14.326888,64 0,49.673112 0,32 C0,14.326888 14.326888,0 32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 Z M32,58 C46.3594035,58 58,46.3594035 58,32 C58,17.6405965 46.3594035,6 32,6 C17.6405965,6 6,17.6405965 6,32 C6,46.3594035 17.6405965,58 32,58 Z M36.2426407,32 L45.7885822,41.5459415 C46.9601551,42.7175144 46.9601551,44.6170094 45.7885822,45.7885822 C44.6170094,46.9601551 42.7175144,46.9601551 41.5459415,45.7885822 L32,36.2426407 L22.4540585,45.7885822 C21.2824856,46.9601551 19.3829906,46.9601551 18.2114178,45.7885822 C17.0398449,44.6170094 17.0398449,42.7175144 18.2114178,41.5459415 L27.7573593,32 L18.2114178,22.4540585 C17.0398449,21.2824856 17.0398449,19.3829906 18.2114178,18.2114178 C19.3829906,17.0398449 21.2824856,17.0398449 22.4540585,18.2114178 L32,27.7573593 L41.5459415,18.2114178 C42.7175144,17.0398449 44.6170094,17.0398449 45.7885822,18.2114178 C46.9601551,19.3829906 46.9601551,21.2824856 45.7885822,22.4540585 L36.2426407,32 Z" />
  </svg>
);

interface SearchProps {
  className?: any;
  placeholder?: any;
  onChange?: any;
  onInstantChange?: any;
  value?: any;
  onSubmit?: any;
  autoFocus?: boolean;
  otherInput?: boolean;
}

export const Search: React.FC<SearchProps> = ({
  className,
  value,
  placeholder,
  onInstantChange,
  onChange,
  onSubmit,
  autoFocus,
  otherInput,
}) => {
  const [internalValue, setInternalValue] = React.useState<string>(value);
  const debounced = useDebouncedCallback((value) => onChange?.(value), 600);
  React.useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onInputChange = (e: any) => {
    debounced(e.target.value);
    setInternalValue(e.target.value);
    if (onInstantChange) onInstantChange(e.target.value);
  };
  const onDelete = () => {
    debounced('');
    setInternalValue('');
    if (onInstantChange) onInstantChange('');
  };

  const _onSubmit = (e: any) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(internalValue);
      onDelete();
    }
  };

  return (
    <form
      className={[
        className,
        styles.container,
        internalValue?.length > 0 ? styles.hasValue : null,
      ].join(' ')}
      onSubmit={_onSubmit}>
      <input
        className={styles.input}
        placeholder={placeholder}
        value={internalValue || ''}
        onChange={onInputChange}
        autoFocus={autoFocus}
      />
      {!otherInput && <SearchIcon className={styles.searchIcon} />}
      <CloseIcon className={styles.deleteIcon} onClick={onDelete} />
    </form>
  );
};

export default Search;
