import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import {
  reportCategoriesV2ListRQKey,
  reportPhrasesListRQKey,
  RQUtils,
  suggestedClustersListRQKey
} from '../../utils/reactQuery.utils';
import {
  CompactReportPhrase,
  CompactReportPhrasesForReport,
  ReportCategoryV2,
  SuggestedCluster,
  trpc
} from '../../utils/trpc';
import { AddablePill, BlankPill, DeletablePill } from '../pills/pills';
import styles from './suggestedCluster.module.scss';

interface SuggestedClusterCompProps {
  reportId: string;
  suggestedCluster: SuggestedCluster;
  reportPhrasesById: Record<string, CompactReportPhrase>;
  conceptsById: Record<string, ReportCategoryV2>;
  active?: any;
  onEdit?: (cluster: SuggestedCluster) => void;
  scrollRef?: any;
}

export const SuggestedClusterComp: React.FC<SuggestedClusterCompProps> = ({
  reportId,
  suggestedCluster,
  reportPhrasesById,
  conceptsById,
  active,
  onEdit,
  scrollRef,
}) => {
  const queryClient = useQueryClient();

  const suggestedClustersListKey = suggestedClustersListRQKey(reportId);
  const conceptsListKey = reportCategoriesV2ListRQKey(reportId);
  const phrasesForReportByIdKey = reportPhrasesListRQKey(reportId);

  const setHiddenClusterMut = trpc.suggestedClusters.changeHidden.useMutation({
    onSuccess: (updatedCluster) => {
      // update the hidden and not hidden suggested clusters queries
      queryClient.setQueryData<SuggestedCluster[]>(suggestedClustersListKey, (old) => {
        // cluster element was updated
        return RQUtils.updateArrQuery(old, updatedCluster);
      });
    },
  });
  const setHiddenCluster = (cluster: SuggestedCluster, hidden: boolean) => {
    if (setHiddenClusterMut.isLoading == true) {
      return;
    }
    setHiddenClusterMut.mutate({ reportId, clusterId: cluster.id, hidden });
  };

  const createConceptFromClusterMut = trpc.suggestedClusters.createConcept.useMutation({
    onSuccess: (updateRes) => {
      const { updatedCluster, newConcept, reportPhrases } = updateRes;
      // update cluster
      queryClient.setQueryData<SuggestedCluster[]>(suggestedClustersListKey, (old) => {
        return RQUtils.updateArrQuery(old, updatedCluster);
      });
      // update concepts
      queryClient.setQueryData<ReportCategoryV2[]>(conceptsListKey, (old) => {
        return RQUtils.updateArrQuery(old, newConcept, { doAppendAndSortBy: (e) => e.name });
      });
      // update phrases
      queryClient.setQueryData<CompactReportPhrasesForReport>(phrasesForReportByIdKey, (old) => {
        return RQUtils.bulkUpdateDictQuery(old, reportPhrases);
      });
    },
  });
  const createConceptFromCluster = (cluster: SuggestedCluster) => {
    if (createConceptFromClusterMut.isLoading == true) {
      return;
    }
    createConceptFromClusterMut.mutate({ reportId, clusterId: cluster.id });
  };

  const dataId = suggestedCluster.id;
  React.useEffect(() => {
    if (!active || scrollRef == null) return;
    const id = `${dataId}_suggest`;

    const element = document.getElementById(id);
    const elementRect = element?.getBoundingClientRect();
    const parent = element?.offsetParent;
    const top =
      (elementRect?.top ?? 0) -
      (parent?.getBoundingClientRect().top ?? 0) +
      (parent?.scrollTop ?? 0);
    const bottom = top + (elementRect?.height ?? 0);
    const parentBottom = (parent?.scrollTop ?? 0) + (parent?.getBoundingClientRect().height ?? 0);

    let newScrollTop: any = null;
    if (top < (parent?.scrollTop ?? 0) || bottom > parentBottom || top > parentBottom) {
      newScrollTop = top - 10;
    }

    if (newScrollTop != null) {
      setTimeout(() => {
        scrollRef && scrollRef.current.scrollTo({ top: newScrollTop, left: 0, behavior: 'smooth' });
      }, 100);
    }
  }, [active, scrollRef, dataId]);

  const useClusterText = createConceptFromClusterMut.isLoading ? '...' : 'use';
  const setHideText = setHiddenClusterMut.isLoading
    ? '...'
    : suggestedCluster.hidden == true
    ? 'unhide'
    : 'hide';

  // initial condition for splitting phrase ids
  const initialPartitions = {
    active: [] as CompactReportPhrase[],
    deleted: [] as CompactReportPhrase[],
    unknown: [] as string[],
  };
  // split phrases ids into different type
  const partitioned = suggestedCluster.phraseIds.reduce((acc, pId) => {
    const phrase = reportPhrasesById[pId];
    if (phrase == null) {
      acc.unknown.push(pId);
      return acc;
    }

    if (phrase.d == true) {
      acc.deleted.push(phrase);
    } else {
      acc.active.push(phrase);
    }

    return acc;
  }, initialPartitions);

  const linkedConceptIdsSet = new Set<string>();
  suggestedCluster.inConcepts?.forEach((cId) => linkedConceptIdsSet.add(cId));
  suggestedCluster.phraseIds?.forEach((pId) => {
    const phrase = reportPhrasesById[pId];
    if (phrase != null) {
      phrase.in?.forEach((cId) => linkedConceptIdsSet.add(cId));
    }
  });
  const linkedConceptIds = Array.from(linkedConceptIdsSet).sort();

  // const phrases: ClusteredPhrase[] = data?.phrases;
  const phraseIds = suggestedCluster.phraseIds;
  return (
    <div className={styles.suggestConceptWrap} id={`${dataId}_suggest`}>
      <div className={styles.suggestConcept}>
        <div className={styles.buttons}>
          {
            <button onClick={() => createConceptFromCluster(suggestedCluster)}>
              {useClusterText}
            </button>
          }
          {onEdit && <button onClick={() => onEdit(suggestedCluster)}>edit</button>}
          <button
            className={styles.conceptDelete}
            onClick={() => setHiddenCluster(suggestedCluster, !suggestedCluster.hidden)}>
            {setHideText}
          </button>
        </div>
        {suggestedCluster != null && (
          <div className={styles.conceptName}>
            {suggestedCluster.name ?? `Cluster ${suggestedCluster.id}`}
          </div>
        )}
        {suggestedCluster == null && <div className={styles.conceptName}>new concept</div>}
        {partitioned.active.length > 0 && (
          <>
            <div className={styles.suggestConceptWords}>
              {partitioned.active.map((phrase, i) => (
                <DeleteReportPhrasePill
                  reportId={reportId}
                  phrase={phrase}
                  key={`${suggestedCluster.id}-${phrase.id}-${i}`}
                />
              ))}
            </div>
          </>
        )}
        {partitioned.deleted.length > 0 && (
          <>
            <div className={styles.phrasesPartition}>deleted</div>
            <div className={styles.suggestConceptWords}>
              {partitioned.deleted.map((phrase, i) => (
                <AddReportPhrasePill
                  reportId={reportId}
                  phrase={phrase}
                  key={`${suggestedCluster.id}-${phrase.id}-${i}`}
                />
              ))}
            </div>
          </>
        )}
        {partitioned.unknown.length > 0 && (
          <>
            <div className={styles.phrasesPartition}>unknown</div>
            <div className={styles.suggestConceptWords}>
              {partitioned.unknown.map((phraseId, i) => (
                <BlankPill text={phraseId} key={`${suggestedCluster.id}-${phraseId}-${i}`} />
              ))}
            </div>
          </>
        )}
        {linkedConceptIds.length > 0 && (
          <>
            <div className={styles.phrasesPartition}>Linked Concepts</div>
            <div className={styles.suggestConceptWords}>
              {linkedConceptIds.map((cId, i) => (
                <LinkedConceptPill
                  key={`concept-${cId}-for-${suggestedCluster.id}`}
                  reportId={reportId}
                  clusterId={suggestedCluster.id}
                  conceptId={cId}
                  conceptsById={conceptsById}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface ReportPhrasePillActionProps {
  reportId: string;
  phrase: CompactReportPhrase;
}
const DeleteReportPhrasePill: React.FC<ReportPhrasePillActionProps> = ({ reportId, phrase }) => {
  const queryClient = useQueryClient();

  const deleteClusterPhraseMut = trpc.reportPhrases.delete.useMutation({
    onSuccess: (updatedReportPhrase) => {
      // update the hidden and not hidden suggested clusters queries
      queryClient.setQueryData<CompactReportPhrasesForReport>(
        reportPhrasesListRQKey(reportId),
        (old) => {
          // cluster element was updated
          return RQUtils.updateDictQuery(old, updatedReportPhrase);
        },
      );
    },
  });
  const deleteClusterPhrase = () => {
    if (deleteClusterPhraseMut.isLoading == true) {
      return;
    }
    deleteClusterPhraseMut.mutate({ reportId, phraseId: phrase.id });
  };

  return (
    <DeletablePill
      text={phraseText(phrase)}
      status={deleteClusterPhraseMut.status}
      onDelete={deleteClusterPhrase}
    />
  );
};
const AddReportPhrasePill: React.FC<ReportPhrasePillActionProps> = ({ reportId, phrase }) => {
  const queryClient = useQueryClient();

  const addClusterPhraseMut = trpc.reportPhrases.unDelete.useMutation({
    onSuccess: (updatedReportPhrase) => {
      // update the hidden and not hidden suggested clusters queries
      queryClient.setQueryData<CompactReportPhrasesForReport>(
        reportPhrasesListRQKey(reportId),
        (old) => {
          // cluster element was updated
          return RQUtils.updateDictQuery(old, updatedReportPhrase);
        },
      );
    },
  });
  const addClusterPhrase = () => {
    if (addClusterPhraseMut.isLoading == true) {
      return;
    }
    addClusterPhraseMut.mutate({ reportId, phraseId: phrase.id });
  };

  return (
    <AddablePill
      text={phraseText(phrase)}
      status={addClusterPhraseMut.status}
      onAdd={addClusterPhrase}
    />
  );
};
function phraseText(phrase: CompactReportPhrase) {
  const reviewSentiment = (phrase.ss?.s || 0).toFixed(1);
  const sentenceSentiment = (phrase.rs?.s || 0).toFixed(1);

  const clusterIds = phrase.in;
  const clusterIdsStr =
    clusterIds == null || clusterIds.length <= 0 ? '' : ` [${clusterIds.join(',')}]`;

  return `${phrase.p} (${phrase.c}) (${sentenceSentiment}) (${reviewSentiment}) ${clusterIdsStr}`;
}

interface LinkedConceptPillActionProps {
  reportId: string;
  clusterId: string;
  conceptId: string;
  conceptsById: Record<string, ReportCategoryV2>;
}
const LinkedConceptPill: React.FC<LinkedConceptPillActionProps> = ({
  reportId,
  clusterId,
  conceptId,
  conceptsById,
}) => {
  const queryClient = useQueryClient();

  const unlinkConceptMut = trpc.suggestedClusters.unlinkConcept.useMutation({
    onSuccess: (updateRes) => {
      const updatedPhrases = updateRes.reportPhrases;
      const updateSuggestedCluster = updateRes.suggestedCluster;

      // update suggested clusters
      queryClient.setQueryData<SuggestedCluster[]>(suggestedClustersListRQKey(reportId), (old) => {
        return RQUtils.updateArrQuery(old, updateSuggestedCluster);
      });
      // update phrases
      queryClient.setQueryData<CompactReportPhrasesForReport>(
        reportPhrasesListRQKey(reportId),
        (old) => {
          return RQUtils.bulkUpdateDictQuery(old, updatedPhrases);
        },
      );
    },
  });
  const unlinkConcept = () => {
    if (unlinkConceptMut.isLoading == true) {
      return;
    }
    unlinkConceptMut.mutate({ reportId, conceptId, clusterId });
  };

  const concept = conceptsById[conceptId];
  return (
    <DeletablePill
      text={conceptText(conceptId, concept)}
      status={unlinkConceptMut.status}
      onDelete={unlinkConcept}
    />
  );
};
function conceptText(id: string, concept: ReportCategoryV2 | undefined) {
  const text = concept?.name || id;
  return text;
}
