import clsx from 'clsx';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { isNotNull, isStringNotEmpty } from 'src/utils2';
import { useAddReviews } from '../../../trpcHooks/useReportInsightSummary';
import { useGetAllByReportId } from '../../../trpcHooks/useReportInsightSummary';
import { InsightSummariesGet } from '../../../utils/trpc';
import styles from './PreviewPopupPicker.module.scss';
import { useCreate } from '../../../trpcHooks/useReportInsightSummary';

interface Props {
  reviewId: string;
  reportId: string;
  insightSummaries: InsightSummariesGet[];
  handleClose: () => void;
}

interface PropsWithData {
  reviewId: string;
  reportId: string;
  handleClose: () => void;
}

const defaultValues = {
  search: '',
};

export const PreviewPopupPickerWithData: React.FC<PropsWithData> = ({
  reviewId,
  reportId,
  handleClose,
}) => {
  const { data: getAllByReportIdQuery } = useGetAllByReportId(reportId);

  if (getAllByReportIdQuery?.insightSummaries) {
    return (
      <PreviewPopupPicker
        reviewId={reviewId}
        handleClose={handleClose}
        insightSummaries={getAllByReportIdQuery.insightSummaries}
        reportId={reportId}
      />
    );
  } else {
    return <div className={clsx(styles.container)}>Loading...</div>;
  }
};

const PreviewPopupPicker: React.FC<Props> = ({
  reviewId,
  reportId,
  insightSummaries,
  handleClose,
}) => {
  const createSectionMutation = useCreate();
  const {
    getValues,
    watch,
    formState: { errors, isSubmitting },
    clearErrors,
    control,
  } = useForm({ mode: 'all', defaultValues });
  const [filteredArray, setFiltredArray] = React.useState(insightSummaries);

  const addReviewsMutation = useAddReviews();

  const searchValue = getValues('search');
  React.useEffect(() => {
    const searchValue = getValues('search').toLocaleLowerCase();
    const filteredInsightSummaries = insightSummaries
      .map((el) => {
        if (!isStringNotEmpty(searchValue)) return el;
        else if (el.title.toLocaleLowerCase().indexOf(searchValue) >= 0) return el;
        else return undefined;
      })
      .filter(isNotNull);

    setFiltredArray(filteredInsightSummaries);
  }, [watch('search')]);

  return (
    <div className={clsx(styles.container, isSubmitting ? styles.loading : null)}>
      <Controller
        control={control}
        name="search"
        render={({ field }) => (
          <input
            autoFocus
            className={styles.input}
            placeholder="Filter key takeaways"
            {...field}
            autoCapitalize="off"
            type="text"
          />
        )}
      />
      <div className={styles.content}>
        {filteredArray.map((el: InsightSummariesGet) => {
          const id = el._id.toHexString();
          return (
            <div
              key={id}
              className={styles.row}
              onClick={async () => {
                await addReviewsMutation.mutateAsync({ id, reviewId });
                toast.success('verbatim added to key takeaways');
                clearErrors('root');
                handleClose();
              }}>
              {el?.title}
            </div>
          );
        })}
        {filteredArray.length === 0 && searchValue.length > 0 && (
          <div
            className={styles.row}
            onClick={async () => {
              const section = await createSectionMutation.mutateAsync({
                reportId,
                title: searchValue,
              });
              const id = section._id.toHexString();
              await addReviewsMutation.mutateAsync({ id, reviewId });
              toast.success('verbatim added to key takeaways');
              handleClose();
            }}>
            Add to `{searchValue}`
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewPopupPicker;
