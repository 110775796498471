import { ArrowLeft, ArrowRight } from 'src/PoseidonComponents/StrokeIcons';
import useRouterQuery from '../../hooks/useRouterQuery';
import { Button, ButtonsGroup } from '../Button/Button';
import { SmallSelectV2 } from '../Button/Select';

interface PaginatorProps {
  limit?: number;
  defaultLimit: number;
  page: number;
  length?: number;
}
export const Paginator: React.FC<PaginatorProps> = ({ limit, defaultLimit, page, length }) => {
  const [, setQuery] = useRouterQuery();

  const changePerPage = (limit: string) => {
    if (parseInt(limit) === defaultLimit)
      setQuery((o) => ({ ...o, page: undefined, limit: undefined }));
    else setQuery((o) => ({ ...o, page: undefined, limit: parseInt(limit) }));
  };

  const _limit = limit || defaultLimit;
  const hasPrev = page > 0;
  const hasNext = length == null ? false : length < _limit ? false : true;

  const goNext = () => setQuery((d) => ({ ...d, page: (d.page ?? 0) + 1 }));
  const goPrev = page > 0 ? () => setQuery((d) => ({ ...d, page: (d.page ?? 0) - 1 })) : undefined;

  return (
    <ButtonsGroup size="small">
      <Button icon={<ArrowLeft />} disabled={!hasPrev} onClick={goPrev} />
      <SmallSelectV2
        onChange={(e: any) => changePerPage(e.target.value)}
        value={limit || defaultLimit}>
        {Array.from({ length: 3 }, (_, i) => (
          <option key={i} value={defaultLimit * (i + 1)}>
            {defaultLimit * (i + 1)}
          </option>
        ))}
      </SmallSelectV2>
      <Button icon={<ArrowRight />} disabled={!hasNext} onClick={goNext} />
    </ButtonsGroup>
  );
};
