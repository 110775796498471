import React from 'react';
import { ServerReportApp } from '../../utils/trpc';
import Icon from '../icon/icon';
import { TrashIcon } from '../icons/icons';
import styles from './IntegrationRow.module.scss';


interface IntegrationRowProps {
  app: ServerReportApp,
  deleteIntegration: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

export const IntegrationRow: React.FC<IntegrationRowProps> = ({app, deleteIntegration}) => {
  return <div className={styles.integrationRow}>
    <Icon src={app.iconUrl} rounded />
    <div className={styles.iconTitle}>{app.title}</div>
    <div className={styles.spacer} />
    <div className={styles.integrationRowStatus}>{app.integration?.status}</div>
    <div className={styles.integrationDelete} onClick={deleteIntegration}>
      <TrashIcon />
    </div>
  </div>
}


interface AndroidIntegrationRowProps {
  reportId: string,
  app: ServerReportApp,
  deleteIntegration: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const AndroidIntegrationRow: React.FC<AndroidIntegrationRowProps> = ({app, deleteIntegration}) => {
  const oAuthUrl = app.authUrl

  // there will only be any empty object there because the json serializer hides it with an empty object
  // but if its not null, then there is data there the server can access
  const isActive = app?.integration?.status == "active";

  const oAuthedBy = app?.integration?.oAuthedBy
  const oAuthedByEmail = app?.integration?.oAuthedByEmail
  const svcClientEmail = app?.integration?.svcClientEmail

  const oAuthedByStr = oAuthedByEmail == null ? oAuthedBy : oAuthedByEmail

  return <div>
    {app?.authTokenError != null && <div>{app.authTokenError}</div>}
    {app?.integration?.error != null && <div>{app?.integration?.error}</div>}
    <div className={styles.integrationRow}>
      <Icon src={app.iconUrl} rounded />
      <div className={styles.iconTitle}>{app.title}</div>
      {!isActive && <div><a href={oAuthUrl} target="_blank">Authenticate</a></div> }
      {isActive && <div>using {svcClientEmail} added by {oAuthedByStr}</div> }
      <div className={styles.spacer} />
      <div className={styles.integrationRowStatus}>{app.integration?.status}</div>
      <div className={styles.integrationDelete} onClick={deleteIntegration}>
        <TrashIcon />
      </div>
    </div>
  </div>
}