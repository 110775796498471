import clsx from 'clsx';
import React from 'react';
import { QueryMachine } from 'src/PoseidonComponents/ReviewCard/ReviewCard';
import UtfString from 'utfstring';
import { MachineParam } from '../../hooks/useRouterQuery';
import Tooltip from '../../libs/context-tooltip/tooltip';
import { ReportCategory, SvrReportReviewRange } from '../../utils/trpc';
import {
  getFadedHighlightRangeColor,
  getHighlightRangeColor,
  getHoverHighlightRangeColor,
  getRangeColor2,
  getScore,
  isNotNull,
} from '../../utils2';
import SentimentTooltip from '../sentimentTooltip/sentimentTooltip';
import styles from './review.module.scss';
import { ReviewHighlight } from './reviewHighlight';
import { MachineOverlay } from './reviewMachineOverlay';
import { MachineTooltip, MachineTooltipModel } from './reviewMachineTooltip';

const splitChar = '\n';

interface ReviewTextProps {
  id: string;
  title?: string | null;
  text: string;
  ranges: SvrReportReviewRange[];
  query?: string | RegExp;
  concept: any;
  machine: MachineParam | undefined;
  conceptsById?: Record<string, ReportCategory>;
  appProvider: string | null | undefined;
  groupedCatTypes: any;
  tooltip?: MachineTooltipModel;
  extras?: Record<string, string>;
  expanded?: boolean;
}

export const ReviewText: React.FC<ReviewTextProps> = React.memo(
  ({
    id,
    title,
    text,
    ranges,
    query,
    concept,
    machine,
    conceptsById,
    appProvider,
    groupedCatTypes,
    tooltip,
    extras,
    expanded,
  }) => {
    let offset = title != null ? UtfString.length(title) + 2 : 0;
    let lines = text.split(splitChar);

    const isYoutube = appProvider === 'csvYoutube';

    if (isYoutube) {
      lines = text.split('___');
    }

    let params = undefined;
    if (extras?.url) {
      if (!extras.url.startsWith('http')) {
        params = new URL('http://' + extras?.url).searchParams;
      } else {
        params = new URL(extras.url).searchParams;
      }
    }

    const youtubeId = params?.get('v');
    const isMachine = machine != null && machine?.rid === id;
    return (
      <div className={clsx(styles.textContainer, isMachine ? styles.machineActive : null)}>
        {isYoutube && youtubeId && expanded && (
          <div className={styles.youtube}>
            <iframe src={`https://www.youtube.com/embed/${youtubeId}`} title={title || ''} />
          </div>
        )}
        {lines
          .filter((l) => l != null)
          .map((line, i) => {
            offset += i > 0 ? UtfString.length(lines[i - 1] ?? '') + 1 : 0;
            return (
              <div
                key={i}
                className={clsx(styles.line, concept != null ? styles.hasConcept : null)}>
                <ReviewTextOverlay
                  line={line}
                  ranges={ranges}
                  concept={concept}
                  offset={offset}
                  conceptsById={conceptsById}
                  isMachine={isMachine}
                  groupedCatTypes={groupedCatTypes}
                />
                <ReviewHighlight line={line} query={query} />
                <MachineOverlay id={id} line={line} offset={offset} machineSplit={machine} />
                <MachineTooltip id={id} line={line} offset={offset} tooltip={tooltip} />
              </div>
            );
          })}
      </div>
    );
  },
);

interface ReviewTextOverlayProps {
  ranges: SvrReportReviewRange[];
  offset: number;
  line: any;
  concept?: ReportCategory;
  isMachine?: boolean;
  conceptsById?: Record<string, ReportCategory>;
  groupedCatTypes?: Record<string, any>;
  queryMachine?: QueryMachine;
}

export const ReviewTextOverlay: React.FC<ReviewTextOverlayProps> = React.memo(
  ({ ranges, offset, queryMachine, line, concept, isMachine, conceptsById, groupedCatTypes }) => {
    if (line == null) return null;
    const lines = [];

    let index = 0;
    let rangeStart = 0;

    while (ranges != null && index < ranges.length) {
      const range = ranges[index];
      const start = (range?.b ?? 0) - (offset ?? 0);
      const end = (range?.e ?? 0) - (offset ?? 0);

      if (start === end) {
        index += 1;
        continue;
      }

      if (end < rangeStart) {
        index += 1;
        continue;
      }

      if (start <= rangeStart) {
        if (UtfString.length(line) < start) break;
        const lineSplit = UtfString.slice(line, start < rangeStart ? rangeStart : start, end);

        if (UtfString.length(lineSplit) > 0) {
          let cats = range?.cats
            ?.map((cat: any) => {
              const _concept = conceptsById?.[cat.c];
              if (
                groupedCatTypes != null &&
                (groupedCatTypes['domain'] == null || groupedCatTypes['domain'] === 0)
              )
                return cat;
              if (
                (concept == null || concept.catType !== 'judgement') &&
                _concept != null &&
                _concept.catType === 'judgement'
              )
                return null;
              if (
                concept != null &&
                concept.catType === 'judgement' &&
                (_concept == null || _concept.catType !== concept.catType)
              )
                return null;
              if (cat?.s >= 0.2 || cat?.s <= -0.2) return cat;
              if (cat?.s != null) return cat;
              return null;
            })
            .filter(isNotNull);

          const hasActiveConcept =
            cats?.find((cat: any) => concept?._id.toHexString() === cat?.c) != null;

          if (cats == null || cats.length === 0) {
            lines.push(<span key={`${index}_${rangeStart}`}>{lineSplit}</span>);
          } else {
            const sentiment = cats?.[0]?.s;
            const score = getScore(sentiment);
            const color = getRangeColor2(score);
            const hightlightColor = getHighlightRangeColor(score);
            const fadedHighlightColor = getFadedHighlightRangeColor(score);
            const hoverHightlightColor = getHoverHighlightRangeColor(score);
            const style =
              queryMachine && !isMachine
                ? undefined
                : ({
                    '--color': color,
                    '--highlight-color': !queryMachine && isMachine ? undefined : hightlightColor,
                    '--highlight-faded-color': fadedHighlightColor,
                    '--hover-highlight-color': hoverHightlightColor,
                    '--hover-highlight-faded-color': fadedHighlightColor,
                  } as React.CSSProperties);
            const content = cats != null && conceptsById != null && (
              <SentimentTooltip cats={cats} conceptsById={conceptsById} />
            );
            const className = clsx(
              styles.sentiment,
              hasActiveConcept ? styles.activeConcept : null,
              isMachine ? styles.machineActive : null,
            );
            lines.push(
              <Tooltip
                tagName="span"
                content={content}
                key={`${index}_${rangeStart}`}
                className={className}
                style={style}
                background={color}>
                {lineSplit}
              </Tooltip>,
            );
          }
        }
        index += 1;
        rangeStart = end;
        continue;
      } else {
        if (UtfString.length(line) < rangeStart) break;
        const lineSplit = UtfString.slice(line, rangeStart, start);
        if (UtfString.length(lineSplit) > 0)
          lines.push(<span key={`${index}_${rangeStart}`}>{lineSplit}</span>);
        rangeStart = start;
        continue;
      }
    }

    const lineSplit = UtfString.slice(line, rangeStart);
    if (lineSplit) lines.push(<span key={`${index}_${rangeStart}`}>{lineSplit}</span>);

    const classList = clsx(
      styles.overlay,
      concept != null ? styles.hasConcept : undefined,
      isMachine ? styles.noEvents : undefined,
    );
    return <div className={classList}>{lines}</div>;
  },
);
