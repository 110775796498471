import { useLocalStorage } from '@rehooks/local-storage';
import React, { HTMLProps } from 'react';
import { SCButton } from '../PoseidonComponents/Button/Button';
import { SegmentedControl } from '../PoseidonComponents/Button/SegmentedControl';
import { ContentDivider } from '../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../PoseidonComponents/ContentSection/ContentSection';
import { FullHeight } from '../PoseidonComponents/FullHeight/FullHeight';
import { ColumnsGrid } from '../PoseidonComponents/Grid/Grid';
import { WithLeftNav } from '../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageFilters } from '../PoseidonComponents/PageFilters';
import { PageHeader } from '../PoseidonComponents/PageHeader/PageHeader';
import { Lightbulb } from '../PoseidonComponents/StrokeIcons';
import { WaveSpinner } from '../PoseidonComponents/WaveSpinner';
import { ReportsLeftNav } from '../components/LeftNav/LeftNav';
import DeletePopup from '../components/deletePopup/deletePopup';
import EditConceptPopup from '../components/editConceptPopup/editConceptPopup';
import { SuggestConcept } from '../components/suggestConcept/suggestConcept';
import useRouterQuery from '../hooks/useRouterQuery';
import { useConceptDeleteMutation } from '../trpcHooks/useConceptMutation';
import { Concept, ServerTeam, ServerUser, trpc } from '../utils/trpc';

const PER_PAGE = 25;
interface ConceptsProps {
  user: ServerUser;
  team: ServerTeam | undefined;
}
export const Concepts: React.FC<ConceptsProps> = ({ user, team }) => {
  const [showDeletePopupFor, setShowDeletePopupFor] = React.useState<string>();
  const [showEditConceptPopupFor, setShowEditConceptPopupFor] = React.useState<Concept>();
  const [query] = useRouterQuery();
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const deleteConceptMut = useConceptDeleteMutation();

  const page = query.page || 0;
  const perPage = query.limit || PER_PAGE;
  const catType = query.catType ?? 'domain';

  const libraryConceptsRes = trpc.concepts.all.useQuery({
    catType,
    pagination: { perPage, page },
  });
  const libraryConcepts = libraryConceptsRes.data;

  const deletePopUpConcept = async (conceptId: string) => {
    if (deleteConceptMut.isLoading || showDeletePopupFor == null) {
      return;
    }
    await deleteConceptMut.mutateAsync({ conceptId });
    setShowDeletePopupFor(undefined);
  };

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Lightbulb />} title="Concepts">
          <ContentDivider
            end={
              <>
                <DomainJudgementSwitcher />
                <PageFilters hideSearch />
              </>
            }
          />
        </PageHeader>

        <PaddedScrollable>
          <ContentSection>
            <ColumnsGrid gap={20} columns={3} columnMinWidth={250}>
              {libraryConceptsRes.isLoading && <WaveSpinner />}
              {libraryConcepts?.map((concept) => (
                <SuggestConcept
                  key={concept._id.toHexString()}
                  concept={concept}
                  onDeletePressed={setShowDeletePopupFor}
                  onEditPressed={setShowEditConceptPopupFor}
                />
              ))}
            </ColumnsGrid>
          </ContentSection>
        </PaddedScrollable>
      </WithLeftNav>

      {showDeletePopupFor != null && (
        <DeletePopup
          onClose={() => setShowDeletePopupFor(undefined)}
          onSubmit={() => deletePopUpConcept(showDeletePopupFor)}
        />
      )}
      {showEditConceptPopupFor != null && (
        <EditConceptPopup
          concept={showEditConceptPopupFor}
          onClose={() => setShowEditConceptPopupFor(undefined)}
        />
      )}
    </FullHeight>
  );
};

export default Concepts;

type ID = 'domain' | 'judgement';

export const DomainJudgementSwitcher: React.FC<HTMLProps<HTMLDivElement>> = () => {
  const [query, setQuery] = useRouterQuery();

  const onChange = (uid: ID) => {
    const catType = uid === 'domain' ? undefined : 'judgement';
    setQuery((o) => ({ ...o, catType }));
  };

  return (
    <SegmentedControl<ID>
      size="small"
      defaultId={query.catType || 'domain'}
      onChangeSegment={onChange}>
      <SCButton<ID> uid="domain">Domain</SCButton>
      <SCButton<ID> uid="judgement">Judgement</SCButton>
    </SegmentedControl>
  );
};
