import React from 'react';
import { Tooltip } from 'src/libs/Tooltip';
import { extractAchronym, isStringNotEmpty } from 'src/utils2';
import { IconWithPlaceholder } from '../IconWithPlaceholder/IconWithPlaceholder';
import styles from './UserIcons.module.scss';

interface UserIconsProps {
  max: number;
  users: any[];
}
const SIZE = 24;
const SHAPE = 'circle';

export const UserIcons: React.FC<UserIconsProps> = ({ users, max }) => {
  const remainingLength = users.length - max;
  return (
    <div className={styles.container}>
      {users.slice(0, max).map((user, index) => {
        const name = user?.name || user?.contactEamil;
        return (
          <Tooltip label={name} key={index}>
            <IconWithPlaceholder
              size={SIZE}
              alt="profile"
              placeholder={extractAchronym([name])}
              urls={[user?.profilePicture].filter(isStringNotEmpty)}
              shape={SHAPE}
              className={styles.icon}
            />
          </Tooltip>
        );
      })}
      {remainingLength > 0 && (
        <IconWithPlaceholder
          size={SIZE}
          alt="profile"
          placeholder={`+${remainingLength}`}
          shape={SHAPE}
          className={styles.icon}
        />
      )}
    </div>
  );
};
