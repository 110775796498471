import useLocalStorage from '@rehooks/local-storage';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { Button } from '../../PoseidonComponents/Button/Button';
import { CalendarDate } from '../../PoseidonComponents/StrokeIcons';
import useRouterQuery from '../../hooks/useRouterQuery';
import Popup from '../../libs/popup/popup';
import { DateTypeValue } from '../../models/dateTypeValue.model';
import { namespaceStyle } from '../../utils2';
import styles from './datePicker.module.scss';

const MAX_YEARS_BACK = 5;

interface DatePickerProps {
  groupBy?: any;
}

export const DatePicker: React.FC<DatePickerProps> = () => {
  const [, setSearch] = useRouterQuery();
  const [dateTypeValue, setDateTypeValue] = useLocalStorage<DateTypeValue>('dateTypeValue', {
    type: undefined,
    value: undefined,
  });
  const searchDateType = dateTypeValue.type;
  const searchDateValue = dateTypeValue.value;
  const [dateType, setDateType] = React.useState(searchDateType);
  const [isVisible, setIsVisible] = React.useState(false);

  const momentDateValue = searchDateValue ? moment(Number(searchDateValue)) : undefined;
  let groupingLabel = 'All';
  if ('year' === searchDateType) {
    groupingLabel = `Year ${momentDateValue?.format('YYYY')}`;
  } else if ('quarter' === searchDateType) {
    groupingLabel = `Quarter ${momentDateValue?.quarter()} - ${momentDateValue?.format('YYYY')}`;
  } else if ('month' === searchDateType) {
    groupingLabel = `${momentDateValue?.format('MMMM YYYY')}`;
  } else if ('week' === searchDateType) {
    const start = momentDateValue?.startOf('isoWeek').format('L');
    const end = momentDateValue?.endOf('isoWeek').format('L');
    groupingLabel = `${start} - ${end}`;
  }

  const onOpenPopup = () => {
    setIsVisible(true);
  };

  const onCancel = () => {
    setIsVisible(false);
    setDateType(searchDateType);
  };

  const selectAll = () => {
    setDateTypeValue({ type: undefined, value: undefined });
    setDateType(undefined);
    setIsVisible(false);
  };

  const onSetDate = (type: 'year' | 'quarter' | 'month' | 'week') => (value: number) => {
    setDateTypeValue({ type, value });
    setDateType(type);
    setIsVisible(false);
    setSearch({ page: undefined });
  };

  return (
    <>
      <Button size="small" onClick={onOpenPopup} icon={<CalendarDate />}>
        Date sort: {groupingLabel}
      </Button>
      {isVisible && (
        <Popup onClose={() => onCancel()}>
          <div className={styles.popup} style={namespaceStyle()}>
            <div className={styles.popupHeader}>Select dates to analyze</div>
            <div className={styles.pickerContainer}>
              <div className={styles.typePicker}>
                <div
                  className={clsx(styles.typePickerRow, dateType == null ? styles.selected : null)}
                  onClick={() => selectAll()}>
                  All time
                </div>
                <div
                  className={clsx(
                    styles.typePickerRow,
                    dateType === 'year' ? styles.selected : null,
                  )}
                  onClick={() => setDateType('year')}>
                  Year
                </div>
                <div
                  className={clsx(
                    styles.typePickerRow,
                    dateType === 'quarter' ? styles.selected : null,
                  )}
                  onClick={() => setDateType('quarter')}>
                  Quarter
                </div>
                <div
                  className={clsx(
                    styles.typePickerRow,
                    dateType === 'month' ? styles.selected : null,
                  )}
                  onClick={() => setDateType('month')}>
                  Month
                </div>
                <div
                  className={clsx(
                    styles.typePickerRow,
                    dateType === 'week' ? styles.selected : null,
                  )}
                  onClick={() => setDateType('week')}>
                  Week
                </div>
              </div>
              <div className={styles.valuePicker}>
                {dateType == null && (
                  <div className={styles.valuePickerRow}>Shows data across all time</div>
                )}
                {dateType === 'year' && (
                  <YearPicker
                    onSetDate={onSetDate('year')}
                    dateType={searchDateType}
                    dateValue={momentDateValue}
                  />
                )}
                {dateType === 'quarter' && (
                  <QuarterPicker
                    onSetDate={onSetDate('quarter')}
                    dateType={searchDateType}
                    dateValue={momentDateValue}
                  />
                )}
                {dateType === 'month' && (
                  <MonthPicker
                    onSetDate={onSetDate('month')}
                    dateType={searchDateType}
                    dateValue={momentDateValue}
                  />
                )}
                {dateType === 'week' && (
                  <WeekPicker
                    onSetDate={onSetDate('week')}
                    dateType={searchDateType}
                    dateValue={momentDateValue}
                  />
                )}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default DatePicker;

interface YearPickerProps {
  onSetDate: (v: number) => void;
  dateType?: string;
  dateValue?: moment.Moment;
}

const YearPicker: React.FC<YearPickerProps> = ({ onSetDate, dateType, dateValue }) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const years: any[] = [];
  while (now.format('YYYY') > minYear) {
    const valueOf = now.valueOf();
    const year = now.format('YYYY');
    const isSelected = dateType === 'year' && dateValue?.format('YYYY') === year;
    years.push([year, valueOf, isSelected]);
    now.subtract(1, 'year');
  }

  return (
    <>
      {years?.map(([year, valueOf, selected]) => (
        <div
          className={clsx(styles.valuePickerRow, selected ? styles.selected : null)}
          key={valueOf}
          onClick={() => onSetDate(valueOf)}>
          {year}
        </div>
      ))}
    </>
  );
};

interface QuarterPickerProps {
  onSetDate: (v: number) => void;
  dateType?: string;
  dateValue?: moment.Moment;
}
const QuarterPicker: React.FC<QuarterPickerProps> = ({ onSetDate, dateType, dateValue }) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const yearQuarters: any[] = [];
  while (now.format('YYYY') > minYear) {
    const valueOf = now.valueOf();
    const year = now.format('YYYY');
    const quarter = now.quarter();
    const isSelected =
      dateType === 'quarter' &&
      dateValue?.format('YYYY') === year &&
      dateValue?.quarter() === quarter;
    yearQuarters.push([year, quarter, valueOf, isSelected]);
    now.subtract(1, 'quarter');
  }

  return (
    <>
      {yearQuarters?.map(([year, quarter, valueOf, selected]) => (
        <div
          className={clsx(styles.valuePickerRow, selected ? styles.selected : null)}
          key={valueOf}
          onClick={() => onSetDate(valueOf)}>
          Quarter {quarter} &ndash; {year}
        </div>
      ))}
    </>
  );
};

interface MonthPickerProps {
  onSetDate: (v: number) => void;
  dateType?: string;
  dateValue?: moment.Moment;
}

const MonthPicker: React.FC<MonthPickerProps> = ({ onSetDate, dateType, dateValue }) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const months: any[] = [];
  while (now.format('YYYY') > minYear) {
    const valueOf = now.valueOf();
    const year = now.format('YYYY');
    const month = now.format('MMMM');
    const isSelected =
      dateType === 'month' &&
      dateValue?.format('YYYY') === year &&
      dateValue?.format('MMMM') === month;
    months.push([year, month, valueOf, isSelected]);
    now.subtract(1, 'month');
  }

  return (
    <>
      {months?.map(([year, month, valueOf, selected]) => (
        <div
          className={clsx(styles.valuePickerRow, selected ? styles.selected : null)}
          key={valueOf}
          onClick={() => onSetDate(valueOf)}>
          {month} &ndash; {year}
        </div>
      ))}
    </>
  );
};

interface WeekPickerProps {
  onSetDate: (v: number) => void;
  dateType?: string;
  dateValue?: moment.Moment;
}

const WeekPicker: React.FC<WeekPickerProps> = ({ onSetDate, dateType, dateValue }) => {
  const now: any = moment();

  const minYear = now.format('YYYY') - MAX_YEARS_BACK;

  const weeks: any[] = [];
  while (now.format('YYYY') > minYear) {
    const start = now.startOf('isoWeek').format('L');
    const end = now.endOf('isoWeek').format('L');
    const valueOf = now.startOf('isoWeek').valueOf();
    const isSelected = dateType === 'week' && dateValue?.startOf('isoWeek').format('L') === start;
    weeks.push([start, end, valueOf, isSelected]);
    now.subtract(1, 'week');
  }

  return (
    <>
      {weeks?.map(([start, end, valueOf, selected]) => (
        <div
          className={clsx(styles.valuePickerRow, selected ? styles.selected : null)}
          key={valueOf}
          onClick={() => onSetDate(valueOf)}>
          {start} &mdash; {end}
        </div>
      ))}
    </>
  );
};
