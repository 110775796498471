import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import React from 'react';
import { ServerReportBrand } from 'src/utils/trpc';
import { SortableItem } from './SortableItem.';
import styles from './draftReportSections.module.scss';

interface Props extends React.HTMLProps<HTMLDivElement> {
  id?: string;
  brands?: ServerReportBrand[];
  deleteBrand?: (name: string, groupName?: string) => void;
}

export const Container: React.FC<Props> = ({ id, deleteBrand, brands, children }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: id || '',
    data: { groupId: id },
  });

  return (
    <div className={clsx(isOver ? styles.overlay : styles.notOverlay)}>
      {children}

      <div ref={setNodeRef} className={clsx(styles.block)}>
        {brands
          ?.sort((a, b) => ((a?.name || '') > (b?.name || '') ? 1 : -1))
          .map((brand) => {
            const idBrand = brand._id.toHexString();
            return (
              <SortableItem
                key={idBrand}
                deleteBrand={deleteBrand}
                id={idBrand}
                brand={brand}
                groupId={id}
              />
            );
          })}
      </div>
    </div>
  );
};
