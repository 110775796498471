import clsx from 'clsx';
import React from 'react';
import { QueryMachine } from 'src/PoseidonComponents/ReviewCard/ReviewCard';
import {
  getFadedHighlightRangeColor,
  getHighlightRangeColor,
  getHoverHighlightRangeColor,
  getRangeColor2,
  getScore,
} from '../../utils2';
import styles from './review.module.scss';

interface ReviewTextOverlayProps {
  line: any;
  isMachine?: boolean;
  queryMachine?: QueryMachine;
  newScore?: number;
}

export const ReviewTextOverlay: React.FC<ReviewTextOverlayProps> = React.memo(
  ({ queryMachine, newScore, line, isMachine }) => {
    const score = getScore(newScore);
    const color = getRangeColor2(score);
    const hightlightColor = getHighlightRangeColor(score);
    const fadedHighlightColor = getFadedHighlightRangeColor(score);
    const hoverHightlightColor = getHoverHighlightRangeColor(score);
    const style = {
      '--color': color,
      '--highlight-color': !queryMachine && isMachine ? undefined : hightlightColor,
      '--highlight-faded-color': fadedHighlightColor,
      '--hover-highlight-color': hoverHightlightColor,
      '--hover-highlight-faded-color': fadedHighlightColor,
    } as React.CSSProperties;

    const classList = clsx(styles.sentiment, isMachine ? styles.machineActive : null);
    return (
      <span className={classList} style={style}>
        {line}
      </span>
    );
  },
);
