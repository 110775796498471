import React, { useState } from 'react';
import { ReportReviewFacets, ReviewExtraColFromList, trpc } from '../../utils/trpc';
import { ChevronIcon } from '../icons/icons';

import useRouterQuery from '../../hooks/useRouterQuery';
import styles from './extraColSection.module.scss';

interface ExtraColSectionProps {
  extraCol: ReviewExtraColFromList;
}

export const ExtraColSection: React.FC<ExtraColSectionProps> = ({ extraCol }) => {
  const [search, setSearch] = useRouterQuery();
  const selectedValuesForKey = extraCol.slug ? search.extras?.get(extraCol.slug) : undefined;
  const [isOpen, setIsOpen] = useState((selectedValuesForKey?.size ?? 0) > 0);

  return (
    <div className={styles.section}>
      <div className={styles.sectionName} onClick={() => setIsOpen((o) => !o)}>
        <span>{extraCol.name}</span>
        <ChevronIcon />
      </div>
      {isOpen && (
        <div className={styles.sectionRows}>
          <ExtraColSectionValues extraCol={extraCol} selectedValuesForKey={selectedValuesForKey} />
        </div>
      )}
    </div>
  );
};

interface ExtraColSectionValuesProps extends React.HTMLProps<HTMLDivElement> {
  extraCol: ReviewExtraColFromList;
  selectedValuesForKey?: Set<string>;
}
export const ExtraColSectionValues: React.FC<ExtraColSectionValuesProps> = ({
  extraCol,
  selectedValuesForKey,
}) => {
  const extraColWithValues = trpc.extraCols.forSlug.useQuery({
    reportId: extraCol.reportId.toHexString(),
    slug: extraCol.slug,
  });

  if (extraColWithValues.isLoading == true) {
    return <div>...</div>;
  }

  const data = extraColWithValues.data;
  if (data == null) {
    return <div>...</div>;
  }

  return (
    <>
      {(data.values || []).map((v) => {
        return (
          <ExtraColSectionValue
            key={`${extraCol.slug}_${v}`}
            extraKey={extraCol.slug}
            value={v}
            selectedValuesForKey={selectedValuesForKey}
          />
        );
      })}
    </>
  );
};

interface ExtraColSectionValueProps extends React.HTMLProps<HTMLDivElement> {
  extraKey: string;
  value: string;
  selectedValuesForKey?: Set<string>;
}
export const ExtraColSectionValue: React.FC<ExtraColSectionValueProps> = ({
  extraKey,
  value,
  selectedValuesForKey,
}) => {
  const [search, setSearch] = useRouterQuery();

  return (
    <label className={styles.label}>
      <input
        checked={selectedValuesForKey?.has(value)}
        type={'checkbox'}
        value=""
        onChange={(e) => {
          const selected = e.target.checked;
          setSearch((oldState) => {
            if (oldState == null) {
              if (selected == false) {
                return oldState;
              }
              return {
                extras: new Map<string, Set<string>>([[extraKey, new Set([value])]]),
              };
            }

            if (oldState.extras == null) {
              if (selected) {
                return {
                  ...oldState,
                  extras: new Map<string, Set<string>>([[extraKey, new Set([value])]]),
                };
              }
              return oldState;
            }

            const atKey = oldState.extras.get(extraKey);
            if (atKey == null) {
              if (selected) {
                oldState.extras.set(extraKey, new Set([value]));
                return oldState;
              } else {
                return oldState;
              }
            } else {
              if (selected) {
                atKey.add(value);
                return oldState;
              } else {
                atKey.delete(value);
                if (atKey.size <= 0) {
                  oldState.extras.delete(extraKey);
                }
                return oldState;
              }
            }
            return oldState;
          });
        }}
      />
      <span>{value}</span>
    </label>
  );
};
