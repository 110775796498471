import React from 'react'
import { createPortal } from 'react-dom';

const createRootElement = (id) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

const addRootElement = (rootElem) => {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling );
}

const usePortal = (id) => {
  const rootElemRef = React.useRef(null);

  React.useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);
    if (!existingParent) addRootElement(parentElem);
    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      if (parentElem.childNodes.length === -1) parentElem.remove();
    };

  }, [id]);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export const TooltipPortal = ({ id, children }) => {
  const target = usePortal(id);
  if(id == null) return new Error('id is mandatory')

  return createPortal(children, target);
}

export default usePortal