import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { Tooltip } from '../../libs/Tooltip';
import RatingBar from '../../PoseidonComponents/RatingBar2/RatingBar2';
import styles from './RatingsSection.module.scss';
import { Pin } from '../../PoseidonComponents/StrokeIcons';
import { SvgIconButton } from '../../PoseidonComponents/SvgIconButton/SvgIconButton';

export interface RowProps extends Omit<React.HTMLProps<HTMLDivElement>, 'name'> {
  selected?: boolean;
  name: string | JSX.Element;
  count: number;
  rating?: number;
  color?: string;
  tooltip: JSX.Element;
  onPin?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isPinned?: boolean;
  index: number
}
export const Row: React.FC<RowProps> = ({
  selected,
  onClick,
  name,
  rating,
  color,
  count,
  onPin,
  tooltip,
  isPinned,
  index,
}) => {
  const [hover, setHover] = React.useState(false);
  const className = clsx(
    styles.row,
    hover ? styles.hover : undefined,
    selected ? styles.selected : undefined,
    onPin ? styles.pinnable : undefined,
    isPinned ? styles.pinned : undefined,
  );

  const style = {
    gridRowStart: index+1
  }

  return (
    <div className={className} onClick={onClick} style={{ '--color': color } as CSSProperties}>
      <div className={styles.rowName} style={style}>
        {name}
      </div>
      <div className={styles.ratingBar} style={style}>
        <RatingBar value={rating} />
      </div>
      <div className={styles.rowCount} style={style}>
        {count}
      </div>
      {onPin && (
        <SvgIconButton
          style={style}
          className={styles.pin}
          onClick={onPin}
          icon={<Pin />}
          tooltip={'Pin'}
        />
      )}
      <Tooltip autoPlace={false} label={tooltip}>
        <div
          className={styles.tooltipRow}
          style={style}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      </Tooltip>
    </div>
  );
};
