import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import ConceptsSection from '../components/conceptsSection/conceptsSection';
import { ExtraColSection } from '../components/extraColSection/extraColSection';
import FilterSection from '../components/filterSection/filterSection';
import SentimentRatingSection from '../components/sentimentRatingSection/sentimentRatingSection';
import StarRatingSection, {
  NpsRatingSection,
} from '../components/sentimentRatingSection/starRatingSection';
import useRouterQuery from '../hooks/useRouterQuery';
import { IIntervalBucket } from '../models/aggModels/intervalBucket.entity';
import { DateTypeValue } from '../models/dateTypeValue.model';
import { useReportUpdateMutation } from '../trpcHooks/useReportMutation';
import {
  IntervalAndDate,
  ReportCategory,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  ServerBrand,
  ServerReport,
  trpc,
} from '../utils/trpc';
import { isNotNull } from '../utils2';
import { ContentDivider } from './ContentDivider/ContentDivider';
import { ContentSection } from './ContentSection/ContentSection';
import { FilterContainer } from './Layout/Layout';
import { PaddedScrollable } from './PaddedScrollable/PaddedScrollable';
import { Search, Smile, Star } from './StrokeIcons';
import TopicSection from './TopicSection';

interface BrandFiltersProps {
  report: ServerReport;
  brand: ServerBrand;
  reportCategories?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  intervalBucket: IIntervalBucket;
  onAddConceptClicked?: () => void;
}

export const BrandFilters: React.FC<BrandFiltersProps> = React.memo(
  ({
    report,
    brand,
    reportCategories,
    extraCols,
    extraColsSections,
    intervalBucket,
    onAddConceptClicked,
  }) => {
    const [query] = useRouterQuery();
    const [dateTypeValue] = useLocalStorage<DateTypeValue>('dateTypeValue', {
      type: undefined,
      value: undefined,
    });
    const brandApps = brand?.apps;
    const appProvider = brandApps?.[0]?.appProvider;
    const reportId = report._id.toHexString();
    const dateValue = dateTypeValue.value;
    const dateType = dateTypeValue.type;
    const intervalAndDate: IntervalAndDate =
      dateValue != null && dateType != null
        ? {
            type: dateType as any,
            dateMillis: dateValue,
          }
        : { type: 'infinity', dateMillis: 0 };

    const reviewsFacetsRes = trpc.reportReviews.facetsForBrand.useQuery({
      reportId: reportId,
      brandId: brand._id.toHexString(),
      query: query.q,
      filters: {
        category: query.category,
        rating: query.rating != null ? parseFloat(query.rating) : undefined,
        sRating: query.sRating != null ? parseInt(query.sRating, 10) : undefined,
        conceptAspect: query.conceptAspect != null ? parseInt(query.conceptAspect) : undefined,
      },
      extrasFilters: query.extras,
      intervalAndDate: intervalAndDate,
      pagination: {
        perPage: 30,
        page: query.page || 0,
      },
    });
    const facets = reviewsFacetsRes.data;

    const intervalBucketDate = intervalBucket.date;
    const intervalBucketType = intervalBucket.type;
    const brandId = brand._id.toHexString();
    const updateActiveMutation = useReportUpdateMutation();
    const [filtersMinimized] = useLocalStorage('filtersMinimized', false);

    const reportBrandCounts = trpc.reportBrand.dataCounts.useQuery({
      reportId,
      brandId,
      timeInterval: { value: intervalBucketDate || 0, type: intervalBucketType || 'infinity' },
    });

    const countsByCategory = React.useMemo(
      () => new Map(facets?.categories.buckets.map((b) => [b._id, b.count])),
      [facets],
    );

    const appIdsFilter = React.useMemo(
      () => new Set(report.apps?.filter((a) => a.brandId?.equals(brand._id)).map((a) => a.appId)),
      [report],
    );

    const [domainConcepts, judgementConcepts] = React.useMemo(() => {
      let domainConcepts: ReportCategory[] = [];
      let judgementConcepts: ReportCategory[] = [];
      reportCategories?.forEach((concept) => {
        if (concept.catType === 'judgement') {
          judgementConcepts.push(concept);
        } else if (concept.catType === 'domain') {
          domainConcepts.push(concept);
        }
      });
      return [domainConcepts, judgementConcepts];
    }, [reportCategories]);

    const updateTopic = (searches?: string[]) => {
      const id = report._id.toHexString();
      updateActiveMutation.mutate({ id, data: { searches } });
    };

    const countsByCategoryId = reportBrandCounts.data;
    const catReviews = countsByCategoryId?._review;
    const aggDataCategory = query.category ? countsByCategoryId?.[query.category] : undefined;

    const ratingData = query?.category == null ? catReviews?.rating : aggDataCategory?.rating;
    const sentimentData =
      query?.category == null ? catReviews?.sentiment : aggDataCategory?.sentiment;

    const bktdCount = catReviews?.rating?.bktd?.filter((r: any) => r != 0).length ?? 0;
    const showNpsRating = appProvider === 'csvNPS' || bktdCount > 5;

    const groupedData = React.useMemo(() => {
      const groupedReviews = new Map<string, ReviewExtraColFromList[]>();

      // filter out the extra cols to only show for the specified apps
      const filteredExtraCols = extraCols?.filter((c) => appIdsFilter.has(c.appId.toHexString()));

      filteredExtraCols?.forEach((col) => {
        if (col.sectionId) {
          if (!groupedReviews.get(col.sectionId.toHexString())) {
            groupedReviews.set(col.sectionId.toHexString(), []);
          }
          groupedReviews.get(col.sectionId.toHexString())?.push(col);
        }
      });

      const sectionsWithCols = extraColsSections?.filter(isNotNull)?.map((sectionCol) => {
        const sections = groupedReviews.get(sectionCol._id.toHexString());
        return {
          section: sectionCol,
          data: sections,
        };
      });
      return sectionsWithCols?.filter((v) => v.data != null && v.data.length > 0);
    }, [extraColsSections, extraCols, appIdsFilter]);

    return (
      <FilterContainer>
        <PaddedScrollable size="small">
          <ContentSection size="small">
            <ContentDivider size="small" icon={<Search />}>
              Saved searches
            </ContentDivider>
            <TopicSection topics={report.searches} updateTopic={updateTopic} />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small" icon={<Star />}>
              Rating
            </ContentDivider>
            {!showNpsRating && <StarRatingSection data={ratingData} facetData={facets?.score} />}
            {showNpsRating && <NpsRatingSection data={ratingData} facetData={facets?.score} />}
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small" icon={<Smile />}>
              Sentiment
            </ContentDivider>
            <SentimentRatingSection data={sentimentData} facetData={facets?.sentiment} />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small">Domain Concepts</ContentDivider>
            <ConceptsSection
              concepts={domainConcepts}
              catType="domain"
              countsByCategoryId={countsByCategoryId}
              categoryFacetCounts={countsByCategory}
              onAddConceptClicked={onAddConceptClicked}
            />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small">Valence Concepts</ContentDivider>
            <ConceptsSection
              concepts={judgementConcepts}
              catType="judgement"
              countsByCategoryId={countsByCategoryId}
              categoryFacetCounts={countsByCategory}
              onAddConceptClicked={onAddConceptClicked}
            />
          </ContentSection>

          <ContentSection size="small">
            <ContentDivider size="small">Phrased Concepts</ContentDivider>

            {groupedData?.map((data, i) => {
              if (data == null) return null;

              return (
                <FilterSection name={data.section.name.toUpperCase()} key={i}>
                  {data.data?.map((data, i) => (
                    <ExtraColSection key={i} extraCol={data} />
                  ))}
                </FilterSection>
              );
            })}
          </ContentSection>
        </PaddedScrollable>
      </FilterContainer>
    );
  },
);
