import useLocalStorage from '@rehooks/local-storage';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BrandFilters } from '../../PoseidonComponents/BrandFilters';
import { Breadcrumbs } from '../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { ConceptReviewDetailPage } from '../../PoseidonComponents/ConceptReviewDetailPage';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { BrandLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import { Flex, WithFilters, WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { ReportFilters } from '../../PoseidonComponents/ReportFilters';
import { ReportProviderPicker } from '../../PoseidonComponents/ReportProviderPicker';
import { FullPageWaveSpinnerContainer } from '../../PoseidonComponents/WaveSpinnerContainer/WaveSpinnerContainer';
import Reviews from '../../components/reviews/reviews2';
import VersionHistory from '../../components/versionHistory/versionHistory';
import useRouterQuery from '../../hooks/useRouterQuery';
import { IIntervalBucket } from '../../models/aggModels/intervalBucket.entity';
import { DateTypeValue } from '../../models/dateTypeValue.model';
import {
  IntervalAndDate,
  ReportCategory,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  ServerBrand,
  ServerDataCounts,
  ServerReport,
  ServerUser,
  trpc,
} from '../../utils/trpc';

interface ReportBrandContainerProps {
  report: ServerReport;
  conceptsById: Record<string, ReportCategory>;
  intervalBucket: IIntervalBucket;
  reportConcepts?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  dataCounts?: ServerDataCounts;
  user: ServerUser;
}

export const ReportBrandContainer: React.FC<ReportBrandContainerProps> = ({
  report,
  intervalBucket,
  reportConcepts,
  conceptsById,
  extraCols,
  extraColsSections,
  dataCounts,
  user,
}) => {
  const reportId = report._id.toString();
  const { brandId } = useParams();
  const [query] = useRouterQuery();

  const reportBrandQuery = trpc.reportBrand.id.useQuery(
    { brandId: brandId || '', reportId },
    { enabled: Boolean(brandId) },
  );

  const brand = reportBrandQuery.data;

  const hasDetail =
    query.pinnedConcept != null ||
    query.rId != null ||
    query.category != null ||
    query.addConcept != null;

  if (brand == null) {
    return (
      <WithLeftNav hasDetail={hasDetail}>
        <PageHeader icon={<BrandLogo brand={brand} shape="circle" size={24} />} title={'???'}>
          <ContentDivider end={<ReportFilters />} />
        </PageHeader>
        <Flex gap="0" grow={1}>
          <FullPageWaveSpinnerContainer />
        </Flex>
      </WithLeftNav>
    );
  }

  return (
    <WithBrand
      brand={brand}
      report={report}
      conceptsById={conceptsById}
      intervalBucket={intervalBucket}
      reportConcepts={reportConcepts}
      extraCols={extraCols}
      extraColsSections={extraColsSections}
      dataCounts={dataCounts}
      user={user}
    />
  );
};

interface WithBrandProps {
  report: ServerReport;
  conceptsById: Record<string, ReportCategory>;
  intervalBucket: IIntervalBucket;
  reportConcepts?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  dataCounts?: ServerDataCounts;
  brand: ServerBrand;
  user: ServerUser;
}
const WithBrand: React.FC<WithBrandProps> = ({
  brand,
  report,
  conceptsById,
  intervalBucket,
  reportConcepts,
  extraCols,
  extraColsSections,
  dataCounts,
  user,
}) => {
  const reportId = report._id.toString();
  const brandId = brand._id.toString();
  const [query] = useRouterQuery();
  const versionHistoryIntervalType = intervalBucket?.type ?? 'month';

  const hasDetail =
    query.pinnedConcept != null ||
    query.rId != null ||
    query.category != null ||
    query.addConcept != null ||
    query.machine != null;

  const [dateTypeValue] = useLocalStorage<DateTypeValue>('dateTypeValue', {
    type: undefined,
    value: undefined,
  });
  const dateValue = dateTypeValue.value;
  const dateType = dateTypeValue.type;

  const intervalAndDate: IntervalAndDate =
    dateValue != null && dateType != null
      ? {
          type: dateType,
          dateMillis: dateValue,
        }
      : { type: 'infinity', dateMillis: 0 };

  const reportReviewsQuery = trpc.reportReviews.forBrandOld.useInfiniteQuery(
    {
      reportId,
      brandId,
      intervalAndDate,
      query: query.q,
      filters: {
        rating: query.rating ? Number.parseFloat(query.rating) : undefined,
        sRating: query.sRating ? Number.parseFloat(query.sRating) : undefined,
        conceptAspect: query.conceptAspect ? Number.parseFloat(query.conceptAspect) : undefined,
        category: query.category,
      },
      extrasFilters: query.extras,
      limit: query.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: query.page,
    },
  );

  const firstReviewId = reportReviewsQuery.data?.pages?.[0]?.reviews?.[0]?._id.toHexString();

  return (
    <WithLeftNav hasDetail={hasDetail}>
      <PageHeader
        icon={<BrandLogo brand={brand} shape="circle" size={24} />}
        title={brand?.name ?? 'Brand'}>
        <ContentDivider end={<ReportFilters firstReviewId={firstReviewId} />}>
          <Breadcrumbs showStartingChevron>
            <ReportProviderPicker reportId={reportId} brand={brand} />
          </Breadcrumbs>
        </ContentDivider>
      </PageHeader>

      <Flex gap="0" grow={1}>
        <BrandFilters
          report={report}
          brand={brand}
          intervalBucket={intervalBucket}
          reportCategories={reportConcepts}
          extraCols={extraCols}
          extraColsSections={extraColsSections}
        />

        <WithFilters>
          {query.section == 'versionHistory' && (
            <VersionHistory
              reportId={reportId}
              brandId={brand._id.toHexString()}
              intervalType={versionHistoryIntervalType}
              categoryId={query.category}
            />
          )}
          {query.section != 'versionHistory' && (
            <Reviews
              report={report}
              reviews={reportReviewsQuery.data?.pages}
              conceptsById={conceptsById}
              fetchNextPage={
                reportReviewsQuery.hasNextPage ? reportReviewsQuery.fetchNextPage : undefined
              }
              fetchPreviousPage={
                reportReviewsQuery.hasPreviousPage
                  ? reportReviewsQuery.fetchPreviousPage
                  : undefined
              }
              isFetchingNextPage={reportReviewsQuery.isFetchingNextPage}
              isFetchingPreviousPage={reportReviewsQuery.isFetchingPreviousPage}
            />
          )}
        </WithFilters>
      </Flex>
      {hasDetail && (
        <ConceptReviewDetailPage
          conceptsById={conceptsById}
          report={report}
          user={user}
          dataCounts={dataCounts}
        />
      )}
    </WithLeftNav>
  );
};
