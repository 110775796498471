import React from 'react';
import { AuthProvider } from './auth.context';
import { ReportsProvider } from './reports.context';
import { ReviewsProvider } from './reviews.context';
export { AuthProvider, useAuthContext } from './auth.context';
export { ReportsProvider, useReportsContext } from './reports.context';
export { ReviewsProvider, useReviewsContext } from './reviews.context';

export const Providers: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  return (
    <AuthProvider>
      <ReportsProvider>
        <ReviewsProvider>
          {children}
        </ReviewsProvider>
      </ReportsProvider>
    </AuthProvider>
  );
};
