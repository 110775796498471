import { getAuth, signOut } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WaveSpinner } from '../PoseidonComponents/WaveSpinner';
import { useReportsContext, useReviewsContext } from '../contexts';

export const Logout = () => {
  const navigate = useNavigate();

  const [, { reset: reportsActionsReset }] = useReportsContext();
  const [, { reset: reviewsActionsReset }] = useReviewsContext();

  React.useEffect(() => {
    const fn = async () => {
      reportsActionsReset();
      reviewsActionsReset();
      const auth = getAuth();
      await signOut(auth);
    };
    fn();
  }, [navigate, reportsActionsReset, reviewsActionsReset]);
  return <WaveSpinner />;
};
