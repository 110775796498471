import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import ConceptsSection from '../../components/conceptsSection/conceptsSection';
import { ExtraColSection } from '../../components/extraColSection/extraColSection';
import FilterSection from '../../components/filterSection/filterSection';
import SentimentRatingSection from '../../components/sentimentRatingSection/sentimentRatingSection';
import StarRatingSection, {
  NpsRatingSection,
} from '../../components/sentimentRatingSection/starRatingSection';
import useRouterQuery from '../../hooks/useRouterQuery';
import { IIntervalBucket } from '../../models/aggModels/intervalBucket.entity';
import { useReportUpdateMutation } from '../../trpcHooks/useReportMutation';
import {
  ReportCategory,
  ReportReviewFacets,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  ServerReport,
  ServerReportApp,
  trpc,
} from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { PaddedScrollable } from '../PaddedScrollable/PaddedScrollable';
import { Search, Smile, Star } from '../StrokeIcons';
import TopicSection from '../TopicSection';
import styles from './Filters.module.scss';

interface FiltersProps {
  report: ServerReport;
  app: ServerReportApp;
  reportCategories?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  appIdsFilter: Set<string>;
  facets: ReportReviewFacets | undefined;
  onAddConceptClicked?: () => void;
  intervalBucket: IIntervalBucket;
}

export const Filters: React.FC<FiltersProps> = React.memo(
  ({
    report,
    app,
    reportCategories,
    extraCols,
    extraColsSections,
    appIdsFilter,
    facets,
    intervalBucket,
    onAddConceptClicked,
  }) => {
    const reportId = report._id.toHexString();
    const metaId = app.metaId.toHexString();
    const [search] = useRouterQuery();

    const intervalBucketDate = intervalBucket.date;
    const intervalBucketType = intervalBucket.type;

    const reportAppCounts = trpc.reportApp.dataCounts.useQuery({
      reportId,
      metaId,
      timeInterval: { value: intervalBucketDate || 0, type: intervalBucketType || 'infinity' },
    });

    const updateActiveMutation = useReportUpdateMutation();
    const [filtersMinimized] = useLocalStorage('filtersMinimized', false);

    const countsByCategory = React.useMemo(
      () => new Map(facets?.categories.buckets.map((b) => [b._id, b.count])),
      [facets],
    );

    const [domainConcepts, judgementConcepts] = React.useMemo(() => {
      let domainConcepts: ReportCategory[] = [];
      let judgementConcepts: ReportCategory[] = [];
      reportCategories?.forEach((concept) => {
        if (concept.catType === 'judgement') {
          judgementConcepts.push(concept);
        } else if (concept.catType === 'domain') {
          domainConcepts.push(concept);
        }
      });
      return [domainConcepts, judgementConcepts];
    }, [reportCategories]);

    const updateTopic = (searches?: string[]) => {
      const id = report._id.toHexString();
      updateActiveMutation.mutate({ id, data: { searches } });
    };

    const appProvider = app.appProviderOverride || app.appProvider;

    const countsByCategoryId = reportAppCounts.data;
    const catReviews = countsByCategoryId?._review;
    const aggDataCategory = search.category ? countsByCategoryId?.[search.category] : undefined;

    const ratingData = search?.category == null ? catReviews?.rating : aggDataCategory?.rating;
    const sentimentData =
      search?.category == null ? catReviews?.sentiment : aggDataCategory?.sentiment;

    const bktdCount = catReviews?.rating?.bktd?.filter((r: any) => r != 0).length ?? 0;
    const showNpsRating = appProvider === 'csvNPS' || bktdCount > 5;

    const groupedData = React.useMemo(() => {
      const groupedReviews = new Map<string, ReviewExtraColFromList[]>();

      // filter out the extra cols to only show for the specified apps
      const filteredExtraCols = extraCols?.filter((c) => appIdsFilter.has(c.appId.toHexString()));

      filteredExtraCols?.forEach((col) => {
        if (col.sectionId) {
          if (!groupedReviews.get(col.sectionId.toHexString())) {
            groupedReviews.set(col.sectionId.toHexString(), []);
          }
          groupedReviews.get(col.sectionId.toHexString())?.push(col);
        }
      });

      const sectionsWithCols = extraColsSections?.filter(isNotNull)?.map((sectionCol) => {
        const sections = groupedReviews.get(sectionCol._id.toHexString());
        return {
          section: sectionCol,
          data: sections,
        };
      });
      return sectionsWithCols?.filter((v) => v.data != null && v.data.length > 0);
    }, [extraColsSections, extraCols, appIdsFilter]);

    return (
      <div className={styles.container}>
        <PaddedScrollable size='small'>
          <ContentSection size='small'>
            <ContentDivider size='small' icon={<Search />}>Saved searches</ContentDivider>
            <TopicSection topics={report.searches} updateTopic={updateTopic} />
          </ContentSection>

          <ContentSection size='small'>
            <ContentDivider size='small' icon={<Star />}>Rating</ContentDivider>
            {!showNpsRating && <StarRatingSection data={ratingData} facetData={facets?.score} />}
            {showNpsRating && <NpsRatingSection data={ratingData} facetData={facets?.score} />}
          </ContentSection>

          <ContentSection size='small'>
            <ContentDivider size='small' icon={<Smile />}>Sentiment</ContentDivider>
            <SentimentRatingSection data={sentimentData} facetData={facets?.sentiment} />
          </ContentSection>

          <ContentSection size='small'>
            <ContentDivider size='small'>Domain Concepts</ContentDivider>
            <ConceptsSection
              concepts={domainConcepts}
              catType="domain"
              countsByCategoryId={countsByCategoryId}
              categoryFacetCounts={countsByCategory}
              onAddConceptClicked={onAddConceptClicked}
            />
          </ContentSection>

          <ContentSection size='small'>
            <ContentDivider size='small'>Valence Concepts</ContentDivider>
            <ConceptsSection
              concepts={judgementConcepts}
              catType="judgement"
              countsByCategoryId={countsByCategoryId}
              categoryFacetCounts={countsByCategory}
              onAddConceptClicked={onAddConceptClicked}
            />
          </ContentSection>

          <ContentSection size='small'>
            <ContentDivider size='small'>Phrased Concepts</ContentDivider>

            {groupedData?.map((data, i) => {
              if (data == null) return null;

              return (
                <FilterSection name={data.section.name.toUpperCase()} key={i}>
                  {data.data?.map((data, i) => (
                    <ExtraColSection key={i} extraCol={data} />
                  ))}
                </FilterSection>
              );
            })}
          </ContentSection>
        </PaddedScrollable>
      </div>
    );
  },
);

export default Filters;
