import { FloatingFocusManager } from '@floating-ui/react';
import useLocalStorage from '@rehooks/local-storage';
import { TRPCClientError } from '@trpc/client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  Gear,
  Logout,
  Mail,
  People,
  PersonAdd,
  TrashCan,
  Warning,
} from 'src/PoseidonComponents/StrokeIcons';
import { ReportsLeftNav } from 'src/components/LeftNav/LeftNav';
import { useTeamDelete, useTeamMemberInviteMutation } from 'src/trpcHooks/useTeamMutation';
import { ServerTeam, ServerUser, trpc } from 'src/utils/trpc';
import { isNotNull } from 'src/utils2';
import {
  BreadCrumb,
  BreadCrumbLink,
  Breadcrumbs,
} from '../../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { Button, LinkButton } from '../../../PoseidonComponents/Button/Button';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../../PoseidonComponents/ContentSection/ContentSection';
import { FullHeight } from '../../../PoseidonComponents/FullHeight/FullHeight';
import { TeamLogo } from '../../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import { Input } from '../../../PoseidonComponents/Input/Input';
import { Left, WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../../PoseidonComponents/PageHeader/PageHeader';
import { usePopover } from '../../../PoseidonComponents/Popover';
import { PopoverContent } from '../../../PoseidonComponents/PopoverContent/PopoverContent';
import { DeletePopup } from '../../../PoseidonComponents/Popup2/Popup2';
import { TeamProfileEditSection } from '../../../PoseidonComponents/TeamProfileEditSection/TeamProfileEditSection';
import { UsersTable } from '../../../PoseidonComponents/UsersTable';

interface Props {
  user: ServerUser;
  team: ServerTeam | undefined;
}
const SettingsTeamsActive: React.FC<Props> = ({ user, team }) => {
  const [shouldDeleteTeam, setShouldDeleteTeam] = React.useState(false);
  const teamDeleteMutation = useTeamDelete();
  const navigate = useNavigate();

  const teamId = team?.team._id.toHexString();

  const deleteTeam = async () => {
    if (teamId) {
      try {
        await teamDeleteMutation.mutateAsync(teamId);
        setShouldDeleteTeam(false);
        navigate(`/teams`);
      } catch (e: unknown) {
        if (e instanceof TRPCClientError) {
          toast.error(e.message);
        }
      }
    }
  };
  const [navMinimized] = useLocalStorage('navMinimized', false);

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Gear />} title="Settings">
          <ContentDivider
            end={
              <LinkButton size="small" to="/teams">
                Switch teams
              </LinkButton>
            }>
            <Breadcrumbs showStartingChevron>
              <BreadCrumbLink to="/teams">Teams</BreadCrumbLink>
              <BreadCrumb>
                {team && <TeamLogo team={team.team} size={16} shape="circle" />}
                {team?.team?.name}
              </BreadCrumb>
            </Breadcrumbs>
          </ContentDivider>
        </PageHeader>

        <PaddedScrollable>
          <ContentSection>
            {team && <TeamProfileEditSection user={user} team={team.team} key={teamId} />}
          </ContentSection>

          {team && <MembersSection user={user} team={team.team} />}

          <ContentSection>
            <ContentDivider icon={<Warning />}>Dangerous actions</ContentDivider>
            <Left>
              {team?.team.adminId === user._id && (
                <Button
                  icon={<TrashCan />}
                  type="dangerous"
                  onClick={() => setShouldDeleteTeam(true)}>
                  Delete Team
                </Button>
              )}

              <LinkButton icon={<Logout />} to="/logout" type="dangerous">
                Log out
              </LinkButton>
            </Left>
          </ContentSection>
        </PaddedScrollable>

        {shouldDeleteTeam && (
          <DeletePopup onClose={() => setShouldDeleteTeam(false)} onDelete={deleteTeam} />
        )}
      </WithLeftNav>
    </FullHeight>
  );
};

export default SettingsTeamsActive;

interface MembersSectionProps {
  user: ServerUser;
  team: ServerTeam['team'];
}
const MembersSection: React.FC<MembersSectionProps> = ({ user, team }) => {
  const teamId = team._id;

  const teamUsersQuery = trpc.team.id.useQuery(teamId.toHexString());
  const teamUsers = teamUsersQuery.data;

  const userIds = Array.from(new Set([...(team?.userIds || []), user._id]));
  const users = userIds.map((id) => teamUsers?.userById?.[id]).filter(isNotNull);
  return (
    <ContentSection>
      <ContentDivider icon={<People />} end={<InviteButton teamId={team._id.toHexString()} />}>
        Members
      </ContentDivider>
      <UsersTable user={user} team={team} users={users} />
    </ContentSection>
  );
};

interface InviteButtonForm {
  email: string;
}
interface InviteButtonProps {
  teamId: string;
}
const InviteButton: React.FC<InviteButtonProps> = ({ teamId }) => {
  const teamInviteMember = useTeamMemberInviteMutation();
  const { control, handleSubmit, resetField } = useForm<InviteButtonForm>({
    mode: 'all',
  });

  const { refs, x, y, isOpen, setIsOpen, context, getReferenceProps, getFloatingProps, strategy } =
    usePopover();

  const onSubmit = async (data: InviteButtonForm) => {
    await teamInviteMember.mutateAsync({ id: teamId, email: data.email });
    setIsOpen(false);
    resetField('email');
  };

  return (
    <>
      <Button size="small" icon={<PersonAdd />} ref={refs.setReference} {...getReferenceProps()}>
        Invite
      </Button>

      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <PopoverContent
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input size="small" icon={<Mail />} placeholder="Email" {...field} />
                )}
              />
              <input type="button" style={{ display: 'none' }} />
            </form>
          </PopoverContent>
        </FloatingFocusManager>
      )}
    </>
  );
};
