import React from 'react';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PageHeader } from '../../../PoseidonComponents/PageHeader/PageHeader';
import { ServerReport } from '../../../utils/trpc';
import { isStringNotEmpty } from '../../../utils2';
import { Charts } from '../../../PoseidonComponents/StrokeIcons';
import styles from './dashboard.module.scss';
import DatePicker from '../../../components/datePicker/datePicker';

interface ReportAdminProps {
  report: ServerReport;
}

export const ReportDashboard: React.FC<ReportAdminProps> = ({ report }) => {
  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<Charts />} title="Dashboard">
        <ContentDivider end={<DatePicker groupBy="quarter" />} />
      </PageHeader>
      <div className={styles.container}>
        {report.flourishIds?.filter(isStringNotEmpty).map((id) => (
          <FlourishChart key={id} flourishId={id} />
        ))}
      </div>
    </WithLeftNav>
  );
};

export default ReportDashboard;

interface FlourishChartProps {
  flourishId: string;
}
const FlourishChart: React.FC<FlourishChartProps> = ({ flourishId }) => {
  const src = `https://flo.uri.sh/visualisation/${flourishId}/embed`;
  const style = { width: '100%', height: '600px' };
  const sandbox =
    'allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation';
  return (
    <div className={styles.card}>
      <iframe
        src={src}
        className="flourish-embed-iframe"
        frameBorder="0"
        scrolling="no"
        style={style}
        sandbox={sandbox}
      />
    </div>
  );
};
