import { useLocalStorage } from '@rehooks/local-storage';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Intersect } from 'src/PoseidonComponents/StrokeIcons';
import { Button } from '../PoseidonComponents/Button/Button';
import { CreateButton } from '../PoseidonComponents/Button/CustomButton';
import { ConceptGroupCard } from '../PoseidonComponents/ConceptGroupCard/ConceptGroupCard';
import { ContentDivider } from '../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../PoseidonComponents/ContentSection/ContentSection';
import { Form } from '../PoseidonComponents/Form/Form';
import { FullHeight } from '../PoseidonComponents/FullHeight/FullHeight';
import { Gap } from '../PoseidonComponents/Gap';
import { ColumnsGrid } from '../PoseidonComponents/Grid/Grid';
import { WithLeftNav } from '../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../PoseidonComponents/PageHeader/PageHeader';
import { WaveSpinner } from '../PoseidonComponents/WaveSpinner';
import { ReportsLeftNav } from '../components/LeftNav/LeftNav';
import DeletePopup from '../components/deletePopup/deletePopup';
import { useConceptGroupDelete, useConceptGroupsCreate } from '../trpcHooks/useConceptGroups';
import { RQUtils, conceptGroupsRQKey } from '../utils/reactQuery.utils';
import {
  ConceptGroup,
  ConceptGroupUpdateParams,
  ServerTeam,
  ServerUser,
  trpc,
} from '../utils/trpc';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface Props {
  user: ServerUser;
  team: ServerTeam | undefined;
}

export const ConceptGroups: React.FC<Props> = ({ user, team }) => {
  const [showDeletePopupId, setShowDeletePopupId] = React.useState<string>();
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const [isAdding, setIsAdding] = React.useState<boolean>();
  const [addingData, setAddingData] = React.useState<{ name?: string }>({});

  const queryClient = useQueryClient();
  const conceptGroupsRes = trpc.conceptGroups.list.useQuery();
  const conceptGroupCreateMut = useConceptGroupsCreate();
  const conceptGroupDelete = useConceptGroupDelete();

  const conceptGroups = conceptGroupsRes.data?.conceptGroups;
  const conceptById = conceptGroupsRes.data?.conceptById;

  const addConceptGroupSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const name = addingData.name;
    if (name == null) {
      return;
    }

    await conceptGroupCreateMut.mutateAsync({ toCreate: { name } });

    // clear out the state
    setAddingData({});
    setIsAdding(false);
  };

  const deleteConceptGroup = (conceptGroupId: string) => {
    if (conceptGroupDelete.isLoading == true) {
      return;
    }
    conceptGroupDelete.mutate({ conceptGroupId });
  };

  const rqUpdateConceptGroups = (updated: ConceptGroup) => {
    queryClient.setQueryData<ConceptGroup[]>(conceptGroupsRQKey(), (old) => {
      return RQUtils.updateArrQueryV2(old, updated, '_id');
    });
  };

  const updateConceptGroupMut = trpc.conceptGroups.update.useMutation({
    onSuccess: rqUpdateConceptGroups,
  });
  const updateConceptGroup = (conceptGroupId: string, updateVals: ConceptGroupUpdateParams) => {
    if (updateConceptGroupMut.isLoading == true) {
      return;
    }
    updateConceptGroupMut.mutate({ conceptGroupId, toUpdate: updateVals });
  };

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  const closeDeletePopup = (deleteId?: string) => {
    if (deleteId) {
      deleteConceptGroup(deleteId);
    }
    setShowDeletePopupId(undefined);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddingData({ ...addingData, name: e.target.value });
  };

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Intersect />} title="Concept Groups">
          <ContentDivider
            end={
              <>
                <Button size="small" onClick={() => setIsAdding(true)}>
                  Create
                </Button>
              </>
            }
          />
        </PageHeader>

        <PaddedScrollable>
          {isAdding && (
            <>
              <Form onSubmit={addConceptGroupSubmit} width="restricted">
                <ContentSection>
                  <Input
                    placeholder="Group Name"
                    name="groupName"
                    onChange={handleChangeInput}
                    value={addingData.name || ''}
                    autoFocus
                  />
                  <CreateButton isLoading={conceptGroupCreateMut.isLoading} />
                </ContentSection>
              </Form>
              <Gap height={10} />
            </>
          )}
          <ContentSection>
            <ColumnsGrid gap={20} columns={3} columnMinWidth={250}>
              {conceptGroupsRes.isLoading && <WaveSpinner />}
              {conceptGroups?.map((c) => (
                <div key={c._id.toHexString()}>
                  <ConceptGroupCard
                    data={c}
                    deleteConceptGroup={setShowDeletePopupId}
                    conceptById={conceptById}
                  />
                </div>
              ))}
            </ColumnsGrid>
          </ContentSection>
        </PaddedScrollable>
      </WithLeftNav>

      {showDeletePopupId != null && (
        <DeletePopup
          onClose={() => closeDeletePopup()}
          onSubmit={() => closeDeletePopup(showDeletePopupId)}
        />
      )}
    </FullHeight>
  );
};

export default ConceptGroups;
