import { useDraggable } from '@dnd-kit/core';
import React from 'react';
import { ServerReportBrand } from 'src/utils/trpc';
import { BrandLogo } from '../IconWithPlaceholder/IconWithPlaceholder';
import styles from './draftReportSections.module.scss';

interface Props {
  brand: ServerReportBrand;
}

export const Item: React.FC<Props> = ({ brand }) => {
  return (
    <div className={styles.brandLogo}>
      <BrandLogo className={styles.item} brand={brand} size={32} shape="circle" />
    </div>
  );
};

interface SortableItemProps {
  id: string;
  groupId?: string;
  brand: ServerReportBrand;
  deleteBrand?: (name: string, groupName?: string) => void;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, groupId, brand, deleteBrand }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data: { groupId },
  });

  return (
    <div
      ref={setNodeRef}
      className={isDragging ? styles.dragging : undefined}
      {...attributes}
      onClick={() => deleteBrand?.(id, groupId)}
      {...listeners}>
      <Item brand={brand} />
    </div>
  );
};
