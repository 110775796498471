import React from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { isMobile } from 'react-device-detect';
import usePortal from '../usePortal';

import styles from './tooltip.module.scss';
import { QueryMachine } from 'src/PoseidonComponents/ReviewCard/ReviewCard';

const initialState: any = {
  top: 0,
  left: 0,
  height: 0,
  width: 0,
  visible: false,
  position: null,
  tooltipPosition: {
    top: 0,
    left: 0,
  },
  arrowPosition: {},
};

const reducer = (state: any, action: any) => {
  if ('SET_POSITION' === action.type) {
    return {
      ...state,
      top: action.top,
      left: action.left,
      width: action.width,
      height: action.height,
      visible: action.visible,
    };
  } else if ('SET_TOOLTIP' === action.type) {
    return {
      ...state,
      position: action.position,
      tooltipPosition: {
        top: action.top,
        left: action.left,
      },
      arrowPosition: {
        top: action.arrowTop,
        left: action.arrowLeft,
      },
    };
  } else if ('RESET' === action.type) {
    return initialState;
  } else {
    throw new Error();
  }
};

export const TooltipPortal: React.FC<React.HTMLProps<HTMLDivElement>> = ({ id, children }) => {
  const target = usePortal(id || 'modal-tooltip');

  if (target) {
    return createPortal(children, target);
  } else {
    return null;
  }
};

const check_top = ({
  state,
  arrowSize,
  offsetWidth,
  offsetHeight,
  offset,
  padding,
  scrollX,
  scrollY,
  maxLeft,
}: any) => {
  if (state.top - offsetHeight - offset - padding > 0) {
    const top = state.top - offsetHeight - offset + scrollY;
    const left = Math.max(
      scrollX + padding,
      Math.min(state.left + (state.width - offsetWidth) / 2 + scrollX, maxLeft),
    );
    const correction = state.left - left - (offsetWidth - state.width) / 2;
    const position = 'top';
    const arrowTop = offsetHeight;
    const arrowLeft = offsetWidth / 2 - arrowSize + scrollX + correction;
    return { top, left, position, arrowTop, arrowLeft };
  }
  return null;
};

const check_right = ({
  state,
  arrowSize,
  offsetWidth,
  offsetHeight,
  innerWidth,
  offset,
  padding,
  scrollX,
  scrollY,
  maxTop,
}: any) => {
  if (state.left + state.width + offsetWidth + offset + padding < innerWidth) {
    const top = Math.max(
      scrollY + padding,
      Math.min(state.top + (state.height - offsetHeight) / 2 + scrollY, maxTop),
    );
    const left = state.left + state.width + offset + scrollX;
    const position = 'right';
    const correction = state.top - top - (offsetHeight - state.height) / 2;
    const arrowTop = offsetHeight / 2 - arrowSize + scrollY + correction;
    const arrowLeft = -arrowSize;
    return { top, left, position, arrowTop, arrowLeft };
  }
  return null;
};

const check_left = ({
  state,
  arrowSize,
  offsetWidth,
  offsetHeight,
  offset,
  padding,
  scrollX,
  scrollY,
  maxTop,
}: any) => {
  if (state.left - state.width - offset - padding > 0) {
    const top = Math.max(
      scrollY + padding,
      Math.min(state.top + (state.height - offsetHeight) / 2 + scrollY, maxTop),
    );
    const left = state.left - offsetWidth - offset + scrollX;
    const position = 'left';
    const correction = state.top - top - (offsetHeight - state.height) / 2;
    const arrowTop = offsetHeight / 2 - arrowSize + scrollY + correction;
    const arrowLeft = offsetWidth;
    return { top, left, position, arrowTop, arrowLeft };
  }
  return null;
};
const check_bottom = ({
  state,
  arrowSize,
  offsetWidth,
  offsetHeight,
  innerHeight,
  offset,
  padding,
  scrollX,
  scrollY,
  maxLeft,
}: any) => {
  if (state.top + state.height + offsetHeight + offset + padding < innerHeight) {
    const top = state.top + state.height + offset + scrollY;
    const left = Math.max(
      scrollX + padding,
      Math.min(state.left + (state.width - offsetWidth) / 2 + scrollX, maxLeft),
    );
    const correction = state.left - left - (offsetWidth - state.width) / 2;
    const position = 'bottom';
    const arrowTop = -arrowSize;
    const arrowLeft = offsetWidth / 2 - arrowSize + scrollX + correction;
    return { top, left, position, arrowTop, arrowLeft };
  }
  return null;
};

type Position = 'top' | 'right' | 'left' | 'bottom';

interface TooltipProps extends React.HTMLProps<HTMLDivElement> {
  manual?: boolean;
  tagName?: any;
  visible?: boolean;
  content?: any;
  background?: string;
  arrowOffset?: number;
  tooltipClassName?: string;
  cx?: number;
  cy?: number;
  r?: number;
  offset?: number;
  rounded?: boolean;
  shadow?: string;
  position?: Position | Position[];
}

const Tooltip: React.FC<TooltipProps> = ({ manual, visible, ...props }) => {
  const Tag = props.tagName || 'div';
  if (isMobile) return <Tag {...props} />;

  if (!manual) return <AutoTooltip {...props} />;
  else return <ManualTooltip visible={visible} {...props} />;
};

interface ManualTooltipProps {
  manual?: boolean;
  tagName?: any;
  visible?: boolean;
  background?: string;
  shadow?: string;
  arrowSize?: number;
  arrowOffset?: number;
  padding?: number;
  content?: any;
  position?: Position | Position[];
  tooltipClassName?: string;
  color?: string;
}

const ManualTooltip: React.FC<ManualTooltipProps> = ({
  tagName,
  background,
  shadow,
  arrowSize = 6,
  arrowOffset = 0,
  padding = 6,
  content,
  position,
  tooltipClassName,
  color,
  visible,
  ...props
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const el = React.useRef(null);
  const tagEl = React.useRef<any>(null);
  const Tag = tagName || 'div';

  const { top, left, width, height } = state;

  React.useLayoutEffect(() => {
    if (tagEl == null || tagEl.current == null) return;

    if (visible) {
      const { top, left, width, height } = tagEl.current?.getBoundingClientRect?.();
      dispatch({ type: 'SET_POSITION', top, left, width, height, visible: true });
    } else if (!visible) {
      dispatch({ type: 'RESET' });
    }
  }, [visible]);

  React.useLayoutEffect(() => {
    if (state.visible && el?.current) {
      const offset = arrowSize + arrowOffset;
      const { innerWidth, innerHeight, scrollX, scrollY } = window;
      const { offsetWidth, offsetHeight } = el.current;
      const maxLeft = innerWidth - offsetWidth + scrollX - padding;
      const maxTop = innerHeight - offsetHeight + scrollY - padding;
      const heuristic = getPositionHeuristic(position);

      const data = {
        state: {
          top,
          left,
          width,
          height,
        },
        arrowSize,
        offsetWidth,
        offsetHeight,
        offset,
        padding,
        scrollX,
        scrollY,
        maxLeft,
        maxTop,
        innerWidth,
        innerHeight,
      };

      let returnData = null;
      for (var i = 0; i < heuristic.length; i++) {
        switch (heuristic[i]) {
          case 'top':
            returnData = check_top(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          case 'left':
            returnData = check_left(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          case 'right':
            returnData = check_right(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          case 'bottom':
            returnData = check_bottom(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          default:
            break;
        }
      }
    }
  }, [state.visible, el, arrowSize, arrowOffset, padding, position, top, left, width, height]);

  const tooltipStyle = {
    ...state.tooltipPosition,
    '--arrow-size': `${arrowSize}px`,
    '--background-color': background || 'rgba(0, 0, 0, 0.85)',
    '--shadow': shadow || `0 2px 4px rgba(0,0,0,.5)`,
  };

  return (
    <>
      <Tag {...props} ref={tagEl} />
      {visible && (
        <TooltipPortal>
          <div
            className={clsx(styles.container, tooltipClassName, styles[state.position])}
            ref={el}
            style={tooltipStyle}>
            {content}
            <div
              className={clsx(styles.arrow, styles[state.position])}
              style={state.arrowPosition}
            />
          </div>
        </TooltipPortal>
      )}
    </>
  );
};

const getPositionHeuristic = (position?: Position | Position[]) => {
  const defaultHeuristic: Position[] = ['top', 'right', 'left', 'bottom'];

  if (position == null) return defaultHeuristic;

  if (Array.isArray(position)) {
    return position.concat(defaultHeuristic.filter((h) => !position.includes(h)));
  } else {
    const positionIndex = defaultHeuristic.indexOf(position);
    return defaultHeuristic.slice(positionIndex).concat(defaultHeuristic.slice(0, positionIndex));
  }
};

interface AutoTooltipProps extends React.HTMLProps<HTMLDivElement> {
  manual?: boolean;
  tagName?: any;
  background?: string;
  shadow?: string;
  arrowSize?: number;
  arrowOffset?: number;
  padding?: number;
  content?: any;
  position?: any;
  tooltipClassName?: string;
  color?: string;
}
const AutoTooltip: React.FC<AutoTooltipProps> = ({
  tagName,
  background,
  shadow,
  arrowSize = 6,
  arrowOffset = 0,
  padding = 6,
  content,
  position,
  tooltipClassName,
  color,
  ...props
}) => {
  const el = React.useRef(null);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const isVisible = state.visible;
  const { top, left, width, height } = state;

  const onMouseEnter = (e: any) => {
    if (props.onMouseEnter) props.onMouseEnter(e);
    const { top, left, width, height } = e.currentTarget.getBoundingClientRect();
    dispatch({ type: 'SET_POSITION', top, left, width, height, visible: true });
  };
  const onMouseLeave = (e: any) => {
    if (props.onMouseLeave) props.onMouseLeave(e);
    dispatch({ type: 'RESET' });
  };

  React.useEffect(() => {
    if (state.visible && el?.current) {
      const offset = arrowSize + arrowOffset;
      const { innerWidth, innerHeight, scrollX, scrollY } = window;
      const { offsetWidth, offsetHeight } = el.current;
      const maxLeft = innerWidth - offsetWidth + scrollX - padding;
      const maxTop = innerHeight - offsetHeight + scrollY - padding;
      const heuristic = getPositionHeuristic(position);

      const data = {
        state: {
          top,
          left,
          width,
          height,
        },
        arrowSize,
        offsetWidth,
        offsetHeight,
        offset,
        padding,
        scrollX,
        scrollY,
        maxLeft,
        maxTop,
        innerWidth,
        innerHeight,
      };

      let returnData = null;
      for (var i = 0; i < heuristic.length; i++) {
        switch (heuristic[i]) {
          case 'top':
            returnData = check_top(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          case 'left':
            returnData = check_left(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          case 'right':
            returnData = check_right(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          case 'bottom':
            returnData = check_bottom(data);
            if (returnData != null) {
              dispatch({ type: 'SET_TOOLTIP', ...returnData });
              return;
            }
            break;
          default:
            break;
        }
      }
    }
  }, [state.visible, el, arrowSize, arrowOffset, padding, position, top, left, width, height]);

  const tooltipStyle = {
    ...state.tooltipPosition,
    '--arrow-size': `${arrowSize}px`,
    '--background-color': background || 'rgba(0, 0, 0, 0.85)',
    '--shadow': shadow || `0 2px 4px rgba(0,0,0,.5)`,
  };

  const Tag = tagName || 'div';

  return (
    <>
      <Tag {...props} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} />
      {isVisible && (
        <TooltipPortal>
          <div
            className={clsx(styles.container, tooltipClassName, styles[state.position])}
            ref={el}
            style={tooltipStyle}>
            {_renderContent(content)}
            <div
              className={clsx(styles.arrow, styles[state.position])}
              style={state.arrowPosition}
            />
          </div>
        </TooltipPortal>
      )}
    </>
  );
};

const _renderContent = (content: any) => {
  if (Array.isArray(content)) {
    return (
      <>
        {content.map((c, idx) => (
          <div key={idx}>{c}</div>
        ))}
      </>
    );
  } else {
    return content;
  }
};

export default Tooltip;

interface TooltipPopupProps extends React.HTMLProps<HTMLDivElement> {
  arrowPosition?: any;
  position?: Position;
  tooltipClassName?: string;
  arrowSize?: number;
  background?: string;
  arrowOffset?: number;
  padding?: number;
  shadow?: string;
  containerSize?: any;
  offset?: any;
}
export const TooltipPopup: React.FC<TooltipPopupProps> = ({
  arrowPosition,
  position = 'top',
  tooltipClassName,
  children,
  arrowSize = 6,
  background,
  arrowOffset = 0,
  padding = 6,
  shadow,
  containerSize = {},
  offset = {},
}) => {
  const d: any = React.useMemo(() => {
    const heuristic = getPositionHeuristic(position);

    const containerSizeBbox = containerSize.bbox;

    const data = {
      state: {
        top: offset.top - (containerSizeBbox?.top ?? 0),
        left: offset.left - (containerSizeBbox?.left ?? 0),
        width: offset.width,
        height: offset.height,
      },
      arrowSize,
      offsetWidth: offset.width,
      offsetHeight: 18, // offset.height,
      offset: arrowSize + arrowOffset,
      padding,
      scrollX: offset.scrollLeft || 0,
      scrollY: offset.scrollTop || 0,
      maxLeft: containerSizeBbox?.width ?? 0,
      maxTop: containerSizeBbox?.height ?? 0,
      innerWidth: containerSizeBbox?.width ?? 0,
      innerHeight: containerSizeBbox?.height ?? 0,
    };

    let returnData = null;
    for (var i = 0; i < heuristic.length; i++) {
      switch (heuristic[i]) {
        case 'top':
          returnData = check_top(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        case 'left':
          returnData = check_left(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        case 'right':
          returnData = check_right(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        case 'bottom':
          returnData = check_bottom(data);
          if (returnData != null) {
            return returnData;
          }
          break;
        default:
          return {};
      }
    }
    return {};
  }, [arrowSize, arrowOffset, padding, position, containerSize.bbox, offset]);

  const tooltipStyle = {
    top: d.top,
    left: d.left,
    '--arrow-size': `${arrowSize}px`,
    '--background-color': background || 'rgba(0, 0, 0, 0.85)',
    '--shadow-color': shadow || 'rgba(0,0,0,.5)',
  };

  return (
    <div
      className={clsx(styles.container, tooltipClassName, styles[d.position])}
      style={tooltipStyle}>
      {children}
      <div className={clsx(styles.arrow, styles[d.position])} style={arrowPosition} />
    </div>
  );
};
