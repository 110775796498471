import { omit } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AppRow, { SearchAppRow } from 'src/components/AppRow/appRow';
import { DraftReportsSectionsAddingSource } from 'src/components/draftReportSections/draftReportsSectionsAddingSource';
import EditableLabel from 'src/components/editableLabel/editableLabel';
import Search from 'src/components/search/search';
import { useReportBrandUpdateMutation } from 'src/trpcHooks/useReportBrandMutation';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import { useReportsContext } from '../../contexts';
import Tooltip from '../../libs/context-tooltip/tooltip';
import { sourcesById } from '../../sources';
import { useAddBrandMutation } from '../../trpcHooks/useReportBrandMutation';
import { useAddAppMutation, useDeleteAppMutation } from '../../trpcHooks/useReportAppMutation';
import { useDeleteBrandMutation, useUpdateBrandMutation } from '../../trpcHooks/useReportBrandMutation';
import { useReportSortBrands } from '../../trpcHooks/useReportMutation';
import { ReportProps } from '../../types';
import { ServerReport, ServerReportBrand, ServerSearchApp, trpc } from '../../utils/trpc';
import { Button } from '../Button/Button';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { Gap } from '../Gap';
import { BrandLogo } from '../IconWithPlaceholder/IconWithPlaceholder';
import { Input } from '../Input/Input';
import { Padded } from '../PaddedScrollable/PaddedScrollable';
import { DeletePopup, Popup } from '../Popup2/Popup2';
import { Mail, TrashCan } from '../StrokeIcons';
import { Table } from '../Table/Table';
import styles from './draftReportSections.module.scss';

export const ReportBrandsSection: React.FC<ReportProps> = ({ report }) => {
  const [csvErrors, setCsvErrors] = React.useState<{ [brandId: string]: any }>();
  const [, { createSourceFromFile }] = useReportsContext();
  const reportSortBrandsMutation = useReportSortBrands();
  const [addingToBrandId, setAddingToBrandId] = React.useState<string>();

  const reportId = report._id.toHexString();
  const brandSources = React.useMemo(
    () => Array.from(new Set(report.apps?.map((a) => a.appProvider) || [])),
    [report],
  );

  const title = (
    <div>
      <div>
        <span>Add </span>
        <Tooltip
          className={styles.tooltipUnderline}
          tagName="span"
          content="We define this as a group of voice of customer data sources for the same product">
          brand or product name
        </Tooltip>
        <span> to prepare your analysis</span>
      </div>
    </div>
  );

  const _createSourceFromFile = async (
    brandId: string,
    title: string,
    file: File,
    appProvider: string,
  ) => {
    if (brandId) {
      setCsvErrors((errors) => omit(errors, brandId));
    }
    try {
      await createSourceFromFile(reportId, brandId, title, file, appProvider);
      setAddingToBrandId(undefined);
    } catch (error: any) {
      setCsvErrors((errors) => ({ ...errors, [brandId]: error }));
    }
  };

  return (
    <ContentSection>
      <ContentDivider
        end={
          <Button size="small" onClick={() => reportSortBrandsMutation.mutateAsync(reportId)}>
            sort alphabetically
          </Button>
        }>
        Add default concepts
      </ContentDivider>

      {report.brands?.map((brand) => {
        const id = brand._id.toHexString();
        const brandCsvErrors = csvErrors?.[id];

        return (
          <Brand
            key={id}
            brand={brand}
            addingToBrandId={addingToBrandId}
            setAddingToBrandId={setAddingToBrandId}
            report={report}
            brandSources={brandSources}
            createSourceFromFile={_createSourceFromFile}
          />
        );
      })}
      <BrandAdd reportId={reportId} />
    </ContentSection>
  );
};

interface BrandProps {
  report: ServerReport;
  brand: ServerReportBrand;
  createSourceFromFile: (brandId: string, title: string, file: File, appProvider: string) => void;
  brandSources?: string[];
  addingToBrandId?: string;
  setAddingToBrandId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface InviteButtonForm {
  imageUrl: string;
}

const Brand: React.FC<BrandProps> = ({
  brand,
  addingToBrandId,
  setAddingToBrandId,
  report,
  brandSources,
  createSourceFromFile,
}) => {
  const reportId = report._id.toHexString();
  const brandId = brand._id.toHexString();
  const brandName = brand.name;
  const deleteAppMutation = useDeleteAppMutation();
  const updateBrandMutation = useUpdateBrandMutation();
  const deleteBrandMutation = useDeleteBrandMutation();
  const [shouldDeleteBrand, setShouldDeleteBrand] = React.useState(false);
  const updateReportMutation = useReportBrandUpdateMutation();
  const [isPopup, setIsPopup] = React.useState(false);

  const { control, handleSubmit, getValues, resetField } = useForm<InviteButtonForm>({
    mode: 'all',
  });

  const appsForBrand = React.useMemo(() => {
    return report.apps?.filter((a) => a.brandId?.equals(brand._id));
  }, [report]);

  const deleteAppId = (metaId: string) => {
    deleteAppMutation.mutate({ reportId, metaId });
  };

  const providersForBrand = React.useMemo(() => {
    return appsForBrand?.map((app) => app.appProvider);
  }, [appsForBrand]);

  const sources = React.useMemo(() => {
    return brandSources?.filter((source) => {
      if (providersForBrand?.find((p) => p === source)) return false;

      return sourcesById?.[source]?.searchable;
    });
  }, [brandSources]);

  const onChangeLabel = (name: string) => {
    updateBrandMutation.mutate({ brandId, reportId, data: { name } });
  };
  const onDeleteBrand = () => {
    deleteBrandMutation.mutate({ reportId, brandId });
  };

  const onSubmit = async (data: InviteButtonForm) => {
    updateReportMutation.mutate({ reportId, data, brandId });

    setIsPopup(false);
    resetField('imageUrl');
  };

  const isAdding = addingToBrandId === brandId;
  return (
    <ContentSection size="small">
      <ContentDivider
        icon={
          <Tooltip
            className={styles.changeIconTooltip}
            onClick={() => setIsPopup(true)}
            tagName="span"
            content="Change Icon">
            <BrandLogo brand={brand} size={20} shape="circle" />
          </Tooltip>
        }
        end={
          <Button
            className={styles.deleteButton}
            size="small"
            type="dangerous"
            icon={<TrashCan />}
            onClick={() => setShouldDeleteBrand(true)}>
            Delete Brand
          </Button>
        }>
        {shouldDeleteBrand && (
          <DeletePopup onClose={() => setShouldDeleteBrand(false)} onDelete={onDeleteBrand} />
        )}
        <EditableLabel
          value={brandName}
          onChangeValue={onChangeLabel}
          deleteTooltip="remove this brand from report"
        />
      </ContentDivider>
      {isPopup && (
        <Popup onClose={() => setIsPopup(false)}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ContentSection className={styles.popupImg}>
              <Controller
                control={control}
                name="imageUrl"
                render={({ field }) => (
                  <Input size="small" icon={<Mail />} placeholder="imageUrl" {...field} />
                )}
              />
              <Button size="small" type="submit">
                Submit
              </Button>
            </ContentSection>
          </form>
        </Popup>
      )}
      <Padded size="small">
        <Table
          gridTemplateColumns={`max-content max-content 1fr max-content max-content`}
          bordered
          size="small">
          {appsForBrand?.map((app, index) => (
            <AppRow
              index={index}
              reportId={reportId}
              key={app.metaId.toHexString()}
              app={app}
              source={app.appProvider}
              className={styles.appRow}
              deleteApp={(id) => deleteAppId(id)}
            />
          ))}
        </Table>
        <Gap height={8} />
        {(sources?.length ?? 0) > 0 && (
          <>
            <ContentDivider>Suggested apps</ContentDivider>
            <Gap height={8} />
            <Table
              gridTemplateColumns={`max-content max-content 1fr max-content`}
              bordered
              size="small">
              {sources?.map((source, index) => {
                if (source === 'apple' || source === 'google') {
                  return (
                    <SuggestedApps
                      key={source}
                      provider={source}
                      brand={brand}
                      reportId={report._id.toHexString()}
                      index={index}
                    />
                  );
                }
              })}
            </Table>
          </>
        )}
        {!isAdding && (
          <div className={styles.addSourcesWrap}>
            <Button size="small" onClick={() => setAddingToBrandId(brandId)}>
              + Add source
            </Button>
          </div>
        )}
        {isAdding && (
          <DraftReportsSectionsAddingSource
            reportId={report._id.toHexString()}
            brand={brand}
            createSourceFromFile={createSourceFromFile}
            onClose={() => setAddingToBrandId(undefined)}
          />
        )}
      </Padded>
    </ContentSection>
  );
};

interface SuggestedAppsProps {
  reportId: string;
  provider: 'apple' | 'google';
  brand: ServerReportBrand;
  index?: number;
}
const SuggestedApps: React.FC<SuggestedAppsProps> = ({ reportId, provider, brand, index }) => {
  const addAppMutation = useAddAppMutation();
  const updateBrandMutation = useUpdateBrandMutation();

  const text = brand.name;
  const providerSearchQuery = trpc.providerSearch.search.useQuery(
    { text: text || '', provider },
    { enabled: Boolean(text) },
  );

  const onAddApp = async (app: ServerSearchApp) => {
    const data = {
      brandId: brand._id,
      appProvider: app.appProvider,
      appId: app.appId,
      title: app.title,
      iconUrl: app.iconUrl,
      locale: app.locale,
      description: app.description,
      score: app.score,
    };

    if (app.iconUrl && !brand.imageUrl) {
      updateBrandMutation.mutate({
        brandId: brand._id.toHexString(),
        reportId,
        data: { imageUrl: app.iconUrl },
      });
    }

    await addAppMutation.mutateAsync({ reportId, data });
  };

  return (
    <>
      {providerSearchQuery.isLoading && (
        <div className={styles.suggestLoading}>
          <WaveSpinner />
        </div>
      )}
      {providerSearchQuery.data?.slice(0, 5)?.map((app, i) => {
        return (
          <SearchAppRow
            index={i + (index ?? 0)}
            provider={provider}
            key={[app.appId, app.appProvider].join('.')}
            app={app}
            addApp={onAddApp}
            color="yellow"
          />
        );
      })}
    </>
  );
};

interface BrandAddProps {
  reportId: string;
}

const BrandAdd: React.FC<BrandAddProps> = ({ reportId }) => {
  const [brandSearch, setBrandSearch] = React.useState('');
  const addBrandMutation = useAddBrandMutation();

  const createBrand = async (name: string) => {
    await addBrandMutation.mutateAsync({ reportId, data: { name } });
    setBrandSearch('');
  };

  return (
    <div className={styles.addBrandContent}>
      <Search
        autoFocus
        placeholder="start typing here for brand suggestions"
        value={brandSearch || ''}
        className={styles.search}
        onChange={setBrandSearch}
        onSubmit={createBrand}
      />

      {(brandSearch.length ?? 0) > 0 && (
        <>
          <div className={styles.createBrand} onClick={() => createBrand(brandSearch)}>
            + Create brand "{brandSearch}"
          </div>
          <Table
            gridTemplateColumns="max-content max-content 1fr max-content"
            bordered
            size="small">
            {['apple', 'google'].map((source, index) => {
              if (source === 'apple' || source === 'google') {
                return (
                  <SuggestedAppsNoBrand
                    key={source}
                    provider={source}
                    reportId={reportId}
                    text={brandSearch}
                    onClear={() => setBrandSearch('')}
                    index={index}
                  />
                );
              }
            })}
          </Table>
        </>
      )}
    </div>
  );
};

interface SuggestedAppsNoBrandProps {
  reportId: string;
  provider: 'apple' | 'google';
  text?: string;
  onClear: () => void;
  index: number;
}

const SuggestedAppsNoBrand: React.FC<SuggestedAppsNoBrandProps> = ({
  reportId,
  provider,
  text,
  onClear,
  index,
}) => {
  const addAppMutation = useAddAppMutation();
  const providerSearchQuery = trpc.providerSearch.search.useQuery(
    { text: text || '', provider },
    { enabled: Boolean(text) },
  );

  const addBrandMutation = useAddBrandMutation();

  const onAddApp = async (app: ServerSearchApp) => {
    const name = app.title;

    const brandId = await addBrandMutation.mutateAsync({
      reportId,
      data: { name, imageUrl: app.iconUrl },
    });
    if (brandId) {
      const data = {
        brandId: brandId,
        appProvider: app.appProvider,
        appId: app.appId,
        title: app.title,
        iconUrl: app.iconUrl,
        locale: app.locale,
        score: app.score,
        description: app.description,
      };
      await addAppMutation.mutateAsync({ reportId, data });
      onClear();
    }
  };

  return (
    <>
      {providerSearchQuery.isLoading && (
        <div className={styles.suggestLoading}>
          <WaveSpinner />
        </div>
      )}
      {providerSearchQuery.data?.slice(0, 5)?.map((app, i) => {
        return (
          <SearchAppRow
            index={i + index}
            key={[app.appId, app.appProvider].join('.')}
            app={app}
            addApp={onAddApp}
            color="yellow"
            provider={provider}
          />
        );
      })}
    </>
  );
};
