import { keyBy } from 'lodash';
import React from 'react';
import { ReviewCard } from '../../PoseidonComponents/ReviewCard/ReviewCard';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import GoodSelect from '../../components/Select/Select';
import Card from '../../components/card/card';
import { ReviewPlaceholder } from '../../components/review/reviewPlaceholder';
import SentimentQuadrant from '../../components/sentimentQuadrant/sentimentQuadrant';
import { ReportCategory, ServerReport, SvrReportReview, trpc } from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import styles from './loversHatersCard.module.scss';
interface LoversHatersCardProps {
  report: ServerReport;
  concepts?: ReportCategory[];
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  conceptsById: Record<string, ReportCategory>;

  allReviews: SvrReportReview[];
  editing?: boolean;
  topicId?: string;
  setTopicId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const useSelectFilters = (report: ServerReport) => {
  return React.useMemo(() => {
    const hasGroups = Object.keys(report.brandGroups || {}).length > 0;
    if (hasGroups) {
      const brandById = keyBy(report.brands, (b) => b._id.toHexString());
      const brands = Object.keys(report.brandGroups || {}).map((key) => {
        const options = report.brandGroups?.[key]
          ?.map((id) => {
            if (brandById?.[id] == null) return undefined;
            const name = brandById?.[id]?.name;

            const src = brandById?.[id]?.imageUrl || undefined;
            return {
              label: name,
              value: [key, id].join('_'),
              src,
            };
          })
          .filter(isNotNull);
        return {
          label: key,
          value: key,
          options,
        };
      });

      if (brands.length > 1) {
        return [{ label: 'All', value: '', options: [{ label: 'All', value: '' }] }, ...brands];
      } else {
        return [];
      }
    } else {
      const brands =
        report.brands?.map((b) => ({
          label: b.name || '',
          value: '_' + b._id.toHexString(),
          src: b.imageUrl || undefined,
        })) || [];
      if (brands.length > 1) {
        return [{ label: 'All', value: '' }, ...brands];
      } else {
        return [];
      }
    }
  }, [report]);
};

export const LoversHatersCard: React.FC<LoversHatersCardProps> = ({
  report,
  concepts,
  conceptsById,
  allReviews,
  editing,
  topicId,
  setTopicId,
  filter,
  setFilter,
  onDelete,
}) => {
  const selectFiltes = useSelectFilters(report);

  const conceptsOptions = concepts?.map((concept) => ({
    value: concept._id.toHexString(),
    label: concept.name || concept._id.toHexString(),
  }));

  const clickTag = (id?: string) => {
    setTopicId?.(id);
  };

  const filters: JSX.Element[] = [];
  if (topicId) {
    filters.push(
      <GoodSelect
        options={conceptsOptions}
        value={topicId}
        key={topicId}
        onChange={(e) => clickTag?.(e?.value)}
      />,
    );
  }
  if (selectFiltes?.length > 1) {
    filters.push(
      <GoodSelect
        options={selectFiltes}
        value={filter}
        key={filter}
        onChange={(e) => e?.value ? setFilter?.(e.value) : undefined}
      />,
    );
  }

  if (allReviews == null) return <LoadingCard filters={filters} />;

  return (
    <LoversHatersCardWithReport
      report={report}
      conceptsById={conceptsById}
      editing={editing}
      onDelete={onDelete}
      allReviews={allReviews}
      filters={filters}
    />
  );
};

interface LoversHatersCardWithReportProps {
  report: ServerReport;
  conceptsById: Record<string, ReportCategory>;
  allReviews: any[];
  editing?: boolean;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filters: JSX.Element[];
}

const LoversHatersCardWithReport: React.FC<LoversHatersCardWithReportProps> = ({
  report,
  conceptsById,
  allReviews,
  editing,
  onDelete,
  filters,
}) => {
  const reportId = report._id.toHexString();
  const [hoveringReviewId, setHoveringReviewId] = React.useState<string>();
  const [clickedReviewId, setClickReviewId] = React.useState<string>();

  const hoveringReviewQuery = trpc.reportReviews.id.useQuery(
    { reportId, reviewId: hoveringReviewId || '' },
    { enabled: Boolean(hoveringReviewId) },
  );

  const clickedReviewQuery = trpc.reportReviews.id.useQuery(
    { reportId, reviewId: clickedReviewId || '' },
    { enabled: Boolean(clickedReviewId) },
  );

  return (
    <Card
      full
      header="Promoters and Detractors"
      description="Grid based review display. Up and to the right is 'better'"
      fileName={`${report?.name}_lovers_and_haters.png`}
      filters={filters}
      onDelete={onDelete}
      editing={editing}>
      <div className={styles.horizontal}>
        <SentimentQuadrant
          allReviews={allReviews}
          clickReview={setClickReviewId}
          hoverReview={setHoveringReviewId}
          selected={clickedReviewId}
        />
        <div className={styles.review}>
          {hoveringReviewId && (
            <>
              {!hoveringReviewQuery.isLoading && hoveringReviewQuery.data?.review && (
                <ReviewCard
                  report={report}
                  review={hoveringReviewQuery.data.review}
                  conceptsById={conceptsById}
                  shownButtons={new Set(['copy', 'download'])}
                />
              )}
              {hoveringReviewQuery.isLoading && <WaveSpinner className={styles.loading} />}
            </>
          )}

          {!hoveringReviewId && clickedReviewId && (
            <>
              {!clickedReviewQuery.isLoading && clickedReviewQuery.data?.review && (
                <ReviewCard
                  report={report}
                  review={clickedReviewQuery.data.review}
                  conceptsById={conceptsById}
                  shownButtons={new Set(['copy', 'download'])}
                />
              )}
              {clickedReviewQuery.isLoading && <WaveSpinner className={styles.loading} />}
            </>
          )}

          {!clickedReviewId && !hoveringReviewId && (
            <div className={styles.clickCorrespondingVerbatimsWrap}>
              <ReviewPlaceholder opacity={1} noWrap />
              <div className={styles.clickCorrespondingVerbatims}>
                Select point to see the corresponding verbatim
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

interface LoadingCardProps {
  filters: JSX.Element[];
}
const LoadingCard: React.FC<LoadingCardProps> = ({ filters }) => {
  return (
    <Card
      full
      header="Promoters and Detractors"
      description="Hover over points on the grid below to see the verbatim feedback.  To learn more about how feedback is mapped, read our documentation. Up and to the right is 'better'"
      filters={filters}>
      <WaveSpinner />
    </Card>
  );
};

export default LoversHatersCard;
