import React from 'react';
import { Input } from 'src/PoseidonComponents/Input/Input';
import { useDebouncedCallback } from 'use-debounce';
import { ReportProps } from '../../types';
import { trpc } from '../../utils/trpc';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';
import { Button } from '../Button/Button';
import styles from './ReportNameSection.module.scss';

export const ReportNameSection: React.FC<ReportProps> = ({ report }) => {
  const [name, setName] = React.useState(report.name || '');
  const updateReportMutation = trpc.report.update.useMutation();

  const debounced = useDebouncedCallback(
    (id: string, data) => updateReportMutation.mutateAsync({ id, data }),
    600,
  );

  const reportId = report._id.toHexString();

  const updateWorkspaceName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setName(name);
    debounced(reportId, { name });
  };

  const handleClickUpdate = () => {
    updateReportMutation.mutateAsync({ id: reportId, data: { name } });
  };

  return (
    <ContentSection>
      <ContentDivider>Workspace Title</ContentDivider>
      <Input
        placeholder="Workspace Name"
        value={name}
        onChange={updateWorkspaceName}
        name="name"
        autoFocus
      />
      <div className={styles.buttons}>
        <Button size="small" onClick={handleClickUpdate}>
          Update
        </Button>
      </div>
    </ContentSection>
  );
};
