import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { TogglePill } from '../components/pills/pills';
import { useReportUpdateMutation } from '../trpcHooks/useReportMutation';
import { ServerReport } from '../utils/trpc';
import { BrandIcons } from './BrandIcons/BrandIcons';
import { Cell, CellHeader, CellLoading, Table } from './Table/Table';

interface WorkspacesTableProps {
  workspaces?: ServerReport[];
  isLoading?: boolean;
}

export const WorkspacesTable: React.FC<WorkspacesTableProps> = ({ workspaces, isLoading }) => {
  return (
    <>
      <Table gridTemplateColumns="1fr max-content max-content max-content" bordered>
        <CellHeader>Name</CellHeader>
        <CellHeader>Originated</CellHeader>
        <CellHeader align="right">Brands</CellHeader>
        <CellHeader />
        {isLoading && <CellLoading index={1}>Loading Workspaces</CellLoading>}
        {workspaces?.map((workspace, index) => (
          <WorkspaceRow workspace={workspace} index={index} key={workspace._id.toHexString()} />
        ))}
      </Table>
    </>
  );
};

interface WorkspaceRowProps extends React.HTMLProps<HTMLDivElement> {
  workspace: ServerReport;
  index: number;
}
const WorkspaceRow: React.FC<WorkspaceRowProps> = ({ workspace, index }) => {
  const date = workspace.createdOn && DateTime.fromJSDate(workspace.createdOn);
  const updateMutation = useReportUpdateMutation();
  const workspaceId = workspace._id.toHexString();
  const isDraft = workspace.status === 'draft';
  const isReportActive = workspace.active === true;
  const link = isDraft ? `/drafts/${workspaceId}` : `/reports/${workspaceId}`;

  return (
    <>
      <Cell index={index}>
        <Link to={link}>{workspace.name || '(No Name)'}</Link>
      </Cell>
      <Cell index={index}>{date?.toLocaleString(DateTime.DATETIME_MED)}</Cell>
      <Cell index={index} align="right">
        {workspace.brands && <BrandIcons max={10} brands={workspace.brands} />}
      </Cell>
      <Cell index={index}>
        <TogglePill
          isOn={isReportActive}
          onText="active"
          onHoverText="deactivate"
          offText="inactive"
          offHoverText="activate"
          onClick={() =>
            updateMutation.mutate({ id: workspaceId, data: { active: !isReportActive } })
          }
        />
      </Cell>
    </>
  );
};
