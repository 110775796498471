import clsx from 'clsx';
import React from 'react';
import { IntervalType } from '../../models/aggModels/intervalBucket.entity';
import { trpc } from '../../utils/trpc';
import { getRangeColor2 } from '../../utils2';
import styles from './versionHistory.module.scss';

const getWeekNumber = (d: Date) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
};

interface VersionHistoryProps {
  reportId: string;
  brandId: string;
  intervalType?: IntervalType;
  categoryId?: string;
}

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const VersionHistory: React.FC<VersionHistoryProps> = ({
  brandId,
  reportId,
  intervalType,
  categoryId,
}) => {
  const NUM = 50;

  const historyBrand = trpc.reportBrand.history.useQuery(
    { reportId, brandId, intervalType: intervalType || 'month' },
    { enabled: Boolean(intervalType) },
  );

  const categoryIdDefined = categoryId != null ? categoryId : '_review';

  return (
    <div className={styles.container}>
      <div className={styles.container2}>
        {historyBrand.data != null && (
          <div className={styles.header}>Each rectangle is at most {NUM} reviews</div>
        )}
        {historyBrand.data?.map((d, key) => {
          const dateValue = d.overall?.interval?.date;
          if (dateValue == null) {
            console.error(`history record is missing a date value`);
            return null;
          }
          const date = new Date(dateValue.toMillis());

          const categoryObj = d.cats?.[categoryIdDefined];
          const countsObj =
            categoryIdDefined != '_review' ? categoryObj?.catScore : categoryObj?.sentiment;

          const catTotalCounts = countsObj?.count || 0;
          const catBktdValues: any[] = countsObj?.bktd || [];

          const monthNumber = date.getUTCMonth();
          const monthString = MONTH_NAMES[monthNumber];
          const yearString = date.getUTCFullYear();
          const weekNumber = getWeekNumber(date);
          const quarter = Math.floor(monthNumber / 3);

          let label = '';
          let showSpacer = false;
          if (intervalType === 'year') {
            label = `${yearString}`;
          } else if (intervalType === 'quarter') {
            label = `Q${quarter + 1} ${yearString}`;
            if (quarter === 3) {
              showSpacer = true;
            }
          } else if (intervalType === 'month') {
            label = `${monthString} ${yearString}`;
            if (monthNumber === 11) {
              showSpacer = true;
            }
          } else if (intervalType === 'week') {
            label = `week ${weekNumber}, ${yearString}`;
            if (weekNumber === 52) {
              showSpacer = true;
            }
          }

          return (
            <React.Fragment key={key}>
              {showSpacer && <div className={styles.yearSpacer} />}
              <div className={clsx(styles.row, styles[`quarter${quarter}`])}>
                <div className={styles.label}>{label}</div>
                <div className={styles.scoreRow}>
                  {catBktdValues?.map((value, i) => {
                    if (value === 0) return null;
                    const width =
                      catTotalCounts <= 0 || value == 0 ? 0 : (100 * value) / catTotalCounts;
                    return (
                      <React.Fragment key={i}>
                        <div style={{ width: `calc(${width}% - 1px)` }} className={styles.bucket}>
                          {Array.from({ length: Math.ceil(value / NUM) }, (_, j) => {
                            return (
                              <div key={j} style={{ background: getRangeColor2((i + 1) * 20) }} />
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className={styles.labelRight}>{catTotalCounts}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default VersionHistory;

interface VersionAppHistoryProps {
  reportId: string;
  metaId: string;
  intervalType?: IntervalType;
  categoryId?: string;
}

export const VersionAppHistory: React.FC<VersionAppHistoryProps> = ({
  intervalType,
  metaId,
  reportId,
  categoryId,
}) => {
  const NUM = 50;

  const historyApp = trpc.reportApp.history.useQuery(
    { reportId, metaId, intervalType: intervalType || 'month' },
    { enabled: Boolean(intervalType) },
  );

  const categoryIdDefined = categoryId != null ? categoryId : '_review';

  return (
    <div className={styles.container}>
      <div className={styles.container2}>
        {historyApp.data != null && (
          <div className={styles.header}>Each rectangle is at most {NUM} reviews</div>
        )}
        {historyApp.data?.map((d, key) => {
          const dateValue = d.overall?.interval?.date;
          if (dateValue == null) {
            console.error(`history record is missing a date value`);
            return null;
          }
          const date = new Date(dateValue.toMillis());

          const categoryObj = d.cats?.[categoryIdDefined];
          const countsObj =
            categoryIdDefined != '_review' ? categoryObj?.catScore : categoryObj?.sentiment;

          const catTotalCounts = countsObj?.count || 0;
          const catBktdValues: any[] = countsObj?.bktd || [];

          const monthNumber = date.getUTCMonth();
          const monthString = MONTH_NAMES[monthNumber];
          const yearString = date.getUTCFullYear();
          const weekNumber = getWeekNumber(date);
          const quarter = Math.floor(monthNumber / 3);

          let label = '';
          let showSpacer = false;
          if (intervalType === 'year') {
            label = `${yearString}`;
          } else if (intervalType === 'quarter') {
            label = `Q${quarter + 1} ${yearString}`;
            if (quarter === 3) {
              showSpacer = true;
            }
          } else if (intervalType === 'month') {
            label = `${monthString} ${yearString}`;
            if (monthNumber === 11) {
              showSpacer = true;
            }
          } else if (intervalType === 'week') {
            label = `week ${weekNumber}, ${yearString}`;
            if (weekNumber === 52) {
              showSpacer = true;
            }
          }

          return (
            <React.Fragment key={key}>
              {showSpacer && <div className={styles.yearSpacer} />}
              <div className={clsx(styles.row, styles[`quarter${quarter}`])}>
                <div className={styles.label}>{label}</div>
                <div className={styles.scoreRow}>
                  {catBktdValues?.map((value, i) => {
                    if (value === 0) return null;
                    const width =
                      catTotalCounts <= 0 || value == 0 ? 0 : (100 * value) / catTotalCounts;
                    return (
                      <React.Fragment key={i}>
                        <div style={{ width: `calc(${width}% - 1px)` }} className={styles.bucket}>
                          {Array.from({ length: Math.ceil(value / NUM) }, (_, j) => {
                            return (
                              <div key={j} style={{ background: getRangeColor2((i + 1) * 20) }} />
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className={styles.labelRight}>{catTotalCounts}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
