import React from 'react';
import { Button } from '../../PoseidonComponents/Button/Button';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { PaddedScrollable } from '../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { ReviewCard } from '../../PoseidonComponents/ReviewCard/ReviewCard';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import { useTextSelection } from '../../hooks/use-text-selection';
import useOnScreen from '../../hooks/useOnScreen';
import { ReportCategory, SvrReportReview, ReviewComment, ServerReport } from '../../utils/trpc';
import styles from './reviews.module.scss';
import useRouterQuery from '../../hooks/useRouterQuery';

interface ReviewsProps {
  report: ServerReport;
  reviews?: {
    reviews: SvrReportReview[] | undefined;
    commentsByReviewId: Record<string, ReviewComment[]>;
    nextCursor: number | undefined;
  }[];
  conceptsById?: Record<string, ReportCategory>;
  fetchNextPage?: () => void;
  fetchPreviousPage?: () => void;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
}

interface ScrollOffset {
  scrollTop?: number;
  scrollLeft?: number;
  offsetTop?: number;
  offsetLeft?: number;
}

export const Reviews: React.FC<ReviewsProps> = ({
  report,
  reviews,
  conceptsById,
  fetchNextPage,
  fetchPreviousPage,
  isFetchingNextPage,
  isFetchingPreviousPage,
}) => {
  const [query, setQuery] = useRouterQuery();
  const [scrollOffset, setScrollOffset] = React.useState<ScrollOffset>({});
  const ref = React.useRef<HTMLDivElement>(null);
  const { clientRect, startOffset, endOffset, selectReviewId, reset } = useTextSelection();
  const nextButtonRef = React.useRef<HTMLDivElement>(null);
  const nextButtonOnScreen = useOnScreen<HTMLDivElement>(nextButtonRef);

  React.useEffect(() => {
    if (nextButtonOnScreen && fetchNextPage) {
      fetchNextPage();
    }
  }, [nextButtonOnScreen, fetchNextPage]);

  const hasNoReviews =
    fetchNextPage == null &&
    fetchPreviousPage == null &&
    (reviews?.[0]?.reviews?.length ?? 0) === 0;

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (e.target instanceof HTMLElement) {
      const scrollTop = e.target.scrollTop;
      const scrollLeft = e.target.scrollLeft;
      window.requestAnimationFrame(() => {
        setScrollOffset((s) => ({ ...s, scrollTop, scrollLeft }));
      });
    }
  };

  React.useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      setScrollOffset((s) => ({ ...s, offsetTop: rect.y, offsetLeft: rect.x }));
    }
  }, []);

  const onTrain = (rid = query.machine?.rid, start?: number, end?: number) => {
    setQuery((q) => ({
      ...q,
      machine: rid ? { rid, start, end } : undefined,
    }));
    reset();
  };

  return (
    <>
      <PaddedScrollable onScroll={onScroll}>
        <ContentSection ref={ref}>
          {fetchPreviousPage && (
            <>
              <ContentDivider align="center">
                {!isFetchingPreviousPage && (
                  <Button size="small" onClick={fetchPreviousPage}>
                    Show Previous Reviews
                  </Button>
                )}
                {isFetchingPreviousPage && (
                  <Button size="small" icon={<WaveSpinner />}>
                    Loading Reviews...
                  </Button>
                )}
              </ContentDivider>
            </>
          )}
          {reviews?.map(({ reviews, commentsByReviewId }, index) => {
            return (
              <React.Fragment key={index}>
                {reviews?.map((review) => {
                  return (
                    <div key={review._id.toHexString()} className={styles.container}>
                      <ReviewCard
                        report={report}
                        review={review}
                        comments={commentsByReviewId?.[review._id.toHexString()]}
                        conceptsById={conceptsById || {}}
                        hideAppLogo
                        showComments
                      />
                      {query.machine && (
                        <>
                          {query.machine?.rid !== review._id.toHexString() && (
                            <Button
                              className={styles.editButton}
                              onClick={() => onTrain(review._id.toHexString())}>
                              Edit
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
          {hasNoReviews && <div>0 reviews</div>}
          {fetchNextPage && (
            <ContentDivider align="center" ref={nextButtonRef}>
              {!isFetchingNextPage && (
                <Button size="small" onClick={fetchNextPage}>
                  Show More Reviews
                </Button>
              )}
              {isFetchingNextPage && (
                <Button size="small" icon={<WaveSpinner />}>
                  Loading more Reviews...
                </Button>
              )}
            </ContentDivider>
          )}
        </ContentSection>
      </PaddedScrollable>

      {clientRect && (
        <div
          className={styles.tooltip}
          onClick={() => onTrain(selectReviewId, startOffset, endOffset)}
          style={{
            left: clientRect.x - (scrollOffset.offsetLeft ?? 0),
            top: clientRect.y - clientRect.height - (scrollOffset.offsetTop ?? 0) - 6,
          }}>
          Train
        </div>
      )}
    </>
  );
};

export default Reviews;
