import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useReportUpdateMutation } from '../trpcHooks/useReportMutation';
import { ReportProps } from '../types';
import { isStringNotEmpty } from '../utils2';
import { ContentDivider } from './ContentDivider/ContentDivider';
import { ContentSection } from './ContentSection/ContentSection';
import { Input } from './Input/Input';

export const ReportFlourishSection: React.FC<ReportProps> = ({ report }) => {
  const [input, setInput] = React.useState((report.flourishIds || []).join(', '));
  const updateMutation = useReportUpdateMutation();
  const debounced = useDebouncedCallback((data) => updateMutation.mutate(data), 600);

  const updateReportFlourishIds = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const flourishIds = value
      .split(',')
      .map((a) => a.trim())
      .filter(isStringNotEmpty);
    setInput(value);
    debounced({
      id: report._id.toHexString(),
      data: { flourishIds },
    });
  };

  return (
    <ContentSection>
      <ContentDivider>Create Flourish Ids</ContentDivider>
      <Input
        placeholder="Flourish Ids"
        value={input}
        onChange={updateReportFlourishIds}
        name="name"
      />
    </ContentSection>
  );
};
