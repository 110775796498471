import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import { Gear } from 'src/PoseidonComponents/StrokeIcons';
import { ServerTeam, ServerUser, trpc } from '../../utils/trpc';
import { FullHeight } from '../../PoseidonComponents/FullHeight/FullHeight';
import { WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { ReportsLeftNav } from '../../components/LeftNav/LeftNav';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { LinkButton } from '../../PoseidonComponents/Button/Button';
import { BreadCrumb, Breadcrumbs } from '../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { PaddedScrollable } from '../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { TeamsTable } from '../../PoseidonComponents/TeamsTable/TeamsTable';

interface Props {
  user: ServerUser;
  team: ServerTeam | undefined;
}
const TeamsContainer: React.FC<Props> = ({ user, team }) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const teamsQuery = trpc.team.all.useQuery();

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Gear />} title="Settings">
          <ContentDivider
            end={
              <LinkButton size="small" to="/teams/create">
                Create team
              </LinkButton>
            }>
            <Breadcrumbs showStartingChevron>
              <BreadCrumb>Teams</BreadCrumb>
            </Breadcrumbs>
          </ContentDivider>
        </PageHeader>
        <PaddedScrollable>
          <ContentSection>
            <TeamsTable
              teams={teamsQuery.data}
              user={user}
              team={team}
              userById={undefined}
              isLoading={teamsQuery.isLoading}
            />
          </ContentSection>
        </PaddedScrollable>
      </WithLeftNav>
    </FullHeight>
  );
};

export default TeamsContainer;
