import { trpc } from '../utils/trpc';

export const useReviewCommentDeleteMutation = () => {
  const utils = trpc.useContext();

  return trpc.reviewComment.delete.useMutation({
    onSuccess: () => {
      utils.reportReviews.forBrandOld.invalidate();
    },
  });
};

export const useReviewCommentAddMutation = () => {
  const utils = trpc.useContext();
  return trpc.reviewComment.add.useMutation({
    onSuccess: (_) => {
      utils.reportReviews.forBrandOld.invalidate();
    },
  });
};
