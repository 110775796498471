import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import React from 'react';
import { ReportCategory, ReportInsightSummaryReview, ServerReport } from 'src/utils/trpc';

import { ReviewCard } from 'src/PoseidonComponents/ReviewCard/ReviewCard';
import { DraggerHandle, TrashCan } from 'src/PoseidonComponents/StrokeIcons';
import { SvgIconButton } from 'src/PoseidonComponents/SvgIconButton/SvgIconButton';
import { useRemoveReview } from 'src/trpcHooks/useReportInsightSummary';
import styles from './KeyTakeaways.module.scss';

interface Props {
  id: string;
  className?: string;
  sectionId: string;
  report: ServerReport;
  review: ReportInsightSummaryReview;
  conceptsById: Record<string, ReportCategory>;
}

export const Draggable: React.FC<Props> = ({
  id,
  report,
  review,
  sectionId,
  className,
  conceptsById,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
  });
  const removeReviewMutation = useRemoveReview();

  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className={clsx(styles.draggableReview)}>
      <div className={styles.buttons}>
        <SvgIconButton icon={<DraggerHandle />} {...attributes} {...listeners} />
        <SvgIconButton
          icon={<TrashCan />}
          onClick={() => removeReviewMutation.mutate({ reviewId: id, id: sectionId })}
          type="dangerous"
        />
      </div>

      <ReviewCard
        report={report}
        review={review}
        key={review._id.toHexString()}
        conceptsById={conceptsById}
        shownButtons={new Set(['info', 'copy', 'download'])}
        showResponse={false}
      />
    </div>
  );
};
