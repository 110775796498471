import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { Popup2 } from '../../libs/popup/popup';
import { PlusIcon } from '../icons/icons';
import styles from './icon.module.scss';

const isString = (test: any) => typeof test === 'string' || test instanceof String;

interface IconProps extends React.HTMLProps<HTMLDivElement> {
  src?: any;
  tagName?: any;
  grayscale?: boolean;
  rounded?: boolean;
  onUpload?: any;
  fakeText?: string;
  background?: string;
}

export const Icon: React.FC<IconProps> = ({
  src,
  style,
  tagName,
  className,
  grayscale,
  rounded,
  onUpload,
  fakeText,
  background,
  ...props
}) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const onSubmit = (value: any) => {
    if (onUpload) onUpload(value);
    setShowPopup(false);
  };

  const Tag = tagName || 'div';

  const onClick = src == null && onUpload != null ? () => setShowPopup(true) : undefined;
  const onDoubleClick = src != null && onUpload != null ? () => setShowPopup(true) : undefined;

  const onClickPopup = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPopup(false);
  };

  const classList = clsx(
    className,
    grayscale ? styles.grayscale : null,
    rounded ? styles.rounded : null,
    styles.icon,
  );
  const _style = { ...style, '--background': background } as CSSProperties;

  return (
    <Tag
      className={classList}
      style={_style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      {...props}>
      {src != null && isString(src) && (
        <div style={{ backgroundImage: `url("${src}")` }} className={styles.img} />
      )}
      {src != null && !isString(src) && src !== false && React.createElement(src)}
      {src == null && onUpload != null && <PlusIcon />}
      {src == null && onUpload == null && fakeText}

      {showPopup && (
        <Popup2 onClose={onClickPopup}>
          <PopupContent onSubmit={onSubmit} />
        </Popup2>
      )}
    </Tag>
  );
};

interface CircleIconProps extends React.HTMLProps<HTMLDivElement> {
  src?: string;
  grayscale?: boolean;
}
interface PopupContentProps {
  onSubmit: any;
}
const PopupContent: React.FC<PopupContentProps> = ({ onSubmit }) => {
  const [disabled, setDisabled] = React.useState(true);
  const [value, setValue] = React.useState('');
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (disabled && value.length > 0) setDisabled(false);
    else if (!disabled && value.length === 0) setDisabled(true);

    setValue(value);
  };

  const _onSubmit = (e: any) => {
    if (e) e.preventDefault();
    if (!disabled) onSubmit?.(value);
  };

  return (
    <form className={styles.content} onSubmit={_onSubmit}>
      <div className={styles.header}>Add an icon</div>

      <input
        placeholder="image url"
        className={styles.input}
        onChange={changeInput}
        value={value}
        ref={ref}
      />
      <button className={clsx(styles.deleteButton, disabled ? styles.disabled : null)}>Add</button>
    </form>
  );
};

export default Icon;
