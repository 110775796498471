import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/PoseidonComponents/Button/Button';
import DeletePopup from '../components/deletePopup/deletePopup';
import EditTeamsPopup from '../components/editTeamsPopup/editTeamsPopup';
import { useReportsContext } from '../contexts';
import { useReportDeleteMutation } from '../trpcHooks/useReportMutation';
import { ReportProps } from '../types';
import { People, PlusCircle, Rocket, TrashCan } from './StrokeIcons';

export const ReportAdminSection: React.FC<ReportProps> = ({ report, user }) => {
  const navigate = useNavigate();
  const reportDeleteMutation = useReportDeleteMutation();
  const [showUsersPopup, setShowUsersPopup] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const [, { processReport, analyzeReport }] = useReportsContext();

  const reportId = report._id.toHexString();

  const closeDeletePopup = async (shouldDelete?: boolean) => {
    if (shouldDelete) {
      await reportDeleteMutation.mutateAsync(reportId);
      setShowDeletePopup(false);
      navigate('/reports');
    } else {
      setShowDeletePopup(false);
    }
  };

  const onCloseEditTeam = () => {
    setShowUsersPopup(false);
  };

  return (
    <>
      <Button icon={<PlusCircle />} size="small" onClick={() => processReport(reportId)}>
        Scan Sources
      </Button>
      <Button icon={<Rocket />} size="small" onClick={() => () => analyzeReport(reportId)}>
        Analyze
      </Button>
      <Button icon={<TrashCan />} size="small" onClick={() => setShowDeletePopup(true)}>
        Delete Workspace
      </Button>

      {showDeletePopup && (
        <DeletePopup
          onClose={() => closeDeletePopup()}
          onSubmit={() => closeDeletePopup(showDeletePopup)}
        />
      )}
      {showUsersPopup && <EditTeamsPopup onClose={onCloseEditTeam} report={report} user={user} />}
    </>
  );
};
