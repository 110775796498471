import useLocalStorage from '@rehooks/local-storage';
import { keyBy } from 'lodash';
import React from 'react';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { Flex, WithLeftNav } from '../../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../../PoseidonComponents/PageHeader/PageHeader';
import { Atom } from '../../../PoseidonComponents/StrokeIcons';
import { WaveSpinner } from '../../../PoseidonComponents/WaveSpinner';
import DatePicker from '../../../components/datePicker/datePicker';
import { useReviewsContext } from '../../../contexts';
import { useReportLogEffect } from '../../../libs/react-amplitude';
import PolarCard from '../../../marksyComponents/PolarCard2/PolarCard2';
import ConceptSummaryCard from '../../../marksyComponents/conceptSummaryCard/conceptSummaryCard';
import HeatmapCard from '../../../marksyComponents/heatmapCard/heatmapCard';
import LoversHatersCard from '../../../marksyComponents/loversHatersCard/loversHatersCard';
import { DateTypeValue } from '../../../models/dateTypeValue.model';
import { ReportCategory, ServerDataCounts, ServerReport } from '../../../utils/trpc';
import { getSortedBrands2, isNotNull } from '../../../utils2';

interface ReportExecSummaryProps {
  report?: ServerReport;
  reportConcepts: ReportCategory[];
  dataCounts?: ServerDataCounts;
}
export const ReportExecSummary: React.FC<ReportExecSummaryProps> = ({
  report,
  reportConcepts,
  dataCounts,
}) => {
  useReportLogEffect({ eventType: 'viewInfo', report }, [report]);
  if (report == null) return <WaveSpinner />;

  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<Atom />} title="Insights">
        <ContentDivider end={<DatePicker groupBy="quarter" />} />
      </PageHeader>
      <DefinedReportExecSummary
        report={report}
        reportConcepts={reportConcepts}
        dataCounts={dataCounts}
      />
    </WithLeftNav>
  );
};

interface DefinedReportExecSummaryProps {
  report: ServerReport;
  reportConcepts: ReportCategory[];
  dataCounts?: ServerDataCounts;
}
const DefinedReportExecSummary: React.FC<DefinedReportExecSummaryProps> = ({
  report,
  reportConcepts,
  dataCounts,
}) => {
  const [dateTypeValue] = useLocalStorage<DateTypeValue>('dateTypeValue', {
    type: undefined,
    value: undefined,
  });
  const [topicId, setTopicId] = React.useState<string>();
  const [{ reviewsByTags }, { fetchTaggedReviews }] = useReviewsContext();
  const reportId = report._id.toHexString();
  const reportBrandIds = report.brands?.map((b) => b._id.toHexString());
  const brandGroups = report.brandGroups;
  const reportApps = report.apps;
  const dateType = dateTypeValue.type;
  const dateValue = dateTypeValue.value;

  // const reviewsQuery = trpc.reportReviews.forTopic.useQuery({ reportId, categoryId: topicId, intervalAndDate });

  React.useEffect(() => {
    setTopicId(reportConcepts[0]?._id.toHexString());
  }, [reportConcepts]);

  React.useEffect(() => {
    if (topicId) {
      fetchTaggedReviews(reportId, topicId, { intervalType: dateType, date: dateValue });
    }
  }, [reportId, reportApps, topicId, dateType, dateValue, fetchTaggedReviews]);

  const [filter, setFilter] = React.useState(
    reportBrandIds?.length === 2 ? `_${reportBrandIds[0]}` : '',
  );

  const conceptsById: any = React.useMemo(
    () => keyBy(reportConcepts, (r) => r._id),
    [reportConcepts],
  );

  const reviewsFromTag = reviewsByTags?.[`${reportId}_${topicId}`];

  const sortedBrands = React.useMemo(() => {
    return getSortedBrands2(report.brands, dataCounts, 'score', 'asc');
  }, [report, dataCounts]);

  const filteredBrandIds = React.useMemo(() => {
    if (filter.length > 0) {
      const filterArray = filter.split('_');
      if (filterArray.length === 2) {
        return [filterArray[1]].filter(isNotNull);
      } else if (filterArray.length === 1) {
        const id = filterArray[0];
        // return id ? brandGroups?.[id] : undefined;
      }
      return [];
    }
    return undefined;
  }, [filter, brandGroups]);

  const allReviews = React.useMemo(() => {
    if (reviewsFromTag && filteredBrandIds != null) {
      return reviewsFromTag.filter((r: any) => filteredBrandIds.indexOf(r?.brandId) >= 0);
    } else {
      return reviewsFromTag;
    }
  }, [reviewsFromTag, filteredBrandIds]);

  return (
    <PaddedScrollable size='small'>
      <Flex gap="16px">
        <PolarCard
          report={report}
          concepts={reportConcepts}
          sortedBrands={sortedBrands}
          dataCounts={dataCounts}
        />
        <ConceptSummaryCard
          report={report}
          concepts={reportConcepts}
          filteredBrandIds={filteredBrandIds}
          filter={filter}
          setFilter={setFilter}
          dataCounts={dataCounts}
        />
        <HeatmapCard
          report={report}
          concepts={reportConcepts}
          filter={filter}
          setFilter={setFilter}
          filteredBrandIds={filteredBrandIds}
          dataCounts={dataCounts}
        />
        <LoversHatersCard
          report={report}
          concepts={reportConcepts}
          filter={filter}
          setFilter={setFilter}
          topicId={topicId}
          setTopicId={setTopicId}
          allReviews={allReviews}
          conceptsById={conceptsById}
        />
      </Flex>
    </PaddedScrollable>
  );
};

export default ReportExecSummary;
