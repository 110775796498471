import { TextareaAutosize } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { useUpdate } from '../../../trpcHooks/useReportInsightSummary';
import styles from './FormSection.module.scss';

interface Props {
  title: string;
  description?: string;
  id: string;
}

interface FormProps {
  title: string;
  description?: string;
}

export const FormSection: React.FC<Props> = ({ id, title, description }) => {
  const { formState, control, reset, handleSubmit } = useForm<FormProps>({
    mode: 'all',
    defaultValues: { title, description },
  });
  const updateMutation = useUpdate();

  const debounced = useDebouncedCallback((name: string, value: string) => {
    updateMutation.mutate({ id, data: { [name]: value } });
  }, 400);

  React.useEffect(() => {
    reset({ description });
  }, [description]);

  const onSubmit = (data: FormProps) => {
    updateMutation.mutate({ id, data });
  };
  return (
    <form className={styles.inputs} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="title"
        render={({ field }) => (
          <H2Input
            placeholder="title"
            error={formState.errors.title?.message}
            {...field}
            onChange={(event) => {
              field.onChange(event);
              debounced(field.name, event.target.value);
            }}
            type="text"
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <TextareaAutosize
            style={{ resize: 'none' }}
            className={styles.input}
            placeholder="description"
            {...field}
            onChange={(event) => {
              field.onChange(event);
              debounced(field.name, event.target.value);
            }}
          />
        )}
      />
    </form>
  );
};

interface H2InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const H2Input = React.forwardRef<HTMLInputElement, H2InputProps>(({ className, ...props }, ref) => {
  return (
    <div className={clsx(className, styles.headerInput)}>
      <label className={styles.label}>
        <input className={styles.input} ref={ref} {...props} />
      </label>
    </div>
  );
});
