import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import useForm from '../../hooks/useForm';
import { conceptsRQKey, RQUtils } from '../../utils/reactQuery.utils';
import { AspectType, Concept, ConceptUpdateParams, trpc } from '../../utils/trpc';
import { DeletablePill } from '../pills/pills';
import Popup from '../popup/popup';
import styles from './editConceptPopup.module.scss';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface EditConceptPopupProps {
  concept: Concept | null;
  onClose: () => void;
}
export const EditConceptPopup: React.FC<EditConceptPopupProps> = ({ concept, onClose }) => {
  if (concept == null) return null;
  return <EditConceptWithDataPopup concept={concept} onClose={onClose} />;
};

interface EditConceptWithDataPopupProps {
  concept: Concept;
  onClose?: () => void;
}
export const EditConceptWithDataPopup: React.FC<EditConceptWithDataPopupProps> = ({
  concept,
  onClose,
}) => {
  const queryClient = useQueryClient();

  const updatedConceptMut = trpc.concepts.update.useMutation({
    onSuccess: (updatedConcept) => {
      queryClient.setQueryData<Concept[]>(conceptsRQKey(), (old) => {
        return RQUtils.updateArrQueryV2(old, updatedConcept, '_id');
      });

      onClose?.();
    },
  });
  const updatedConcept = (concept: Concept, updatedValues: ConceptUpdateParams) => {
    if (updatedConceptMut.isLoading == true) {
      return;
    }
    updatedConceptMut.mutate({
      conceptId: concept._id.toHexString(),
      updateValues: updatedValues,
    });
  };

  const { values, handleChange, registerSubmit } = useForm({ name: concept.name });
  const isJudgement = concept.catType === 'judgement';

  const onSubmit = async ({ values }: any) => {
    const name = values.name as string | undefined;
    updatedConcept(concept, { name });
  };

  const conceptIdStr = concept._id.toHexString();

  return (
    <Popup onClose={() => onClose?.()}>
      <h4>Edit concept</h4>

      <form onSubmit={registerSubmit(onSubmit)}>
        <Input value={values.name} name="name" onChange={handleChange} />
      </form>

      {!isJudgement && (
        <>
          <div className={styles.words}>
            {concept.words?.map((w, i) => (
              <DeletableConceptWordPill
                word={w}
                key={w + i}
                categoryId={conceptIdStr}
                aspect={null}
              />
            ))}
          </div>
          <AddWordInput concept={concept} words={concept.words || []} aspect={null} />
        </>
      )}

      {isJudgement && (
        <>
          <div className={styles.wordsTitle}>{concept.positive?.name || 'POSITIVE WORDS'}</div>
          <div className={styles.words}>
            {concept.positive?.words?.map((w, i) => (
              <DeletableConceptWordPill
                categoryId={conceptIdStr}
                word={w}
                key={w + i}
                aspect={'positive'}
              />
            ))}
          </div>

          <AddWordInput
            concept={concept}
            words={concept.positive?.words || []}
            aspect={'positive'}
          />

          <div className={styles.wordsTitle}>{concept.negative?.name || 'NEGATIVE WORDS'}</div>
          <div className={styles.words}>
            {concept.negative?.words?.map((w, i) => (
              <DeletableConceptWordPill
                categoryId={conceptIdStr}
                word={w}
                key={w + i}
                aspect={'negative'}
              />
            ))}
          </div>
          <AddWordInput
            concept={concept}
            words={concept.negative?.words || []}
            aspect={'negative'}
          />
        </>
      )}
    </Popup>
  );
};

export default EditConceptPopup;

interface AddWordInputProps {
  concept: Concept;
  words: string[];
  aspect: AspectType | null;
  onUpdated?: (updated: Concept) => void;
}
const AddWordInput: React.FC<AddWordInputProps> = ({ concept, words, aspect, onUpdated }) => {
  const queryClient = useQueryClient();

  const addWordsMut = trpc.concepts.addWords.useMutation({
    onSuccess: (updatedConcept) => {
      queryClient.setQueryData<Concept[]>(conceptsRQKey(), (old) => {
        return RQUtils.updateArrQueryV2(old, updatedConcept, '_id');
      });

      onUpdated?.(updatedConcept);
    },
  });
  const addWords = (concept: Concept, words: string[]) => {
    if (addWordsMut.isLoading == true) {
      return;
    }
    if (words.length <= 0) {
      return;
    }

    addWordsMut.mutate({
      conceptId: concept._id.toHexString(),
      words: words,
      aspect: aspect,
    });
  };

  const { values, handleChange, registerSubmit } = useForm({ words, word: '' });

  const _onSubmit = async ({ values }: any) => {
    try {
      const wordsSet = new Set<string>(values.words);
      values.word.split(',').forEach((word: string) => {
        wordsSet.add(word.trim());
      });

      const newWords = Array.from(wordsSet);
      addWords(concept, newWords);
    } catch (error) {}
  };

  return (
    <form onSubmit={registerSubmit(_onSubmit)}>
      <Input value={values.word} name="word" onChange={handleChange} />
    </form>
  );
};

interface ConceptPillActionProps {
  categoryId: string;
  word: string;
  aspect: AspectType | null;
}
const DeletableConceptWordPill: React.FC<ConceptPillActionProps> = ({
  categoryId,
  word,
  aspect,
}) => {
  const queryClient = useQueryClient();

  const deleteClusterPhraseMut = trpc.concepts.removeWord.useMutation({
    onSuccess: (updatedConcept) => {
      // update the hidden and not hidden suggested clusters queries
      queryClient.setQueryData<Concept[]>(conceptsRQKey(), (old) => {
        // cluster element was updated
        return RQUtils.updateArrQueryV2(old, updatedConcept, '_id');
      });
    },
  });
  const deleteClusterPhrase = () => {
    if (deleteClusterPhraseMut.isLoading == true) {
      return;
    }
    deleteClusterPhraseMut.mutate({ conceptId: categoryId, word, aspect: aspect });
  };

  return (
    <DeletablePill
      text={word}
      status={deleteClusterPhraseMut.status}
      onDelete={deleteClusterPhrase}
    />
  );
};
