import React from 'react';
import { Button as Button2 } from '../../PoseidonComponents/Button/Button';
import { useAddAppMutation } from '../../trpcHooks/useReportAppMutation';
import { ServerReportBrand } from '../../utils/trpc';

import styles from './sourceUrlUpload.module.scss';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface SourceUrlUploadProps {
  reportId: string;
  brand: ServerReportBrand;
  provider: string;
  closeFn: () => void;
}

export const SourceUrlUpload: React.FC<SourceUrlUploadProps> = ({
  reportId,
  brand,
  provider,
  closeFn,
}) => {
  const [url, setUrl] = React.useState('');
  const addAppMutation = useAddAppMutation();

  const createSource = async () => {
    if (provider === 'amazon' || provider === 'googleMaps') {
      addAppMutation.mutate({ reportId, data: { appProvider: provider, brandId: brand._id, url } });
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  return (
    <div className={styles.container}>
      <Input
        placeholder="Url for source"
        value={url || ''}
        onChange={handleChangeInput}
        className={styles.input}
      />

      <div className={styles.buttons}>
        <Button2 onClick={createSource} type="primary" disabled={url.length === 0}>
          Create source
        </Button2>
        <Button2 onClick={closeFn}>Cancel</Button2>
      </div>
    </div>
  );
};

export default SourceUrlUpload;
