import queryString from 'query-string';
import React from 'react';
import { Location, Navigate, useLocation } from 'react-router-dom';
import { FullPageWaveSpinnerContainer } from './PoseidonComponents/WaveSpinnerContainer/WaveSpinnerContainer';
import { useAuthContext } from './contexts';
import { Logout } from './pages/logout';
import { ServerTeam, ServerUser, trpc } from './utils/trpc';

type AuthedChildrenFn = (data: { user: ServerUser; team?: ServerTeam }) => React.ReactNode;

//cleanRedirect == do not pass props of current location to redirect
interface AuthProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  redirectTo: string;
  loader?: React.ReactElement | null;
  cleanRedirect?: boolean;
  children?: React.ReactNode | AuthedChildrenFn;
}

interface UnAuthProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  redirectTo: string;
  loader?: React.ReactElement | null;
  cleanRedirect?: boolean;
  children?: React.ReactNode | AuthedChildrenFn;
}

export const RequireSuperAuthed: React.FC<AuthProps> = ({
  children,
  redirectTo,
  loader = <FullPageWaveSpinnerContainer />,
}) => {
  const { user, me } = useAuthContext();

  const defaultTeamId = me?.defaultTeamId?.toHexString();
  const { data: team } = trpc.team.id.useQuery(defaultTeamId || '', {
    enabled: Boolean(defaultTeamId),
  });

  if (user === undefined && me === undefined) return loader;
  if (user === null) return <Navigate to={redirectTo} />;
  if (user != null) {
    if (me === undefined) return loader;
    if (me === null) return <Logout />;

    if (me.isSuperAdmin) {
      if (typeof children === 'function') {
        return <>{children({ user: me, team })}</>;
      } else {
        return <>{children}</>;
      }
    } else return <Navigate to="/" />;
  }
  return loader;
};

export const RequireAuthed: React.FC<AuthProps> = ({
  children,
  redirectTo,
  cleanRedirect,
  loader = <FullPageWaveSpinnerContainer />,
}) => {
  const location = useLocation();
  const { me, user } = useAuthContext();

  const defaultTeamId = me?.defaultTeamId?.toHexString();
  const { data: team } = trpc.team.id.useQuery(defaultTeamId || '', {
    enabled: Boolean(defaultTeamId),
  });

  if (user === undefined && me === undefined) return loader;
  if (user === null) {
    if (cleanRedirect) {
      return <Navigate to={redirectTo} />;
    } else return <Navigate to={redirectNavigation(redirectTo, location)} />;
  }
  if (user != null) {
    if (me === undefined) return loader;
    if (me === null) return <Logout />;

    if (typeof children === 'function') {
      return <>{children({ user: me, team })}</>;
    } else {
      return <>{children}</>;
    }
  }
  return loader;
};

export const RequireUnAuthed: React.FC<UnAuthProps> = ({
  children,
  redirectTo,
  loader = <FullPageWaveSpinnerContainer />,
}) => {
  const location = useLocation();
  const { user, me } = useAuthContext();

  if (user === undefined && me === undefined) return loader;
  if (user === null) return <>{children}</>; //not logged in
  if (user != null) {
    if (me === undefined) return loader;
    if (me === null) return <Logout />;
    if (me != null) {
      const parsed = queryString.parse(location.search);
      const redirect = parsed.redirect as string | undefined;
      if (redirect) {
        return <Navigate to={decodeURIComponent(redirect)} />;
      }
      return <Navigate to={redirectTo} />;
    }
  }
  return loader;
};

const convertLocationToRedirectUrl = (location: Location) => {
  let url = location.pathname;
  if (location.search) {
    url += location.search;
  }
  if (location.hash) {
    url += location.hash;
  }
  return encodeURIComponent(url);
};

const redirectNavigation = (pathname: string, location: Location) => {
  const encodedRedirect = convertLocationToRedirectUrl(location);
  return {
    pathname,
    search: `?redirect=${encodedRedirect}`,
  };
};
