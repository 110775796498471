import useLocalStorage from '@rehooks/local-storage';
import { keyBy } from 'lodash';
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { FullHeight } from '../../PoseidonComponents/FullHeight/FullHeight';
import { FullPageWaveSpinnerContainer } from '../../PoseidonComponents/WaveSpinnerContainer/WaveSpinnerContainer';
import { ExtraColsContainer } from '../../components/ExtraCols2/ExtraCols2';
import { KeyTakeaways } from '../../components/KeyTakeaways/KeyTakeaways';
import { ReportLeftNav } from '../../components/LeftNav/LeftNav';
import { useReportsContext } from '../../contexts';
import { IIntervalBucket } from '../../models/aggModels/intervalBucket.entity';
import { DateTypeValue } from '../../models/dateTypeValue.model';
import ReportExecSummary from '../../pages/reports/[reportId]';
import ReportAdmin from '../../pages/reports/[reportId]/admin';
import ReportBrands from '../../pages/reports/[reportId]/brands';
import ReportComments from '../../pages/reports/[reportId]/comments';
import ReportDashboard from '../../pages/reports/[reportId]/dashboard';
import { SearchableExtraCols } from '../../pages/reports/[reportId]/searchableCols';
import SuggestedConcepts from '../../pages/reports/[reportId]/suggestedConcepts';
import {
  ReportCategory,
  ReportCategoryV2,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  SearchableExtraColModel,
  ServerDataCounts,
  ServerReport,
  ServerTeam,
  ServerUser,
  trpc,
} from '../../utils/trpc';
import { ReportBrandAppContainer } from './ReportBrandAppContainer';
import { ReportBrandContainer } from './ReportBrandContainer';

interface ReportProps {
  user: ServerUser;
  team: ServerTeam | undefined;
}

interface ReportWIdProps extends ReportProps {
  reportId: string;
}

const Report: React.FC<ReportProps> = ({ user, team }) => {
  const { reportId } = useParams();
  if (reportId == null) return null;

  return <ReportWId reportId={reportId} user={user} team={team} />;
};

const ReportWId: React.FC<ReportWIdProps> = ({ reportId, user, team }) => {
  const [dateTypeValue] = useLocalStorage<DateTypeValue>('dateTypeValue', {
    type: undefined,
    value: undefined,
  });
  const [{ reportJobsById }] = useReportsContext();

  const dateType = dateTypeValue.type;
  const dataValueString = dateTypeValue.value;
  const dateValue = dataValueString && !Number.isNaN(dataValueString) ? dataValueString : undefined;

  const reportDataCounts = trpc.report.dataCounts.useQuery(
    { reportId: reportId || '', interval: { type: dateType || 'infinity', value: dateValue || 0 } },
    { enabled: Boolean(reportId) },
  );

  const reportQuery = trpc.report.id.useQuery(reportId || '', { enabled: Boolean(reportId) });
  const reportJobs = reportId ? reportJobsById?.[reportId] : undefined;

  const intervalBucket: IIntervalBucket = { date: dateValue, type: dateType };

  const isReportProcessing = reportJobs?.isProcessing;

  const reportCategoriesRes = trpc.reportCategories.list.useQuery({ reportId });
  const reportCategories: ReportCategory[] | undefined = reportCategoriesRes.data;

  const reportCategoriesV2Res = trpc.reportCategoriesV2.list.useQuery({ reportId });
  const reportCategoriesV2: ReportCategoryV2[] | undefined = reportCategoriesV2Res.data;

  const reviewExtraColsRes = trpc.extraCols.forReport.useQuery({ reportId: reportId });
  const reviewExtraCols = reviewExtraColsRes.data?.extraCols;
  const reviewExtraColSections = reviewExtraColsRes.data?.sections;

  const searchableColsReq = trpc.extraCols.searchableForReport.useQuery({ reportId });
  const searchableCols = searchableColsReq.data?.searchableExtraCols;

  const searchableColsById = React.useMemo(
    () => new Map(searchableCols?.map((c) => [c._id.toHexString(), c])),
    [searchableCols],
  );

  if (reportQuery.data) {
    return (
      <ReportWithData
        report={reportQuery.data}
        reportCategories={reportCategories || []}
        reportCategoriesV2={reportCategoriesV2 || []}
        intervalBucket={intervalBucket}
        extraCols={reviewExtraCols}
        extraColsSections={reviewExtraColSections}
        searchableCols={searchableCols}
        searchableColsById={searchableColsById}
        dataCounts={reportDataCounts.data}
        user={user}
        team={team}
      />
    );
  } else {
    return <FullPageWaveSpinnerContainer />;
  }
};

interface ReportWithDataProps {
  report: ServerReport;
  reportCategories: ReportCategory[];
  reportCategoriesV2: ReportCategoryV2[];
  intervalBucket: IIntervalBucket;
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  searchableCols: SearchableExtraColModel[] | undefined;
  searchableColsById: Map<string, SearchableExtraColModel> | undefined;
  dataCounts?: ServerDataCounts;
  user: ServerUser;
  team: ServerTeam | undefined;
}
const ReportWithData: React.FC<ReportWithDataProps> = ({
  report,
  reportCategories,
  reportCategoriesV2,
  intervalBucket,
  extraCols,
  extraColsSections,
  searchableCols,
  searchableColsById,
  dataCounts,
  user,
  team,
}) => {
  const [, { fetchStatus }] = useReportsContext();

  const reportId = report._id.toString();
  const conceptsById = React.useMemo(
    () => keyBy(reportCategories, (r) => r._id.toHexString()),
    [reportCategories],
  );

  React.useEffect(() => {
    fetchStatus(reportId);
  }, [fetchStatus, reportId]);

  const [navMinimized] = useLocalStorage('navMinimized', false);

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  const showDashbaordCharts = (report?.flourishIds?.length ?? 0) > 0;
  return (
    <FullHeight style={style}>
      <ReportLeftNav report={report} user={user} team={team} />
      <Routes>
        <Route path="/admin" element={<ReportAdmin report={report} user={user} />} />

        {showDashbaordCharts && (
          <Route path="/dashboard" element={<ReportDashboard report={report} />} />
        )}

        <Route
          path="comments"
          element={<ReportComments report={report} conceptsById={conceptsById} />}
        />

        <Route
          path="key-takeaways"
          element={
            <KeyTakeaways report={report} conceptsById={conceptsById} dataCounts={dataCounts} />
          }
        />
        <Route
          path="/suggestedConcepts"
          element={<SuggestedConcepts report={report} user={user} />}
        />
        <Route
          path="/searchableCols"
          element={<SearchableExtraCols report={report} user={user} />}
        />

        <Route
          path="/brands"
          element={
            <ReportBrands
              report={report}
              reportCategories={reportCategories}
              reportCategoriesV2={reportCategoriesV2}
              dataCounts={dataCounts}
              user={user}
            />
          }
        />

        <Route
          path="/brands/:brandId"
          element={
            <ReportBrandContainer
              report={report}
              conceptsById={conceptsById}
              reportConcepts={reportCategories}
              intervalBucket={intervalBucket}
              extraCols={extraCols}
              extraColsSections={extraColsSections}
              dataCounts={dataCounts}
              user={user}
            />
          }
        />

        <Route
          path="brands/:brandId/:metaId"
          element={
            <ReportBrandAppContainer
              report={report}
              conceptsById={conceptsById}
              reportConcepts={reportCategories}
              intervalBucket={intervalBucket}
              extraCols={extraCols}
              extraColsSections={extraColsSections}
              dataCounts={dataCounts}
              user={user}
            />
          }
        />

        <Route
          path="brands/:brandId/:metaId/extraCols"
          element={
            <ExtraColsContainer
              user={user}
              report={report}
              searchableCols={searchableCols}
              searchableColsById={searchableColsById}
              team={team}
            />
          }
        />

        <Route
          path="/"
          element={
            <ReportExecSummary
              report={report}
              reportConcepts={reportCategories || []}
              dataCounts={dataCounts}
            />
          }
        />
      </Routes>
    </FullHeight>
  );
};

export default Report;
