import { Controller, useForm } from 'react-hook-form';
import { Search } from 'src/toneIcons';
import { useDebouncedCallback } from 'use-debounce';
import DatePicker from '../components/datePicker/datePicker';
import useRouterQuery from '../hooks/useRouterQuery';
import { Button, SCButton } from './Button/Button';
import { SegmentedControl } from './Button/SegmentedControl';
import { Input } from './Input/Input';
import { Bot, TrendUp } from './StrokeIcons';

interface ReportFiltersProps extends React.HTMLProps<HTMLDivElement> {
  firstReviewId?: string;
}

export const ReportFilters: React.FC<ReportFiltersProps> = ({ firstReviewId }) => {
  const [query, setQuery] = useRouterQuery();
  const { control } = useForm<{ q: string }>({
    mode: 'all',
    defaultValues: {
      q: query.q,
    },
  });
  const debounceFn = useDebouncedCallback(
    (n: string, v: string) => setQuery((o) => ({ ...o, [n]: v })),
    400,
  );

  const toggleBot = () => {
    if (query.machine?.rid) {
      setQuery((s) => ({ ...s, machine: undefined }));
    } else {
      if (firstReviewId) {
        setQuery((s) => ({ ...s, machine: { rid: firstReviewId } }));
      } else {
        setQuery((s) => ({ ...s, machine: undefined }));
      }
    }
  };

  const showSearch = query.section != 'versionHistory';

  return (
    <>
      {showSearch && (
        <Controller
          control={control}
          name="q"
          render={({ field }) => (
            <Input
              size="small"
              icon={<Search />}
              placeholder="Search"
              {...field}
              onChange={(e) => {
                field.onChange(e);
                debounceFn(field.name, e.currentTarget.value);
              }}
            />
          )}
        />
      )}
      <SentencesHistorySwitcher />
      <Button size="small" icon={<Bot />} onClick={toggleBot}>
        Classify
      </Button>
      <DatePicker groupBy="quarter" />
    </>
  );
};

type ID = 'sentences' | 'versionHistory' | undefined;

export const SentencesHistorySwitcher: React.FC<React.HTMLProps<HTMLDivElement>> = () => {
  const [query, setQuery] = useRouterQuery();

  const onChange = (uid: ID) => {
    const section = uid == null ? undefined : 'versionHistory';
    setQuery((o) => ({ ...o, section }));
  };

  return (
    <SegmentedControl<ID> size="small" defaultId={query.section} onChangeSegment={onChange}>
      <SCButton<ID> uid={undefined}>Voice</SCButton>
      <SCButton<ID> uid="versionHistory" icon={<TrendUp />}>
        History
      </SCButton>
    </SegmentedControl>
  );
};
