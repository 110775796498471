import React from 'react';
import { ContentDivider } from '../../../../PoseidonComponents/ContentDivider/ContentDivider';
import { WithLeftNav } from '../../../../PoseidonComponents/Layout/Layout';
import { PageHeader } from '../../../../PoseidonComponents/PageHeader/PageHeader';
import { CompareMatrix1 } from '../../../../PoseidonComponents/StrokeIcons';
import DatePicker from '../../../../components/datePicker/datePicker';
import { useReportLogEffect } from '../../../../libs/react-amplitude';
import {
  ReportCategory,
  ReportCategoryV2,
  ServerDataCounts,
  ServerReport,
  ServerUser,
} from '../../../../utils/trpc';
import styles from './index.module.scss';
import { ReportBrandTable } from '../../../../PoseidonComponents/ReportBrandTable';
import { Padded } from '../../../../PoseidonComponents/PaddedScrollable/PaddedScrollable';

interface ReportBrandsProps {
  report: ServerReport;
  reportCategories?: ReportCategory[];
  reportCategoriesV2?: ReportCategoryV2[];
  dataCounts?: ServerDataCounts;
  user: ServerUser;
}

const ReportBrands: React.FC<ReportBrandsProps> = ({
  report,
  reportCategories,
  reportCategoriesV2,
  dataCounts,
  user,
}) => {
  useReportLogEffect({ eventType: 'viewTLDR', report }, [report]);

  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<CompareMatrix1 />} title="Compare: Matrix">
        <ContentDivider end={<DatePicker groupBy="quarter" />} />
      </PageHeader>
      <Padded className={styles.wrap}>
        <ReportBrandTable
          dataCounts={dataCounts}
          reportCategories={reportCategories}
          reportCategoriesV2={reportCategoriesV2}
          report={report}
        />
      </Padded>
    </WithLeftNav>
  );
};

export default ReportBrands;
