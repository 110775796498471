import clsx from 'clsx';
import React from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';
import { Button } from '../../PoseidonComponents/Button/Button';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { DetailPage } from '../../PoseidonComponents/DetailPage/DetailPage';
import { WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { ReviewDetailPage } from '../../PoseidonComponents/ReviewDetailPage';
import { Lightbulb, Pencil, PlusCircle, XMarkCircle } from '../../PoseidonComponents/StrokeIcons';
import { useCreate } from '../../trpcHooks/useReportInsightSummary';
import { useGetAllByReportId } from '../../trpcHooks/useReportInsightSummary';
import { useSortSummaries } from '../../trpcHooks/useReportInsightSummary';
import { reOrder } from '../../utils/dragAndDrop';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCorners,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  InsightSummariesgetAllByReportId,
  ReportCategory,
  ServerDataCounts,
  ServerReport,
} from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import styles from './KeyTakeaways.module.scss';
import {
  ReportInsightSection,
  ReportInsightSectionEditable,
} from './ReportInsightSection/ReportInsightSection';
import { FullPageWaveSpinnerContainer } from '../../PoseidonComponents/WaveSpinnerContainer/WaveSpinnerContainer';
import { Droppable } from './Droppable';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface PropsWithData {
  report: ServerReport;
  conceptsById: Record<string, ReportCategory>;
  dataCounts?: ServerDataCounts;
}
export const KeyTakeaways: React.FC<PropsWithData> = ({ report, conceptsById, dataCounts }) => {
  const createMutation = useCreate();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [query] = useRouterQuery();
  const reportId = report._id.toHexString();
  const { data: getAllByReportIdQuery, isLoading } = useGetAllByReportId(report._id.toHexString());

  const lastIndex = (getAllByReportIdQuery?.insightSummaries?.length ?? 0) + 1;

  const onAddSection = () => {
    createMutation.mutate({ title: `Section ${lastIndex}`, reportId: report._id.toHexString() });
  };

  return (
    <WithLeftNav hasDetail={Boolean(query.rId)}>
      <PageHeader icon={<Lightbulb />} title="Key Takeaways">
        <ContentDivider
          end={
            <>
              {!isEditMode && (
                <Button size="small" icon={<Pencil />} onClick={() => setIsEditMode(true)}>
                  Edit
                </Button>
              )}
              {isEditMode && (
                <>
                  <Button size="small" icon={<PlusCircle />} onClick={onAddSection}>
                    Add Section
                  </Button>
                  <Button size="small" icon={<XMarkCircle />} onClick={() => setIsEditMode(false)}>
                    Done Editing
                  </Button>
                </>
              )}
            </>
          }
        />
      </PageHeader>
      {isLoading && <FullPageWaveSpinnerContainer />}
      {getAllByReportIdQuery && (
        <ReportInsightSummaryWithData
          report={report}
          isEditMode={isEditMode}
          getAllByReportIdQuery={getAllByReportIdQuery}
          conceptsById={conceptsById}
        />
      )}
      {query.rId && (
        <DetailPage>
          <ReviewDetailPage id={query.rId} reportCounts={dataCounts} reportId={reportId} />
        </DetailPage>
      )}
    </WithLeftNav>
  );
};

interface Props {
  report: ServerReport;
  getAllByReportIdQuery: InsightSummariesgetAllByReportId;
  isEditMode: boolean;
  conceptsById: Record<string, ReportCategory>;
}

const ReportInsightSummaryWithData: React.FC<Props> = ({
  getAllByReportIdQuery,
  isEditMode,
  report,
  conceptsById,
}) => {
  const sortSummaries = useSortSummaries();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const onDragEnd = async (event: DragEndEvent) => {
    const activeId = event.active.id;
    const overId = event.over?.id;

    if (overId == null) return;
    if (overId == activeId) return;

    const massById = getAllByReportIdQuery?.insightSummaries?.map((el) => el._id.toHexString());

    const activeIndex = massById.findIndex((el) => el === activeId);
    const overIndex = massById.findIndex((el) => el === overId);

    const summaryIds = arrayMove(massById, activeIndex, overIndex);

    await sortSummaries.mutateAsync({
      reportId: report._id.toHexString(),
      summaryIds,
    });
  };

  if (isEditMode) {
    return (
      <div className={styles.container}>
        <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={onDragEnd}>
          <SortableContext
            items={getAllByReportIdQuery?.insightSummaries?.map((el) => el._id.toHexString())}
            strategy={verticalListSortingStrategy}>
            {getAllByReportIdQuery?.insightSummaries?.map((section, index) => {
              const id = section._id.toHexString();
              const reviews = section.reviewIds
                ?.map((id) => getAllByReportIdQuery.reviewById?.[id.toHexString()])
                .filter(isNotNull);
              return (
                <Droppable key={id} id={id}>
                  <ReportInsightSectionEditable
                    report={report}
                    section={section}
                    reviews={reviews}
                    index={index}
                    conceptsById={conceptsById}
                  />
                </Droppable>
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
    );
  }

  return (
    <PaddedScrollable>
      {getAllByReportIdQuery?.insightSummaries?.map((section) => {
        const id = section._id.toHexString();
        const reviews = section.reviewIds
          ?.map((id) => getAllByReportIdQuery.reviewById?.[id.toHexString()])
          .filter(isNotNull);
        return (
          <ContentSection key={`${id}`}>
            <ReportInsightSection
              report={report}
              section={section}
              reviews={reviews}
              conceptsById={conceptsById}
            />
          </ContentSection>
        );
      })}
    </PaddedScrollable>
  );
};

export default KeyTakeaways;
