import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerBrand } from '../utils/trpc';
import { Select } from './Input/Select';
import { sourcesById } from '../sources';
import styles from './ReportProviderPicker.module.scss';

const DEFAULT_VALUE = '';
interface ReportProviderPickerProps {
  reportId: string;
  brand: ServerBrand;
  appId?: string;
}
export const ReportProviderPicker: React.FC<ReportProviderPickerProps> = ({
  reportId,
  brand,
  appId,
}) => {
  const navigate = useNavigate();
  const brandId = brand._id.toHexString();

  const options = [
    { value: DEFAULT_VALUE, label: 'All Sources' },
    ...(brand.apps?.map((app) => {
      const providerId = app.appProviderOverride || app?.appProvider
      const provider = providerId ? sourcesById?.[providerId] : undefined;
      const label = (providerId.startsWith('csv')) ? (app.title || provider?.name) : provider?.name
      return {
        value: app.metaId.toHexString(),
        label: label || '',
        icon: provider?.icon,
      };
    }) || []),
  ];

  const onChangeSource = (data: { label: string; value: string }) => {
    if (data.value === DEFAULT_VALUE) {
      navigate({
        pathname: `/reports/${reportId}/brands/${brandId}`,
        search: window.location.search,
      });
    } else {
      navigate({
        pathname: `/reports/${reportId}/brands/${brandId}/${data.value}`,
        search: window.location.search,
      });
    }
  };

  return (
    <Select size="small"
      placeholder="All Sources"
      value={appId || DEFAULT_VALUE}
      options={options}
      onChange={onChangeSource}
    />
  );
};
