import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { UpdateButton } from 'src/PoseidonComponents/Button/CustomButton';
import { ContentDivider } from '../../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../../PoseidonComponents/ContentSection/ContentSection';
import { Gap } from '../../../PoseidonComponents/Gap';
import { ReviewCard } from '../../../PoseidonComponents/ReviewCard/ReviewCard';
import { DraggerHandle, TrashCan } from '../../../PoseidonComponents/StrokeIcons';
import { SvgIconButton } from '../../../PoseidonComponents/SvgIconButton/SvgIconButton';
import { useDelete, useSummarizeMutation } from '../../../trpcHooks/useReportInsightSummary';
import {
  InsightSummariesGet,
  ReportCategory,
  ReportInsightSummaryReview,
  ServerReport,
} from '../../../utils/trpc';
import { FormSection } from '../FormSection/FormSection';
import styles from './ReportInsightSection.module.scss';
import { ReviewDragAndDrop } from './ReportInsightSectionDraggable';

interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  section: InsightSummariesGet;
  report: ServerReport;
  reviews?: ReportInsightSummaryReview[];
  conceptsById: Record<string, ReportCategory>;
}
export const ReportInsightSection: React.FC<SectionProps> = ({
  section,
  report,
  reviews,
  conceptsById,
}) => {
  return (
    <div className={styles.section}>
      <h2 className={styles.title}>{section.title}</h2>
      <p className={styles.description}>{section.description}</p>
      <div className={styles.reviewsWithBorder}>
        {reviews?.map((review) => (
          <ReviewCard
            report={report}
            review={review}
            key={review._id.toHexString()}
            conceptsById={conceptsById}
            shownButtons={new Set(['info', 'copy', 'download'])}
            showResponse={false}
          />
        ))}
      </div>
    </div>
  );
};

interface ReportInsightSectionDraggable extends SectionProps {
  index: number;
}

export const ReportInsightSectionEditable: React.FC<ReportInsightSectionDraggable> = ({
  section,
  report,
  reviews,
  index,
  conceptsById,
}) => {
  const deleteMutation = useDelete();
  const id = section._id.toHexString();

  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
  });

  const summarizeMutation = useSummarizeMutation();

  const handleSummarize = () =>
    summarizeMutation.mutateAsync({ reportId: report._id.toHexString(), id });

  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  return (
    <ContentSection className={styles.container}>
      <div ref={setNodeRef} style={style}>
        <div className={styles.buttons}>
          <SvgIconButton icon={<DraggerHandle />} {...attributes} {...listeners} />
          <SvgIconButton
            icon={<TrashCan />}
            onClick={() => deleteMutation.mutate({ id })}
            type="dangerous"
          />
        </div>
        {!summarizeMutation.isLoading && (
          <FormSection
            title={section.title}
            description={section.description || undefined}
            id={section._id.toHexString()}
          />
        )}
        {summarizeMutation.isLoading && (
          <div>
            <h2 className={styles.title}>{section.title}</h2>
            <p className={styles.description}>{section.description}</p>
          </div>
        )}
        {reviews != null && reviews.length > 0 && (
          <>
            <ContentDivider
              end={
                <UpdateButton
                  isLoading={summarizeMutation.isLoading}
                  size="small"
                  onClick={handleSummarize}>
                  Summarize
                </UpdateButton>
              }
            />
            <Gap height={6} />
            <div className={styles.reviews}>
              <ReviewDragAndDrop
                sectionId={id}
                report={report}
                reviews={reviews}
                conceptsById={conceptsById}
              />
            </div>
          </>
        )}
      </div>
    </ContentSection>
  );
};
