import clsx from 'clsx';
import React, { type CSSProperties } from 'react';
import { extractAchronym, isNotEmpty } from 'src/utils2';
import styles from './IconWithPlaceholder.module.scss';
import { ServerTeam } from '../../utils/trpc';

interface CommonProps {
  size: number;
  shape?: 'circle' | 'roundedSquare';
}
interface IconWithPlaceholderProps extends CommonProps {
  urls?: (string | undefined)[];
  alt: string;
  className?: string;
  placeholder?: JSX.Element | string | undefined;
}

export const IconWithPlaceholder = React.forwardRef<HTMLDivElement, IconWithPlaceholderProps>(
  ({ urls, size, alt, shape, placeholder, className }, ref) => {
    const [srcIndex, setSrcIndex] = React.useState(0);

    const src = urls?.filter(isNotEmpty)?.[srcIndex];
    const style = { '--icon-size': `${size}px` } as CSSProperties;
    return (
      <div
        ref={ref}
        className={clsx(styles.icon, className, shape ? styles?.[shape] : undefined)}
        style={style}>
        {src && (
          <img
            width={size}
            height={size}
            alt={alt}
            src={src}
            onError={() => setSrcIndex((i) => i + 1)}
          />
        )}
        {!src && placeholder}
      </div>
    );
  },
);

IconWithPlaceholder.displayName = 'IconWithPlaceholder';

interface CommonProps {
  size: number;
  shape?: 'circle' | 'roundedSquare';
  style?: CSSProperties;
}

interface TeamLogoProps extends CommonProps {
  team?: ServerTeam['team'];
}
export const TeamLogo: React.FC<TeamLogoProps> = ({ size, team, shape }) => {
  return (
    <IconWithPlaceholder
      placeholder={extractAchronym([team?.name])}
      // urls={[team?.logo?.url]}
      urls={[]}
      size={size}
      alt="team"
      shape={shape}
    />
  );
};

interface BrandLogoProps extends CommonProps {
  brand?: { name?: string; imageUrl?: string | null };
  className?: string;
}
export const BrandLogo = React.forwardRef<HTMLDivElement, BrandLogoProps>(
  ({ size, brand, shape, style, className }, ref) => {
    return (
      <IconWithPlaceholder
        placeholder={extractAchronym([brand?.name])}
        urls={[brand?.imageUrl || undefined]}
        size={size}
        alt="team"
        shape={shape}
        className={className}
        ref={ref}
        style={style}
      />
    );
  },
);
BrandLogo.displayName = 'BrandLogo';

interface AppLogoProps extends CommonProps {
  app?: { title?: string; iconUrl?: string | null };
  className?: string;
}
export const AppLogo = React.forwardRef<HTMLDivElement, AppLogoProps>(
  ({ size, app, shape, className }, ref) => {
    return (
      <IconWithPlaceholder
        placeholder={extractAchronym([app?.title])}
        urls={[app?.iconUrl || undefined]}
        size={size}
        alt="team"
        shape={shape}
        className={className}
        ref={ref}
      />
    );
  },
);
AppLogo.displayName = 'AppLogo';
