import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { Key } from '../../PoseidonComponents/StrokeIcons';
import { ReportsLeftNav } from '../LeftNav/LeftNav';
import DatePicker from '../datePicker/datePicker';
import { SearchableExtraColModel, ServerReport, ServerTeam, ServerUser, trpc } from '../../utils/trpc';
import { ExtraCol } from '../../containers/extraCol';
import styles from './ExtraCols2.module.scss';

interface ExtraColsContainerParams {
  user: ServerUser;
  report: ServerReport;
  searchableCols: SearchableExtraColModel[] | undefined;
  searchableColsById: Map<string, SearchableExtraColModel> | undefined;
  team: ServerTeam | undefined;
}

export const ExtraColsContainer: React.FC<ExtraColsContainerParams> = ({
  user,
  report,
  searchableCols,
  searchableColsById,
  team
}) => {
  const { metaId } = useParams();

  return (
    <WithLeftNav hasDetail={false}>
      <PageHeader icon={<Key />} title="Admin">
        <ContentDivider end={<DatePicker groupBy="quarter" />} />
      </PageHeader>
      {metaId != null && (
        <ExtraCols
          user={user}
          report={report}
          appId={metaId}
          searchableCols={searchableCols}
          searchableColsById={searchableColsById}
          team={team}
        />
      )}
      {metaId == null && <div>App Id missing</div>}
    </WithLeftNav>
  );
};

interface ExtraColsParams {
  user: ServerUser;
  report: ServerReport;
  appId: string;
  searchableCols: SearchableExtraColModel[] | undefined;
  searchableColsById: Map<string, SearchableExtraColModel> | undefined;
  team: ServerTeam | undefined;
}

export const ExtraCols: React.FC<ExtraColsParams> = ({
  user,
  report,
  appId,
  searchableCols,
  searchableColsById,
  team
}) => {
  const reportId = report._id.toHexString();

  const extraColsForAppReq = trpc.extraCols.forApp.useQuery({ reportId, appId });
  const extraColsForApp = extraColsForAppReq.data?.extraCols;

  return (
    <div className={styles.containerWrap}>
      <ReportsLeftNav user={user} team={team} />
      <div className={styles.container}>
        <div>Extra Cols</div>
        {extraColsForApp != null &&
          extraColsForApp.map((col) => (
            <ExtraCol
              key={`${col._id.toHexString()}`}
              reportId={reportId}
              extraCol={col}
              searchableCols={searchableCols}
              searchableColsById={searchableColsById}
            />
          ))}
      </div>
    </div>
  );
};
