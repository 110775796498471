import { ObjectId } from 'bson';
import { orderBy } from 'lodash';
import { RouterOutput } from './trpc';

export type RQKey<R extends RouterOutput, K extends keyof R> = [
  [K, keyof R[K]],
  {
    input?: object;
    type: 'query';
  },
];

export type RQKeyPath<R extends RouterOutput, K extends keyof R> = RQKey<R, K>[0];
export type RQKeyParams<R extends RouterOutput, K extends keyof R> = RQKey<R, K>[1];

type a = RouterOutput['reportCategoriesV2'];
// type b = keyof a

export function buildRQKey<R extends RouterOutput, K extends keyof R>(
  namespace: K,
  procedure: keyof R[K],
  input: object | undefined,
): RQKey<R, K> {
  const params: RQKeyParams<R, K> = input != null ? { input, type: 'query' } : { type: 'query' };
  const tup: RQKey<R, K> = [[namespace, procedure], params];
  return tup;
}

export const reportCategoriesV2ListRQKey = (reportId: string) => {
  return buildRQKey('reportCategoriesV2', 'list', { reportId: reportId });
};
export const suggestedClustersListRQKey = (reportId: string) => {
  return buildRQKey('suggestedClusters', 'list', { reportId: reportId });
};
export const reportPhrasesListRQKey = (reportId: string) => {
  return buildRQKey('reportPhrases', 'list', { reportId: reportId });
};
export const reportCategoriesRQKey = (reportId: string) => {
  return buildRQKey('reportCategories', 'list', { reportId: reportId });
};

export const commemtsRQKey = (reportId: string) => {
  return buildRQKey('reportReviews', 'forBrandOld', { reportId: reportId });
};

export const extraColsForReportAppRQKey = (reportId: string, appId: string) => {
  return buildRQKey('extraCols', 'forApp', { reportId: reportId, appId: appId });
};
export const searchableExtraColsForReportRQKey = (reportId: string) => {
  return buildRQKey('extraCols', 'searchableForReport', { reportId: reportId });
};
export const reportConceptByIdRQKey = (reportId: string, id: string) => {
  return buildRQKey('report', 'conceptById', { reportId, id });
};

export const reportClassifyByReviewId = (reportId: string, reviewId: string) => {
  return buildRQKey('reportClassify', 'byReviewId', { reportId, reviewId });
};

export const reportReviewsIdWithBrand = (reportId: string, reviewId: string) => {
  return buildRQKey('reportReviews', 'idWithBrand', { reportId, reviewId });
};


export const conceptsRQKey = () => {
  return buildRQKey('concepts', 'list', undefined);
};
export const conceptGroupsRQKey = () => {
  return buildRQKey('conceptGroups', 'list', undefined);
};
export const conceptGroupsForReportRQKey = (reportId: string) => {
  return buildRQKey('conceptGroups', 'forReport', { reportId: reportId });
};

export const searchableColsForReport = (reportId: string) => {
  return buildRQKey('extraCols', 'searchableForReport', { reportId: reportId });
};

function equals<T>(a: T, b: T) {
  if (a instanceof ObjectId && b instanceof ObjectId) {
    return a.equals(b);
  }

  return a == b;
}

export class RQUtils {
  static updateArrQueryV2<T>(
    oldArr: T[] | undefined,
    updated: T,
    idProp: keyof T,
    options?: { doAppendAndSortBy: (element: T) => any },
  ): T[] {
    // cluster element was updated
    if (oldArr == null) {
      return [updated];
    }
    let didAdd = false;
    const updatedArr = oldArr.map((old) => {
      if (equals(old[idProp], updated[idProp])) {
        didAdd = true;
        return updated;
      } else {
        return old;
      }
    });
    if (didAdd == false) {
      const withNew = [...oldArr, updated];
      if (options?.doAppendAndSortBy == null) {
        return withNew;
      } else {
        return orderBy([...oldArr, updated], options.doAppendAndSortBy);
      }
    } else {
      return updatedArr;
    }
  }
  static bulkUpdateArrQueryV2<T, K extends keyof T>(
    oldArr: T[] | undefined,
    updatedArr: T[],
    idProp: K,
    options?: { doAppendAndSortBy: (element: T) => any },
  ): T[] {
    // cluster element was updated
    if (oldArr == null) {
      return [...updatedArr];
    }

    // create a map to store the new updates by id
    const updatesById = updatedArr.reduce((acc, el) => {
      acc.set(el[idProp], el);
      return acc;
    }, new Map<T[K], T>());
    const newArr = oldArr.map((old) => {
      const updatedEl = updatesById.get(old[idProp]);
      if (updatedEl != null) {
        // remove the updated element from the map, so that we can track with were not added
        updatesById.delete(old[idProp]);

        return updatedEl;
      } else {
        return old;
      }
    });

    if (updatesById.size <= 0) {
      // all updated element were added, return new array
      return newArr;
    }
    if (options?.doAppendAndSortBy == null) {
      return newArr;
    }

    const remainingUpdates = updatesById.values();
    newArr.push(...remainingUpdates);
    
    return orderBy(newArr, options.doAppendAndSortBy);
  }

  static delArrQueryV2<T, K extends keyof T>(
    oldArr: T[] | undefined,
    deletedId: T[K],
    idProp: K,
  ): T[] | undefined {
    return oldArr?.filter((e) => {
      return !equals(e[idProp], deletedId);
    });
  }

  static updateArrQuery<T extends { id: string }>(
    oldArr: T[] | undefined,
    updated: T,
    options?: { doAppendAndSortBy: (element: T) => any },
  ): T[] {
    // cluster element was updated
    if (oldArr == null) {
      return [updated];
    }
    let didAdd = false;
    const updatedArr = oldArr.map((old) => {
      if (old.id == updated.id) {
        didAdd = true;
        return updated;
      } else {
        return old;
      }
    });
    if (didAdd == false && options?.doAppendAndSortBy != null) {
      return orderBy([...oldArr, updated], options.doAppendAndSortBy);
    } else {
      return updatedArr;
    }
  }

  static bulkUpdateArrQuery<T extends { id: string }>(
    oldArr: T[] | undefined,
    updatedArr: T[],
    options?: { doAppendAndSortBy: (element: T) => any },
  ): T[] {
    // cluster element was updated
    if (oldArr == null) {
      return [...updatedArr];
    }

    // create a map to store the new updates by id
    const updatesById = updatedArr.reduce((acc, el) => {
      acc.set(el.id, el);
      return acc;
    }, new Map<string, T>());
    const newArr = oldArr.map((old) => {
      const updatedEl = updatesById.get(old.id);
      if (updatedEl != null) {
        // remove the updated element from the map, so that we can track with were not added
        updatesById.delete(old.id);

        return updatedEl;
      } else {
        return old;
      }
    });

    if (updatesById.size <= 0) {
      // all updated element were added, return new array
      return newArr;
    }
    if (options?.doAppendAndSortBy == null) {
      return newArr;
    }

    const remainingUpdates = updatesById.values();
    newArr.push(...remainingUpdates);

    return orderBy(newArr, options.doAppendAndSortBy);
  }

  static updateDictQuery<T extends { id: string }>(
    oldDict: Record<string, T> | undefined,
    updated: T,
  ): Record<string, T> {
    const newDict = {
      ...oldDict,
      [updated.id]: updated,
    };
    return newDict;
  }

  static bulkUpdateDictQuery<T extends { id: string }>(
    oldDict: Record<string, T> | undefined,
    updatedArr: T[],
  ): Record<string, T> {
    const newDict = {
      ...oldDict,
    };
    updatedArr.forEach((u) => {
      newDict[u.id] = u;
    });
    return newDict;
  }

  static delArrQuery<T extends { id: string }>(
    oldArr: T[] | undefined,
    deletedId: string,
  ): T[] | undefined {
    return oldArr?.filter((e) => e.id != deletedId);
  }
}
