import React from 'react';
import styles from './Images.module.scss';

interface ImagesProps {
  images: string[];
}

export const Images: React.FC<ImagesProps> = ({ images }) => {
  return (
    <div className={styles.container}>
      {images.map((image, i) => {
        return (
          <div key={i} className={styles.image}>
            <img alt="image" src={image} />
          </div>
        );
      })}
    </div>
  );
};
