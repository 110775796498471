import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Badge } from '../../PoseidonComponents/Badge/Badge';
import { Flex } from '../../PoseidonComponents/Layout/Layout';
import { Pencil, TrashCan } from '../../PoseidonComponents/StrokeIcons';
import { SvgIconButton } from '../../PoseidonComponents/SvgIconButton/SvgIconButton';
import { RQUtils, reportCategoriesRQKey } from '../../utils/reactQuery.utils';
import { Concept, ReportCategory, trpc } from '../../utils/trpc';
import styles from './suggestConcept.module.scss';

interface SuggestConceptProps {
  reportId?: string;
  concept: Concept;
  active?: boolean;
  canCreate?: boolean;
  onCreatedForReport?: (reportConcept: ReportCategory) => void;
  onDeletePressed?: (conceptId: string) => void;
  onEditPressed?: (concept: Concept) => void;
}

function calcConceptName(concept: Concept): string {
  if (concept?.name != null && concept.name.length > 0) {
    return concept.name;
  }

  return `Concept ${concept._id.toHexString()}`;
}

export const SuggestConcept: React.FC<SuggestConceptProps> = ({
  reportId,
  concept,
  active,
  canCreate,
  onCreatedForReport,
  onDeletePressed,
  onEditPressed,
}) => {
  const queryClient = useQueryClient();
  const calculatedConceptName = calcConceptName(concept);

  const createReportConceptFromLibMut =
    reportId == null
      ? undefined
      : trpc.reportCategoriesConcept.createFromConcept.useMutation({
          onSuccess: (updatedReportConcept: ReportCategory) => {
            queryClient.setQueryData<ReportCategory[]>(reportCategoriesRQKey(reportId), (old) => {
              return RQUtils.updateArrQueryV2<ReportCategory>(old, updatedReportConcept, '_id');
            });
            onCreatedForReport?.(updatedReportConcept);
          },
        });

  const createReportConceptFromLib = (reportId: string, libConcept: Concept) => {
    if (createReportConceptFromLibMut == null) {
      return;
    }
    if (createReportConceptFromLibMut.isLoading == true) {
      return;
    }
    createReportConceptFromLibMut.mutate({
      reportId,
      conceptId: libConcept._id.toHexString(),
    });
  };

  return (
    <div>
      <div className={styles.suggestConcept}>
        <div className={styles.conceptName}>
          <span>{calculatedConceptName}</span>
          <div className={styles.buttons}>
            {onEditPressed && concept != null && (
              <SvgIconButton
                size="small"
                icon={<Pencil />}
                onClick={() => onEditPressed?.(concept)}
                tooltip="edit"
              />
            )}
            {onDeletePressed && concept != null && (
              <SvgIconButton
                size="small"
                icon={<TrashCan />}
                tooltip="Delete"
                type="dangerous"
                onClick={() => onDeletePressed?.(concept._id.toHexString())}
              />
            )}
          </div>
        </div>
        {concept.catType === 'domain' && (
          <Flex>
            {concept?.words?.map((s) => (
              <Badge key={s}>{s}</Badge>
            ))}
          </Flex>
        )}

        {concept.catType === 'judgement' && (
          <>
            {concept?.positive && (
              <Flex>
                <div className={styles.suggestConceptWordsTitle}>
                  {concept.positive.name || 'POSITIVE WORDS'}
                </div>
                {concept.positive?.words?.map((s) => (
                  <Badge key={s}>{s}</Badge>
                ))}
              </Flex>
            )}
            {concept?.negative && (
              <Flex>
                <div className={styles.suggestConceptWordsTitle}>
                  {concept.negative.name || 'NEGATIVE WORDS'}
                </div>
                {concept.negative?.words?.map((s) => (
                  <Badge key={s}>{s}</Badge>
                ))}
              </Flex>
            )}
          </>
        )}
        {reportId != null && canCreate === true && concept != null && active == true && (
          <div
            className={styles.activeConceptCreate}
            onClick={() => createReportConceptFromLib(reportId, concept)}>
            <div className={styles.activeConceptCreateButton}>Create Concept [enter]</div>
          </div>
        )}
        {reportId != null && canCreate === true && concept != null && active == false && (
          <div
            className={styles.suggestConceptCreate}
            onClick={() => createReportConceptFromLib(reportId, concept)}>
            <div className={styles.suggestConceptCreateButton}>Create Concept</div>
          </div>
        )}
      </div>
    </div>
  );
};
