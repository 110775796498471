import { useLocalStorage } from '@rehooks/local-storage';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Badge } from '../../PoseidonComponents/Badge/Badge';
import {
  BreadCrumb,
  BreadCrumbLink,
  Breadcrumbs,
} from '../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { Button, LinkButton } from '../../PoseidonComponents/Button/Button';
import { UpdateButton } from '../../PoseidonComponents/Button/CustomButton';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import { ContentSection } from '../../PoseidonComponents/ContentSection/ContentSection';
import { Form } from '../../PoseidonComponents/Form/Form';
import { FullHeight } from '../../PoseidonComponents/FullHeight/FullHeight';
import { Input } from '../../PoseidonComponents/Input/Input';
import { Flex, Right, WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PaddedScrollable } from '../../PoseidonComponents/PaddedScrollable/PaddedScrollable';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { ReportBrandsSection } from '../../PoseidonComponents/ReportAdminsBlocks/ReportBrandsSection';
import { Pencil, TrashCan } from '../../PoseidonComponents/StrokeIcons';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import { ReportLeftNav } from '../../components/LeftNav/LeftNav';
import DeletePopup from '../../components/deletePopup/deletePopup';
import { useReportCategoriesCreateFromConcepts, useReportCategoriesDelete } from '../../trpcHooks/useReportCategoriesMutation';
import { useReportDeleteMutation } from '../../trpcHooks/useReportMutation';
import { ServerReport, ServerTeam, ServerUser, trpc } from '../../utils/trpc';
import { isNotEmpty, isNotNull } from '../../utils2';

interface Props {
  user: ServerUser;
  team: ServerTeam | undefined;
}
const DraftReport: React.FC<Props> = ({ user, team }) => {
  const params = useParams();
  const reportId = params.reportId;
  const reportQuery = trpc.report.id.useQuery(reportId || '', { enabled: Boolean(reportId) });

  const report = reportQuery.data;
  if (report === null) return <Navigate to="/reports" />;
  if (report === undefined) return <WaveSpinner />;
  return <DraftReportWithId report={report} user={user} team={team} />;
};

interface DraftReportWithIdProps {
  report: ServerReport;
  user: ServerUser;
  team: ServerTeam | undefined;
}
interface FormData {
  name: string;
}
const DraftReportWithId: React.FC<DraftReportWithIdProps> = ({ report, user, team }) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const updateReportMutation = trpc.report.update.useMutation();
  const { control, handleSubmit } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      name: report.name,
    },
  });

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  const onSubmit = (data: FormData) => {
    updateReportMutation.mutate({ id: report._id.toHexString(), data });
  };

  const name = report.name;

  return (
    <FullHeight style={style}>
      <ReportLeftNav report={report} user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Pencil />} title="Workspaces">
          <ContentDivider end={<AdminButtons report={report} />}>
            <Breadcrumbs showStartingChevron>
              <BreadCrumbLink to="/drafts">Drafts</BreadCrumbLink>
              {!isNotEmpty(name) && <BreadCrumb>?????</BreadCrumb>}
              {isNotEmpty(name) && <BreadCrumb>{name}</BreadCrumb>}
            </Breadcrumbs>
          </ContentDivider>
        </PageHeader>
        <PaddedScrollable>
          <ContentSection>
            <ContentDivider>Workspace Title</ContentDivider>
            <Form width="restricted" onSubmit={handleSubmit(onSubmit)}>
              <ContentSection>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input
                      title="Name"
                      placeholder="Workspace Name"
                      disabled={updateReportMutation.isLoading}
                      autoFocus
                      {...field}
                    />
                  )}
                />
                <Right>
                  <UpdateButton isLoading={updateReportMutation.isLoading}> Update</UpdateButton>
                </Right>
              </ContentSection>
            </Form>
          </ContentSection>

          <ReportBrandsSection report={report} user={user} />
          <AddDefaultSection report={report} />
        </PaddedScrollable>
      </WithLeftNav>
    </FullHeight>
  );
};

interface AdminButtonsProps {
  report: ServerReport;
}
const AdminButtons: React.FC<AdminButtonsProps> = ({ report }) => {
  const [showDeletePopupId, setShowDeletePopupId] = React.useState<any>(null);
  const navigate = useNavigate();
  const requestReportMutation = trpc.report.request.useMutation();
  const reportDeleteMutation = useReportDeleteMutation();
  const reportId = report._id.toHexString();

  let isSubmitDisabled = false;
  if (report == null || report == null || report.apps == null) isSubmitDisabled = true;
  else if (report.apps.length < 1) isSubmitDisabled = true;
  else if (report.name == null || report.name.length <= 0) isSubmitDisabled = true;

  const requestReport = async () => {
    await requestReportMutation.mutateAsync({ reportId, chargeId: 'admin' });
    navigate?.(`/reports/${reportId}`);
  };

  const closeDeletePopup = async (deleteId?: string) => {
    if (deleteId) {
      await reportDeleteMutation.mutateAsync(deleteId);
      setShowDeletePopupId(null);
      navigate('/reports');
    } else {
      setShowDeletePopupId(null);
    }
  };

  return (
    <>
      <Button
        size="small"
        type="dangerous"
        icon={<TrashCan />}
        onClick={() => setShowDeletePopupId(reportId)}>
        Delete Workspace
      </Button>
      <Button size="small" onClick={!isSubmitDisabled ? requestReport : undefined}>
        Submit
      </Button>
      <LinkButton size="small" to="/reports">
        Cancel
      </LinkButton>

      {showDeletePopupId != null && (
        <DeletePopup
          onClose={() => closeDeletePopup()}
          onSubmit={() => closeDeletePopup(showDeletePopupId)}
        />
      )}
    </>
  );
};

export default DraftReport;

interface AddDefaultSectionProps {
  report: ServerReport;
}
const AddDefaultSection: React.FC<AddDefaultSectionProps> = ({ report }) => {
  const reportId = report._id.toHexString();

  const createReportCategoryMutation = useReportCategoriesCreateFromConcepts();
  const deleteReportCategoryMutation = useReportCategoriesDelete(reportId);
  const reportCategoriesQuery = trpc.reportCategories.list.useQuery({ reportId });
  const reportCategories = reportCategoriesQuery.data;

  const reportConceptGroupsRes = trpc.conceptGroups.forReport.useQuery(
    { reportId },
    { enabled: Boolean(reportId) },
  );
  const reportConceptGroups = reportConceptGroupsRes.data?.reportConceptGroups;
  const conceptById = reportConceptGroupsRes.data?.conceptById;

  const createReportConceptFromLib = (reportId: string, conceptIds: string[]) => {
    if (createReportCategoryMutation.isLoading == true) {
      return;
    }
    createReportCategoryMutation.mutate({ reportId, conceptIds });
  };

  const deleteReportConcept = (reportId: string, reportCategoryId: string) => {
    if (deleteReportCategoryMutation.isLoading == true) {
      return;
    }

    deleteReportCategoryMutation.mutate({ reportId, categoryId: reportCategoryId });
  };

  const onSelect = (conceptId: string) => {
    const selected = reportCategories?.find((v) => v.blueprintId?.toHexString() === conceptId);

    if (selected != null) {
      deleteReportConcept(reportId, selected._id.toHexString());
    } else {
      createReportConceptFromLib(reportId, [conceptId]);
    }
  };

  return (
    <ContentSection>
      <ContentDivider>Add default concepts</ContentDivider>

      <div>Pick from an initial list of concepts.</div>
      <div>Customize or add more later from your dashboard.</div>

      {reportConceptGroups?.map((group) => {
        const groupId = group._id.toHexString();
        return (
          <ContentSection key={groupId}>
            <ContentDivider>{group.name}</ContentDivider>
            <Flex>
              {group.conceptIds
                .map((id) => conceptById?.[id])
                .filter(isNotNull)
                .map((concept) => {
                  const id = concept._id.toHexString();
                  const selected = reportCategories?.find((v) => v.blueprintId?.equals(id)) != null;

                  return (
                    <Badge
                      key={id}
                      type={selected ? 'primary' : undefined}
                      onClick={() => onSelect(concept._id.toHexString())}>
                      {concept.name || concept._id.toHexString()}
                    </Badge>
                  );
                })}
            </Flex>
          </ContentSection>
        );
      })}
    </ContentSection>
  );
};
