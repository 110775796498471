import Color from 'color';
import escapeStringRegexp from 'escape-string-regexp';
import { groupBy, mapValues, reverse, sortBy, trimEnd, trimStart } from 'lodash';
import moment from 'moment';
import * as Concepts from './components/icons/concepts';
import { Rank1Icon, Rank2Icon, Rank3Icon, Rank4Icon, Rank5Icon } from './components/icons/icons';
import { Concept, ConceptType, ServerDataCounts, ServerReportBrand } from './utils/trpc';

export const categories = [
  { key: 'quality', name: 'Quality', icon: Concepts.Quality },
  { key: 'design', name: 'Design', icon: Concepts.Design },
  { key: 'featureRequest', name: 'Feature Request', icon: Concepts.Features },
  { key: 'pricing', name: 'Pricing', icon: Concepts.Pricing },
  { key: 'service', name: 'Service', icon: Concepts.Service },
];

export const conceptIconByName = mapValues(
  groupBy(categories, (c) => c.name),
  (cc) => cc[0]?.icon,
);

export const strokeIconByName = mapValues(
  groupBy(categories, (c) => c.name),
  (cc) => cc[0]?.icon,
);

export const getRangeString = (value?: number, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return undefined;
  const val = value / outOf;
  if (val <= 0.2) return 'poor';
  else if (val <= 0.4) return 'bad';
  else if (val <= 0.6) return 'ok';
  else if (val <= 0.8) return 'good';
  else return 'great';
};

export const getScore = (avg?: number) => {
  if (avg == null) return undefined;
  return Math.round((avg + 1) * 50);
};

export const isMacLike = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

export const sentimentWords: { [id: number]: string } = {
  1: 'terrible',
  2: 'bad',
  3: 'ok',
  4: 'good',
  5: 'great',
};

export const extractAchronym = (s: (string | undefined)[], maxLetters = 3) => {
  const letters = s
    .filter(isNotNull)
    .map((s) => s[0]?.toLocaleUpperCase())
    .join('');
  if (maxLetters) return letters.slice(0, maxLetters);
  else return letters;
};

export interface Meta<T, M = {}> {
  data?: T;
}

export interface DefinedMeta<T, M = {}> {
  data: T;
  loading?: boolean;
}

export const defineMeta = <T>(meta?: Meta<T>) => {
  if (meta?.data != null) return meta as DefinedMeta<T>;
  else return undefined;
};

export const getColor = (i: number) => {
  if (i === 0) return '#66c2a5';
  else if (i === 1) return '#fc8d62';
  else if (i === 2) return '#8da0cb';
  else return '#666';
};

export type NPSScore = 0 | 1 | 2 | 3;
export const getNPSScore = (nps: number): NPSScore => {
  if (nps <= 3) return 0;
  else if (nps <= 6) return 1;
  else if (nps <= 8) return 2;
  else if (nps <= 10) return 3;
  else return 0;
};
export const getNPSColor = (score: NPSScore) => {
  if (score <= 0) return '#FF5F69';
  else if (score <= 1) return '#F98964';
  else if (score <= 2) return '#F7CC3F';
  else if (score <= 3) return '#4EA051';
};

export const getLetterGrade = (score: number) => {
  if (score == null) return undefined;

  if (score >= 95) return 'A+';
  else if (score >= 85) return 'A';
  else if (score >= 80) return 'A-';
  else if (score >= 75) return 'B+';
  else if (score >= 65) return 'B';
  else if (score >= 60) return 'B-';
  else if (score >= 55) return 'C+';
  else if (score >= 45) return 'C';
  else if (score >= 40) return 'C-';
  else if (score >= 35) return 'D+';
  else if (score >= 25) return 'D';
  else if (score >= 20) return 'D-';
  else if (score >= 15) return 'F+';
  else if (score >= 5) return 'F';
  else return 'F-';
};

export const starColor = (value: number) => {
  if (value === 5) return '#FFB135';
  else if (value === 4) return '#FFBC51';
  else if (value === 3) return '#FFC76C';
  else if (value === 2) return '#FFD188';
  else if (value === 1) return '#FFDCA4';
  else return '#FFF0D9';
};

export const getRangeColor = (value?: number, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#C5C5C5';

  const val = value / outOf;
  if (val <= 0.2) return '#D71015'; // red
  else if (val <= 0.4) return '#FFAC3C'; // orange
  else if (val <= 0.6) return '#edc909'; // yellow
  else if (val <= 0.8) return '#b1d00b'; // green
  else return '#71CE0B'; // deep green
};

export const getRangeColor2 = (value?: number, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#EC3E23'; // red
  else if (val <= 0.4) return '#EA8F14'; // orange
  else if (val <= 0.6) return '#E7C601'; // yellow
  else if (val <= 0.8) return '#ACB601'; // green
  else return '#5C9E02'; // deep green
};

export const isNotNull = <T>(arg: T | undefined | null): arg is T => {
  return !(arg === null || arg === undefined);
};

export const isStringNotEmpty = (arg: string | undefined | null): arg is string => {
  return isNotNull(arg) && arg.length > 0;
};

export const getHighlightRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#FBD8D3'; // red
  else if (val <= 0.4) return '#FBE9D0'; // orange
  else if (val <= 0.6) return '#FAF4CC'; // yellow
  else if (val <= 0.8) return '#EEF0CC'; // green
  else return '#DEECCC'; // deep green
};
export const getFadedHighlightRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#FEF5F4'; // red
  else if (val <= 0.4) return '#FEF9F3'; // orange
  else if (val <= 0.6) return '#FEFCF2'; // yellow
  else if (val <= 0.8) return '#FBFBF2'; // green
  else return '#F7FAF2'; // deep green
};

export const isNotEmpty = (arg: string | undefined | null): arg is string => {
  return isNotNull(arg) && arg.length > 0;
};

export const getHoverHighlightRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#F59E91'; // red
  else if (val <= 0.4) return '#F4C789'; // orange
  else if (val <= 0.6) return '#F3E280'; // yellow
  else if (val <= 0.8) return '#D5DA80'; // green
  else return '#ADCE80'; // deep green
};

export const getBubbleColors = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#efefef';

  const val = value / outOf;
  if (val <= 0.2) return '#EC3E23'; // red
  else if (val <= 0.4) return '#EA8F14'; // orange
  else if (val <= 0.6) return '#d0d0d0'; // yellow
  else if (val <= 0.8) return '#ACB601'; // green
  else return '#5C9E02'; // deep green
};

export const getDetailRangeColor = (value?: number, outOf: number = 100) => {
  if (value == null || Number.isNaN(value)) return '#C5C5C5';

  const val = value / outOf;
  if (val <= 0.04 * 2) return '#F93B3B';
  else if (val <= 0.04 * 5) return '#EF761E';
  else if (val <= 0.04 * 7) return '#ff8329';
  else if (val <= 0.04 * 10) return '#ffdd00';
  else if (val <= 0.04 * 12) return '#ffdd00';
  else if (val <= 0.04 * 15) return '#ffdd00';
  else if (val <= 0.04 * 17) return '#C2F636';
  else if (val <= 0.04 * 20) return '#C2F636';
  else if (val <= 0.04 * 23) return '#7DB44B';
  else return '#519D0D';
};

export const getRangeIcon = (value?: number, outOf = 100) => {
  if (value == null || Number.isNaN(value)) return undefined;

  const val = value / outOf;
  if (val <= 0.2) return Rank1Icon;
  else if (val <= 0.4) return Rank2Icon;
  else if (val <= 0.6) return Rank3Icon;
  else if (val <= 0.8) return Rank4Icon;
  else return Rank5Icon;
};

export const scoreToFeedbackColor = (score: number) => {
  if (score <= -0.6) return '#FF5F69';
  else if (score <= -0.2) return '#F98964';
  else if (score <= 0.2) return '#F7CC3F';
  else if (score <= 0.6) return '#83C956';
  else if (score > 0.6) return '#4EA051';
  else return undefined;
};

export const isMac = () => navigator.userAgent.indexOf('Mac OS X') !== -1;

export const namespaceStyle = () => {
  const primaryColor = '#524f9f';
  const secondaryColor = '#634592';
  return {
    ...colorStyle(primaryColor, 'primary'),
    ...colorStyle(secondaryColor, 'secondary'),
  };
};

export const colorStyle = (color: string, prefix: string) => {
  const style: any = {};

  const onWhite = Color('#f9f9f9');

  style[`--${prefix}-color`] = color;
  try {
    style[`--${prefix}-w98-color`] = Color(color).mix(onWhite, 0.95).hex();
    style[`--${prefix}-w95-color`] = Color(color).mix(onWhite, 0.95).hex();
    style[`--${prefix}-w90-color`] = Color(color).mix(onWhite, 0.9).hex();
    style[`--${prefix}-w80-color`] = Color(color).mix(onWhite, 0.8).hex();
    style[`--${prefix}-w70-color`] = Color(color).mix(onWhite, 0.7).hex();
    style[`--${prefix}-w50-color`] = Color(color).mix(onWhite, 0.5).hex();
    style[`--${prefix}-w40-color`] = Color(color).mix(onWhite, 0.4).hex();
    style[`--${prefix}-w30-color`] = Color(color).mix(onWhite, 0.3).hex();
    style[`--${prefix}-w20-color`] = Color(color).mix(onWhite, 0.2).hex();
    style[`--${prefix}-w05-color`] = Color(color).mix(onWhite, 0.05).hex();
    style[`--${prefix}-a30-color`] = Color(color).alpha(0.3).toString();
    style[`--${prefix}-a00-color`] = Color(color).alpha(0).toString();
    // style[`--${prefix}-a70-color`] = Color(color).alpha(0.7).toString();

    style['--text-color'] = Color(color).isLight() ? '#333' : '#fefefe';
  } catch (error) {}
  return style;
};

export const locales = ['us', 'gb', 'au', 'ca'];

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

//split on _ and capitalize
export const humanReadable = (s: string) => {
  return s.split('_').map(capitalize).join(' ');
};

export const LOW_REVIEW_COUNT_CUTOFF = 150;

export const genreIcon = (genre: string) => {
  switch (genre) {
    case 'Books':
      return `https://devimages-cdn.apple.com/app-store/categories/images/books_2x.png`;
    case 'Business':
      return `https://devimages-cdn.apple.com/app-store/categories/images/business_2x.png`;
    case 'Developer Tools':
      return `https://devimages-cdn.apple.com/app-store/categories/images/developertools_2x.png`;
    case 'Education':
      return `https://devimages-cdn.apple.com/app-store/categories/images/education_2x.png`;
    case 'Entertainment':
      return `https://devimages-cdn.apple.com/app-store/categories/images/entertainment_2x.png`;
    case 'Finance':
      return `https://devimages-cdn.apple.com/app-store/categories/images/finance_2x.png`;
    case 'Food & Drink':
      return `https://devimages-cdn.apple.com/app-store/categories/images/foodanddrink_2x.png`;
    case 'Games':
      return `https://devimages-cdn.apple.com/app-store/categories/images/games_2x.png`;
    case 'Graphics & Design':
      return `https://devimages-cdn.apple.com/app-store/categories/images/graphicsanddesign_2x.png`;
    case 'Health & Fitness':
      return `https://devimages-cdn.apple.com/app-store/categories/images/fitness_2x.png`;
    case 'Lifestyle':
      return `https://devimages-cdn.apple.com/app-store/categories/images/lifestyle_2x.png`;
    case 'Kids':
      return `https://devimages-cdn.apple.com/app-store/categories/images/kids_2x.png`;
    case 'Magazines & Newspapers':
      return `https://devimages-cdn.apple.com/app-store/categories/images/magazinesandnewspapers_2x.png`;
    case 'Medical':
      return `https://devimages-cdn.apple.com/app-store/categories/images/medical_2x.png`;
    case 'Music':
      return `https://devimages-cdn.apple.com/app-store/categories/images/music_2x.png`;
    case 'Navigation':
      return `https://devimages-cdn.apple.com/app-store/categories/images/navigation_2x.png`;
    case 'News':
      return `https://devimages-cdn.apple.com/app-store/categories/images/news_2x.png`;
    case 'Photo & Video':
      return `https://devimages-cdn.apple.com/app-store/categories/images/photoandvideo_2x.png`;
    case 'Photography':
      return `https://devimages-cdn.apple.com/app-store/categories/images/photoandvideo_2x.png`;
    case 'Productivity':
      return `https://devimages-cdn.apple.com/app-store/categories/images/productivity_2x.png`;
    case 'Reference':
      return `https://devimages-cdn.apple.com/app-store/categories/images/reference_2x.png`;
    case 'Shopping':
      return `https://devimages-cdn.apple.com/app-store/categories/images/shopping_2x.png`;
    case 'Social Networking':
      return `https://devimages-cdn.apple.com/app-store/categories/images/socialnetworking_2x.png`;
    case 'Sports':
      return `https://devimages-cdn.apple.com/app-store/categories/images/sports_2x.png`;
    case 'Travel':
      return `https://devimages-cdn.apple.com/app-store/categories/images/travel_2x.png`;
    case 'Utilities':
      return `https://devimages-cdn.apple.com/app-store/categories/images/utilities_2x.png`;
    case 'Video':
      return `https://devimages-cdn.apple.com/app-store/categories/images/video_2x.png`;
    case 'Weather':
      return `https://devimages-cdn.apple.com/app-store/categories/images/weather_2x.png`;
    default:
      return undefined;
  }
};

export const getCountryCode = (url?: string) => {
  if (url == null) return undefined;
  const regex = 'apple.com/([^/]+)/';
  const found = url.match(regex);
  return found?.[1]?.toUpperCase?.();
};

const urlJoin = (a: string, b: string) => {
  return [trimEnd(a, '/'), trimStart(b, '/')].join('/');
};

export const apiUrl = (url: string, isAdmin: boolean) => {
  if (isAdmin) return urlJoin('/api/admin', url);
  else return urlJoin('/api', url);
};

export const filterConcepts = (
  concepts: Concept[] | undefined,
  conceptType: ConceptType | undefined,
  query?: string,
) => {
  if (concepts == null) return [];

  const lowerQuery = query?.toLowerCase();
  return concepts.filter((c) => {
    if (c.catType === conceptType) {
      const lowerName = c.name && c.name.toLowerCase();
      if (lowerName == null) {
        return false;
      }

      if (lowerQuery && lowerQuery.length > 0) return lowerName.indexOf(lowerQuery) >= 0;
      else return true;
    }
    return false;
  });
};

export const findAncestor = (el: any, cls: string): any => {
  if (el == null) return el;
  if (el.className?.indexOf(cls) >= 0) return el;

  const parentElement = el.parentElement;
  if (parentElement == null) return undefined;
  return findAncestor(parentElement, cls);
};

export const reportKey = (reportId: string, key: string) => reportId + key;

export const getSearch = (search: string | RegExp, caseSensitive?: boolean) => {
  if (search instanceof RegExp) return search;
  if (search == null || search.length === 0) return undefined;

  let flags = '';
  if (!caseSensitive) flags += 'i';

  if (typeof search === 'string') return new RegExp(escapeStringRegexp(search), flags);
  else return new RegExp(search, flags);
};

export const getSortedBrands2 = (
  brands?: ServerReportBrand[],
  reportCounts?: ServerDataCounts,
  sort?: string,
  sortOrder?: 'desc' | 'asc',
) => {
  if (brands == null) return undefined;
  const variable = 'desc' === sortOrder ? -1 : 1;

  if (sort == null || sort === 'name') {
    const sorted = sortBy(brands, (c) => c.name);
    if (sortOrder == 'desc') return reverse(sorted);
    else return sorted;
  } else if (sort === 'score') {
    return sortBy(brands, (c) => {
      const score = getScore(reportCounts?.brands?.[c._id.toHexString()]?.categoryScore);
      if (score != null) return score * variable;
      else return undefined;
    });
  } else if (sort === 'reviews') {
    return sortBy(brands, (c) => {
      const score = reportCounts?.brands?.[c._id.toHexString()]?.counts.processed;
      if (score != null) return score * variable;
      else return undefined;
    });
  } else {
    return sortBy(brands, (c) => {
      const categoryCounts = reportCounts?.brandsByCategory?.[sort];
      const score = categoryCounts?.[c._id.toHexString()]?.categoryScore;
      if (score != null) return score * variable;
      else return undefined;
    });
  }
};

export const pluralize = (singularWord: string, value: number) => {
  if (value === 1) return singularWord;
  else return singularWord + 's';
};

export const findNextPrevReview = (reviews: any, search?: any) => {
  if (reviews == null) return {};

  const reviewsKeys = reviews?.groups && Object.keys(reviews.groups).sort();
  const machine = search?.machine;
  const machineSplit = machine?.split('_');
  const machineId = machineSplit?.[0];
  let prevReview = null;
  let nextReview = null;
  for (var i = 0; i < reviewsKeys.length; i++) {
    const key = reviewsKeys[i];
    const groupedReviews = reviews?.groups[key];
    for (var j = 0; j < groupedReviews.length; j++) {
      const review = groupedReviews[j];
      if (review === machineId) {
        if (j - 1 >= 0) prevReview = groupedReviews[j - 1];
        else if (i > 0) {
          const prevKey = reviewsKeys[i - 1];
          const groupedReviews = reviews?.groups[prevKey];
          prevReview = groupedReviews[groupedReviews.length - 1];
        }

        if (j + 1 < groupedReviews.length - 1) nextReview = groupedReviews[j + 1];
        else if (i < reviewsKeys.length - 1) {
          const nextKey = reviewsKeys[i + 1];
          const groupedReviews = reviews?.groups[nextKey];
          nextReview = groupedReviews[0];
        }
        return { prevReview, nextReview };
      }
    }
  }
  const groupedReviews = reviews.groups[reviewsKeys[0]];
  if (groupedReviews) {
    return {
      prevReview: groupedReviews[0],
      nextReview: groupedReviews[0],
    };
  }
  return {};
};

export type SortOrder = -1 | 1;

export const getSortOrderNumber = (maybeOrder?: string | null): SortOrder | undefined => {
  if (maybeOrder == null) return undefined;
  else if (maybeOrder === '-1') return -1;
  else if (maybeOrder === '1') return 1;
  else return undefined;
};

export const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  const x = centerX + radius * Math.cos(angleInRadians);
  const y = centerY + radius * Math.sin(angleInRadians);
  return { x, y };
};

export const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

  return [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
    'L',
    x,
    y,
    'L',
    start.x,
    start.y,
  ].join(' ');
};

export const getDate = (date?: number | string | Date | null) => {
  if (date == null) return undefined;
  const momentDate = moment(date);
  if (momentDate.isValid()) return momentDate;
  else return undefined;
};
