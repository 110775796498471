import clsx from 'clsx';
import { sortBy } from 'lodash';
import React from 'react';
import { ServerDataCounts, ServerReportBrand, ServerVersionedApp } from '../../utils/trpc';
import { humanReadable } from '../../utils2';
import { ChevronIcon } from '../icons/currentColorIcons';
import styles from './detail.module.scss';

interface ExtrasSectionProps {
  extras: Record<string, string>;
}

export const ExtrasSection: React.FC<ExtrasSectionProps> = ({ extras }) => {
  const DEFAULT_KEY = 'other details';
  const [opened, setOpened] = React.useState<string>(DEFAULT_KEY);
  const extrasArray = React.useMemo(() => {
    const data: any = {};

    Object.entries(extras).forEach(([key, value], i) => {
      if (key == 'post_url' || key == 'title' || key.includes('url')) {
        return;
      }

      const splitKey = key.split('_');
      if (splitKey.length >= 2 && key !== 'post_date') {
        const sectionName = splitKey[0];
        const columnName = splitKey.slice(1).join(' ');
        if (sectionName) {
          const sortOrders = sectionName.match(/^(\d+)\.(\d+):(.*)$/);
          if (sortOrders && sortOrders[1] && sortOrders[2]) {
            const headerSort = Number.parseInt(sortOrders[1]);
            const valueSort = Number.parseInt(sortOrders[2]);
            const trimmedKey = sortOrders[3]?.trim();
            const correctedKey = trimmedKey ? headerSort + '_' + trimmedKey : sectionName;
            if (data[correctedKey] != null) {
              data[correctedKey][valueSort + '_' + columnName] = value;
            } else {
              data[correctedKey] = { [valueSort + '_' + columnName]: value };
            }
          } else {
            if (data[sectionName] != null) {
              data[sectionName][columnName] = value;
            } else {
              data[sectionName] = { [columnName]: value };
            }
          }
        }
      } else {
        if (data[DEFAULT_KEY] != null) {
          data[DEFAULT_KEY][key] = value;
        } else {
          data[DEFAULT_KEY] = { [key]: value };
        }
      }
    });

    const arrayData = sortBy(
      Object.entries(data).map(([key, value]: any) => {
        const sortOrders = key.match(/^(\d+)\.(\d+):(.*)$/);
        if (key.includes('_')) {
          const headerSort = key.split('_')?.[0];
          const valueSort = undefined;
          const correctedKey = key.split('_')?.[1];
          const newValue = sortBy(
            Object.entries(value).map(([key, value]) => {
              const splitKey = key.split('_');
              if (splitKey.length > 1) {
                return { key: splitKey[1], value, sort: splitKey[0] };
              } else {
                return { key, value, sort: undefined };
              }
            }),
            (d) => valueSort || d.sort || d.key,
          );
          return { key: correctedKey, value: newValue, sort: headerSort };
        } else if (sortOrders != null) {
          const headerSort = Number.parseInt(sortOrders[1]);
          const valueSort = Number.parseInt(sortOrders[2]);
          const correctedKey = sortOrders[3];

          const newValue = sortBy(
            Object.entries(value).map(([key, value]) => ({ key, value })),
            (d) => valueSort || d.key,
          );
          return { key: correctedKey, value: newValue, sort: headerSort };
        }
        const newValue = sortBy(
          Object.entries(value).map(([key, value]) => ({ key, value })),
          (d) => d.key,
        );
        return { key, value: newValue, headerSort: undefined };
      }),
      (d) => d.headerSort || d.key,
    );
    return arrayData;
  }, [extras]);

  return (
    <div className={styles.sections}>
      {extrasArray?.map((section) => {
        const isOpened = section.key === opened;
        return (
          <div
            className={clsx(styles.section, isOpened ? styles.opened : undefined)}
            key={section.key}>
            <div
              className={styles.sectionName}
              onClick={() => {
                setOpened(isOpened ? undefined : section.key);
              }}>
              <span>{section.key}</span>
              <ChevronIcon />
            </div>
            {isOpened && (
              <div className={styles.sectionRows}>
                {section.value.map((row: any, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className={styles.rowName}>
                        {row?.key ? humanReadable(row?.key) : ''}
                      </div>
                      <div className={styles.rowValue}>{row.value}</div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
