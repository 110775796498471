import { trpc } from '../utils/trpc';

export const useReportDeleteCollaboratorMutation = () => {
  const utils = trpc.useContext();

  return trpc.reportCollaborators.delete.useMutation({
    onSuccess: (_, { id }) => {
      utils.report.id.invalidate(id);
      utils.report.all.invalidate();
      utils.reportUsers.get.invalidate();
    },
  });
};
