import useComponentSize from '@rehooks/component-size';
import commaNumber from 'comma-number';
import React from 'react';
import { WaveSpinner } from '../../PoseidonComponents/WaveSpinner';
import GoodSelect from '../../components/Select/Select';
import Card from '../../components/card/card';
import { ReportCategory, ServerDataCounts, ServerReport } from '../../utils/trpc';
import { isNotNull } from '../../utils2';
import { useSelectFilters } from '../loversHatersCard/loversHatersCard';
import { BubbleChart } from './BubbleChart';
import { TraceRow } from './TraceRow';
import styles from './conceptSummaryCard.module.scss';

export interface BubbleData {
  id: string;
  label: string;
  value: number;
  posValue: number;
  negValue: number;
  posCount: number;
  negCount: number;
  count: number;
}

interface ConceptSummaryCardProps {
  filteredBrandIds?: string[];
  concepts?: ReportCategory[];
  report: ServerReport;
  dataCounts?: ServerDataCounts;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

export const ConceptSummaryCard: React.FC<ConceptSummaryCardProps> = ({
  filteredBrandIds,
  dataCounts,
  concepts,
  report,
  filter,
  setFilter,
}) => {
  if (concepts == null) return <LoadingCard />;
  if (dataCounts == null) return <LoadingCard />;

  return (
    <ConceptSummaryCardWithReport
      report={report}
      dataCounts={dataCounts}
      filter={filter}
      setFilter={setFilter}
      concepts={concepts}
      filteredBrandIds={filteredBrandIds}
    />
  );
};

interface ConceptSummaryCardWithReportProps {
  report: ServerReport;
  filteredBrandIds?: string[];
  concepts: ReportCategory[];
  filter?: string;
  dataCounts?: ServerDataCounts;
  setFilter?: React.Dispatch<React.SetStateAction<string>>;
}

const ConceptSummaryCardWithReport: React.FC<ConceptSummaryCardWithReportProps> = ({
  report,
  dataCounts,
  filteredBrandIds,
  concepts,
  filter,
  setFilter,
}) => {
  const ref = React.useRef(null);
  const { width, height } = useComponentSize(ref);

  const reportBrandIds = report.brands?.map((b) => b._id.toString());
  const reportDataCountsBrands = dataCounts?.brands;

  const reportDataCountsCounts = dataCounts?.count;
  const brandsByCategory = dataCounts?.brandsByCategory;
  const categories = dataCounts?.categories;

  const [_concepts, verbatimCount] = React.useMemo(() => {
    let _concepts = null;
    let verbatimCount = 0;
    const conceptsWithScores = concepts?.filter((c) => c.countInScore);
    if (filteredBrandIds == null) {
      verbatimCount = reportDataCountsCounts || 0;
      _concepts = conceptsWithScores?.map((concept) => {
        const scoreCount = categories?.[concept._id.toHexString()];
        return {
          _id: concept._id,
          name: concept.name,
          count: scoreCount?.counts?.categorized,
          positive: scoreCount?.posNegSentiment?.positive,
          negative: scoreCount?.posNegSentiment?.negative,
          neutral: scoreCount?.posNegSentiment?.neutral,
        };
      });
    } else {
      const counts = filteredBrandIds.map((id) => dataCounts?.brands?.[id]?.counts?.processed ?? 0);
      verbatimCount = counts.reduce((a, c) => a + c, 0);

      _concepts = conceptsWithScores
        ?.map((concept) => {
          const categoryScores = brandsByCategory?.[concept._id.toHexString()];

          const data = reportBrandIds?.reduce(
            (acc, brandId) => {
              const brandScore = categoryScores?.[brandId];
              if (filteredBrandIds.indexOf(brandId) < 0) return acc;
              return {
                count: acc.count + (brandScore?.counts?.categorized || 0),
                positive: acc.positive + (brandScore?.posNegSentiment?.positive || 0),
                negative: acc.negative + (brandScore?.posNegSentiment?.negative || 0),
                neutral: acc.neutral + (brandScore?.posNegSentiment?.neutral || 0),
              };
            },
            { count: 0, positive: 0, negative: 0, neutral: 0 },
          );

          return { _id: concept._id, name: concept.name, ...data };
        })
        .filter(isNotNull);
    }

    const sortedConcepts = _concepts
      ?.map((c) => ({ ...c, reportRatio: c.count / verbatimCount }))
      .filter((c) => c.reportRatio)
      .sort((a, b) => b.reportRatio - a.reportRatio);

    return [sortedConcepts, verbatimCount];
  }, [
    filteredBrandIds,
    concepts,
    brandsByCategory,
    categories,
    reportBrandIds,
    reportDataCountsBrands,
    reportDataCountsCounts,
  ]);

  const selectFiltes = useSelectFilters(report);
  let filters = undefined;
  if (selectFiltes?.length > 1) {
    filters = (
      <GoodSelect
        options={selectFiltes}
        value={filter}
        onChange={(e: any) => setFilter?.(e.value)}
      />
    );
  }

  const bubbleData: BubbleData[] =
    _concepts?.map((c) => ({
      id: c._id.toHexString(),
      label: c.name || c._id.toHexString(),
      value: 100 * c.reportRatio,
      posValue: c.positive / c.count,
      negValue: c.negative / c.count,
      posCount: c.positive,
      negCount: c.negative,
      count: c.count,
    })) ?? [];

  const highBubbleData = bubbleData.filter((c) => c.value >= 5);
  const lowBubbleData = bubbleData.filter((c) => c.value < 5);

  const size = Math.min(width, height);

  let description = `What concepts are prospects or customers talking about across verbatims.`;
  if (isNotNull(verbatimCount)) {
    description = `What concepts are prospects or customers talking about across <strong>${commaNumber(
      verbatimCount,
    )}</strong> verbatims.`;
  }

  return (
    <Card
      header="Concept Summary"
      description={description}
      fileName={`${report.name}_concept_summary.png`}
      filters={filters}>
      <div className={styles.container}>
        <div className={styles.bubbles} ref={ref}>
          {highBubbleData.length > 0 && size !== 0 && (
            <BubbleChart width={size} height={size} data={highBubbleData} />
          )}
        </div>

        {lowBubbleData.length > 0 && (
          <div className={styles.traces}>
            <div className={styles.bubbleChartTrace}>
              <div className={styles.bubbleChartTraceHeader}>Trace amounts</div>
              {lowBubbleData.map((d) => (
                <TraceRow data={d} key={d.id} />
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ConceptSummaryCard;

const LoadingCard = () => (
  <Card header="Concept Summary" description="What concepts are customers talking about">
    <WaveSpinner />
  </Card>
);
