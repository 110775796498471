import queryString from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import useRouterQuery from '../../hooks/useRouterQuery';
import {
  ConceptType,
  conceptTypeNameFromType,
  ReportAppDataCounts,
  ReportBrandDataCounts,
  ReportCategory,
} from '../../utils/trpc';
import { getRangeColor2, getScore } from '../../utils2';
import { Row } from '../sentimentRatingSection/Row';
import styles from './conceptsSection.module.scss';
import { AspectButtons } from './Row';

export interface DraggingProps {
  under?: boolean;
  conceptId?: string;
  originConceptId: string;
}

interface ConceptsSectionProps extends React.HTMLProps<HTMLDivElement> {
  countsByCategoryId: ReportAppDataCounts | ReportBrandDataCounts;
  concepts: ReportCategory[];
  catType: ConceptType;
  categoryFacetCounts: Map<string, number> | undefined;
  onAddConceptClicked?: () => void;
}

export const ConceptsSection: React.FC<ConceptsSectionProps> = React.memo(
  ({ countsByCategoryId, concepts, catType, categoryFacetCounts, onAddConceptClicked }) => {
    const [query, setQuery] = useRouterQuery();
    const addConceptSearch = queryString.stringify({
      ...query,
      addConcept: catType,
      hideDetail: undefined,
    });

    const onToggleAspect = (aspect: number) => () => {
      setQuery((s: any) => ({
        ...s,
        conceptAspect: parseInt(s.conceptAspect) === aspect ? undefined : aspect,
      }));
    };
    const onToggle = (v: string) => {
      const isPinned = query.pinnedConcept === v;
      setQuery((s: any) => ({
        ...s,
        category: s.category === v ? undefined : v,
        page: undefined,
        conceptAspect: undefined,
        addConcept: undefined,
        pinnedConcept: isPinned && s.category !== v ? undefined : s.pinnedConcept,
      }));
    };

    const conceptName = conceptTypeNameFromType(catType);

    const onPin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setQuery((q) => ({
        ...q,
        category: q.category === id ? undefined : q.category,
        pinnedConcept: q.pinnedConcept === id ? undefined : id,
        addConcept: q.pinnedConcept === id ? q.addConcept : undefined,
      }));
    };

    const valenceActiveIndex = concepts.findIndex(c => c._id.toHexString() === query.category)

    return (
      <div className={styles.concepts}>
        {concepts?.map((c, i) => {
          const catId = c._id.toHexString();
          const facetCount =
            categoryFacetCounts != null ? categoryFacetCounts?.get(catId) || 0 : undefined;

          const countDataForCategory = countsByCategoryId?.[c._id.toHexString()];

          // const icon = c.name ? conceptIconByName[c.name] : undefined;
          const count = facetCount != null ? facetCount : countDataForCategory?.catScore?.count;
          const rating = getScore(countDataForCategory?.catScore?.value);
          const color = getRangeColor2(rating);

          console.log("::", c.name, valenceActiveIndex < i ? i+1 : i)
          return (
            <React.Fragment key={catId}>
              <Row
                index={valenceActiveIndex < i ? i+1 : i}
                name={c.name || ''}
                color={color}
                onClick={() => onToggle(catId)}
                onPin={(e) => onPin(e, catId)}
                count={count}
                rating={rating}
                isPinned={query.pinnedConcept == catId}
                selected={query.category == catId}
                tooltip={<div>Click to see {c.name} Reviews</div>}
              />
              {catId === query.category && (
                <AspectButtons
                  concept={c}
                  index={i}
                  conceptAspect={query.conceptAspect}
                  onToggleAspect={onToggleAspect}
                />
              )}
            </React.Fragment>
          );
        })}

        <Link
          className={styles.newRowName}
          to={`?${addConceptSearch}`}
          onClick={onAddConceptClicked}>
          + Add {conceptName} Concept
        </Link>
      </div>
    );
  },
);

export default ConceptsSection;
