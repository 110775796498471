import React, { createContext } from 'react'
import toast from 'react-hot-toast'
import { AmplitudeContext } from '../libs/react-amplitude'
import { useAuthContext } from './auth.context'
import { ReviewContextPropsState, initialState, reducer } from './reviews.reducer'
import { ApiUrlPath, Fetch, HttpError } from './utils'

type ReviewContextPropsActions = {
  fetchTaggedReviews: (reportId: string, tag: string, body?: any) => void,
  deleteReviewResponse: (reportId: string, reviewId: string) => void,
  reset: () => void,
}

const initialActions: ReviewContextPropsActions = {
  fetchTaggedReviews: () => { throw new Error("fetchTaggedReviews not implemented") },
  deleteReviewResponse: () => { throw new Error("deleteReviewResponse not implemented") },
  reset: () => { throw new Error("reset not implemented") },
}

type ContextProps = [ReviewContextPropsState, ReviewContextPropsActions]

export const ReviewsContext = createContext<ContextProps>([initialState, initialActions]);

export const ReviewsProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { user } = useAuthContext();
  const { amplitudeInstance } = React.useContext(AmplitudeContext);
  const amplitudeInstanceDeviceId = amplitudeInstance.options.deviceId

  const fetchTaggedReviews = React.useCallback( async (reportId: string, tag: string, body: any) => {
    try {
      const url: ApiUrlPath = `/api/v2/reports/${reportId}/reviews/categories/${tag}`;
      
      // @TODO: add type, cast to any for now to zip up type checker
      const data: any = await Fetch.Get(user, url, body, amplitudeInstanceDeviceId)
      dispatch({ type: 'FETCHED_TAGGED_REVIEWS', reportId, tag, reviews: data && data.reviews });
    } catch (error) {
      console.error('fetchTaggedReviews', error)
    }
  }, [amplitudeInstanceDeviceId, user])

  const deleteReviewResponse = React.useCallback( async (reportId: string, reviewId: string) => {
    try {
      const url: ApiUrlPath = `/api/v2/reports/${reportId}/reviews/${reviewId}/response`;
      
      // @TODO: add type, cast to any for now to zip up type checker
      const data: any = await Fetch.Delete(user, url, undefined, amplitudeInstanceDeviceId)
      dispatch({ type: 'UPDATE_REVIEW_SUCCESS', reportId, reviewId, review: data.review })
      return data;
    } catch (error) { 
      const e = error as HttpError
      const message = e.body?.message || "could not delete review response"
      toast.error(message)
    }
  }, [amplitudeInstanceDeviceId, user])

  const reset = React.useCallback( () => dispatch({ type: 'RESET' }), [])

  const value: ContextProps = [
    state,
    {
      fetchTaggedReviews,
      deleteReviewResponse,
      reset,
    }
  ]

  return <ReviewsContext.Provider value={value}>
    {children}
  </ReviewsContext.Provider>
}

export const useReviewsContext = () => React.useContext(ReviewsContext)