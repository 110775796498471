import { trpc } from '../utils/trpc';

export const useUpdateDefaultTeamId = () => {
  const utils = trpc.useContext();

  return trpc.user.updateDefaultTeamId.useMutation({
    onSuccess: () => {
      utils.user.me.invalidate();
    },
  });
};
