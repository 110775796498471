import clsx from 'clsx';
import React from 'react';
import styles from './BrandSource2.module.scss';

interface BrandSourceProps extends React.HTMLProps<HTMLDivElement> {
  name?: string;
  icon: JSX.Element;
}

export const BrandSource: React.FC<BrandSourceProps> = ({ className, name, icon, style }) => {
  return (
    <div key={name} className={clsx(className, styles.brandSource)} style={style}>
      <div className={styles.brandSourceIcon}>{icon}</div>
      {name != null && <div className={styles.brandSourceName}>{name}</div>}
    </div>
  );
};

export default BrandSource;
