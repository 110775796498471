import React from 'react'
import { ServerReport, ServerReportUser, ServerUser } from '../../utils/trpc';
import Popup from '../popup/popup'
import Users from '../users/users'
import styles from './editTeamsPopup.module.scss';

interface EditTeamsPopupProps {
  report: ServerReport,
  user: ServerUser,
  onClose: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

export const EditTeamsPopup: React.FC<EditTeamsPopupProps> = ({report, user, onClose}) => {
  return <Popup className={styles.container} onClose={onClose}>
    <h4>Edit teams</h4>
    <Users report={report} user={user} />
  </Popup>
}

export default EditTeamsPopup;