import { useLocalStorage } from '@rehooks/local-storage';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Gear } from 'src/PoseidonComponents/StrokeIcons';
import { isStringNotEmpty } from 'src/utils2';
import { ServerTeam, ServerUser } from '../utils/trpc';
import { BreadCrumb, BreadCrumbLink, Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { LinkButton } from './Button/Button';
import { ContentDivider } from './ContentDivider/ContentDivider';
import { ContentSection } from './ContentSection/ContentSection';
import { IconWithPlaceholder } from './IconWithPlaceholder/IconWithPlaceholder';
import { Input } from './Input/Input';
import { PaddedScrollable } from './PaddedScrollable/PaddedScrollable';
import { PageHeader } from './PageHeader/PageHeader';
import { CreateButton } from './Button/CustomButton';
import { ReportsLeftNav } from '../components/LeftNav/LeftNav';
import { FullHeight } from './FullHeight/FullHeight';
import { WithLeftNav } from './Layout/Layout';
import { useTeamCreate } from '../trpcHooks/useTeamMutation';

interface Props {
  user: ServerUser;
  team: ServerTeam | undefined;
}

interface TeamFormData {
  name: string;
}
const SettingsTeamsCreate: React.FC<Props> = ({ user, team }) => {
  const [navMinimized] = useLocalStorage('navMinimized', false);
  const teamId = team?.team?._id.toHexString();
  const navigate = useNavigate();
  const teamCreateMutation = useTeamCreate();
  const { control, handleSubmit, watch } = useForm<TeamFormData>({
    mode: 'all',
  });

  const disabled = teamCreateMutation.isLoading;

  const onSubmit = async () => {
    await teamCreateMutation.mutateAsync({
      name,
    });
    void navigate('/teams');
  };

  const name = watch('name');

  const style = {
    '--nav-width': !navMinimized ? 'var(--nav-opened-width)' : 'var(--nav-closed-width)',
  } as React.CSSProperties;

  return (
    <FullHeight style={style}>
      <ReportsLeftNav user={user} team={team} />
      <WithLeftNav hasDetail={false}>
        <PageHeader icon={<Gear />} title="Settings">
          <ContentDivider
            end={
              <LinkButton size="small" to="/teams">
                Cancel
              </LinkButton>
            }>
            <Breadcrumbs showStartingChevron>
              <BreadCrumbLink to="/teams">Teams</BreadCrumbLink>
              {!isStringNotEmpty(name) && <BreadCrumb>?????</BreadCrumb>}
              {isStringNotEmpty(name) && <BreadCrumb>{name}</BreadCrumb>}
            </Breadcrumbs>
          </ContentDivider>
        </PageHeader>

        <PaddedScrollable>
          <ContentSection>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ContentSection>
                <IconWithPlaceholder size={150} alt="profile" urls={[]} shape="circle" />

                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input
                      title="Name"
                      placeholder="Your Team's name"
                      disabled={disabled}
                      // length="restricted"
                      autoFocus
                      {...field}
                    />
                  )}
                />

                <CreateButton isLoading={teamCreateMutation.isLoading} />
              </ContentSection>
            </form>
          </ContentSection>
        </PaddedScrollable>
      </WithLeftNav>
    </FullHeight>
  );
};

export default SettingsTeamsCreate;
