import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import useForm from '../../hooks/useForm';
import { RQUtils, suggestedClustersListRQKey } from '../../utils/reactQuery.utils';
import {
  CompactReportPhrase,
  SuggestedCluster,
  trpc,
  UpdateSuggestedCluster,
} from '../../utils/trpc';
import Popup from '../popup/popup';
import { Input } from 'src/PoseidonComponents/Input/Input';

interface AddWordInputProps {
  words: any;
  onSubmit: any;
}
const AddWordInput: React.FC<AddWordInputProps> = ({ words, onSubmit }) => {
  const { values, handleChange, registerSubmit } = useForm({ words, word: '' });

  const _onSubmit = async ({ values }: any) => {
    try {
      const wordsSet = new Set(values.words);
      values.word.split(',').forEach((word: any) => {
        wordsSet.add(word);
      });

      const words = Array.from(wordsSet);
      onSubmit(words);
    } catch (error) {}
  };

  return (
    <form onSubmit={registerSubmit(_onSubmit)}>
      <Input value={values.word} name="word" onChange={handleChange} />
    </form>
  );
};

interface EditSuggestedClusterPopupProps {
  reportId: string;
  suggestedCluster: SuggestedCluster;
  reportPhrasesById: Record<string, CompactReportPhrase> | undefined;
  onUpdate: (updatedCluster: SuggestedCluster) => void;
  onClose: (suggestedCluster: SuggestedCluster) => void;
}

export const EditSuggestedClusterPopup: React.FC<EditSuggestedClusterPopupProps> = ({
  suggestedCluster,
  reportPhrasesById,
  reportId,
  onClose,
  onUpdate,
}) => {
  const queryClient = useQueryClient();

  const updateClusterMut = trpc.suggestedClusters.update.useMutation({
    onSuccess: (updatedCluster) => {
      // update the hidden and not hidden suggested clusters queries
      queryClient.setQueryData<SuggestedCluster[]>(suggestedClustersListRQKey(reportId), (old) => {
        // cluster element was updated
        return RQUtils.updateArrQuery(old, updatedCluster);
      });

      onUpdate(updatedCluster);
    },
  });
  const updateCluster = (cluster: SuggestedCluster, newValues: UpdateSuggestedCluster) => {
    if (updateClusterMut.isLoading == true) {
      return;
    }
    updateClusterMut.mutate({ reportId, clusterId: cluster.id, newValues: newValues });
  };

  const { values, handleChange, registerSubmit } = useForm({ name: suggestedCluster.name });

  const id = suggestedCluster.id;

  const onSubmit = async ({ values }: any) => {
    const updateValues: UpdateSuggestedCluster = {
      name: values['name'],
    };
    updateCluster(suggestedCluster, updateValues);
  };

  return (
    <Popup onClose={() => onClose(suggestedCluster)}>
      <h4>Edit suggested cluster</h4>

      <form onSubmit={registerSubmit(onSubmit)}>
        <Input placeholder="cluster name" value={values.name} name="name" onChange={handleChange} />
      </form>

      {/* <div className={styles.words}>
      {suggestedCluster.phraseIds?.map( (pId, i) => <DeletablePill text={w} key={w + i} onDelete={() => deleteWord(w)} />)}
    </div>
    <AddWordInput words={dataWords} onSubmit={(words: any) => updateWords({words})} /> */}
    </Popup>
  );
};

export default EditSuggestedClusterPopup;
