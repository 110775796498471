import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'src/PoseidonComponents/Button/Button';
import { ContentSection } from 'src/PoseidonComponents/ContentSection/ContentSection';
import { Input } from 'src/PoseidonComponents/Input/Input';
import { Popup } from 'src/PoseidonComponents/Popup2/Popup2';
import { Mail, TrashCan } from 'src/PoseidonComponents/StrokeIcons';
import { SvgIconButton } from 'src/PoseidonComponents/SvgIconButton/SvgIconButton';
import { Cell } from 'src/PoseidonComponents/Table/Table';
import Tooltip from 'src/libs/context-tooltip/tooltip';
import { sourcesById } from 'src/sources';
import { AppLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import { ShortStars } from '../../PoseidonComponents/ShortStars/ShortStars';
import { useReportAppUpdateMutation } from '../../trpcHooks/useReportAppMutation';
import { ServerReportApp, ServerSearchApp } from '../../utils/trpc';
import styles from './appRow.module.scss';

interface AppRowProps extends React.HTMLProps<HTMLDivElement> {
  app: ServerReportApp;
  deleteApp?: (id: string) => void;
  index: number;
  source?: string | undefined;
  reportId: string;
}

interface InviteButtonForm {
  iconUrl: string;
}

export const AppRow: React.FC<AppRowProps> = ({ app, reportId, source, index, deleteApp }) => {
  const sourceIcon = source ? sourcesById?.[source] : undefined;
  const updateReportMutation = useReportAppUpdateMutation();

  const [isPopup, setIsPopup] = React.useState(false);

  const metaId = app.metaId.toHexString();

  const { control, handleSubmit, resetField } = useForm<InviteButtonForm>({
    mode: 'all',
  });

  const onSubmit = async (data: InviteButtonForm) => {
    updateReportMutation.mutate({ reportId, data, metaId });

    setIsPopup(false);
    resetField('iconUrl');
  };

  return (
    <React.Fragment key={index}>
      <Cell index={index}>
        {sourceIcon?.icon && <div className={styles.icon}>{sourceIcon?.icon}</div>}
      </Cell>
      <Cell index={index}>
        <Tooltip
          className={styles.changeIconTooltip}
          onClick={() => setIsPopup(true)}
          tagName="span"
          content="Change Icon">
          <AppLogo app={app} size={16} shape="roundedSquare" />
        </Tooltip>
      </Cell>
      <Cell index={index}>{app.title}</Cell>
      <Cell index={index} align="center">
        <ShortStars value={app.score} />
      </Cell>
      <Cell index={index} align="center">
        <SvgIconButton
          icon={<TrashCan />}
          size="small"
          type="dangerous"
          onClick={() => (deleteApp ? deleteApp(app.metaId.toString()) : undefined)}
        />
      </Cell>
      {isPopup && (
        <Popup onClose={() => setIsPopup(false)}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ContentSection className={styles.popupImg}>
              <Controller
                control={control}
                name="iconUrl"
                render={({ field }) => (
                  <Input size="small" icon={<Mail />} placeholder="iconUrl" {...field} />
                )}
              />
              <Button size="small" type="submit">
                Submit
              </Button>
            </ContentSection>
          </form>
        </Popup>
      )}
    </React.Fragment>
  );
};

interface SearchAppRowProps extends React.HTMLProps<HTMLDivElement> {
  app: ServerSearchApp;
  addApp: (data: ServerSearchApp) => void;
  index: number;
  provider: string | undefined;
  color?: 'yellow';
}

export const SearchAppRow: React.FC<SearchAppRowProps> = ({
  app,
  provider,
  addApp,
  index,
  color,
}) => {
  const onClick = () => addApp(app);
  const sourceIcon = provider ? sourcesById?.[provider] : undefined;
  return (
    <React.Fragment key={app.appId}>
      <Cell index={index} onClick={onClick} color={color}>
        {sourceIcon?.icon && <div className={styles.icon}>{sourceIcon?.icon}</div>}
      </Cell>
      <Cell index={index} onClick={onClick} color={color}>
        <AppLogo app={app} size={16} shape="roundedSquare" />
      </Cell>
      <Cell index={index} onClick={onClick} color={color}>
        {app.title}
      </Cell>
      <Cell index={index} align="center" onClick={onClick} color={color}>
        <ShortStars value={app.score} />
      </Cell>
    </React.Fragment>
  );
};

export default AppRow;
