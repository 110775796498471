import useLocalStorage from '@rehooks/local-storage';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../PoseidonComponents/Breadcrumbs/Breadcrumbs';
import { ConceptDetailAddPage } from '../../PoseidonComponents/ConceptDetailAddPage/ConceptDetailAddPage';
import {
  ConceptDetailPage,
  PinnedConceptDetailPage,
} from '../../PoseidonComponents/ConceptDetailPage/ConceptDetailPage';
import { ContentDivider } from '../../PoseidonComponents/ContentDivider/ContentDivider';
import Filters from '../../PoseidonComponents/Filters/Filters';
import { BrandLogo } from '../../PoseidonComponents/IconWithPlaceholder/IconWithPlaceholder';
import { Flex, WithFilters, WithLeftNav } from '../../PoseidonComponents/Layout/Layout';
import { PageHeader } from '../../PoseidonComponents/PageHeader/PageHeader';
import { ReportFilters } from '../../PoseidonComponents/ReportFilters';
import { ReportProviderPicker } from '../../PoseidonComponents/ReportProviderPicker';
import { FullPageWaveSpinnerContainer } from '../../PoseidonComponents/WaveSpinnerContainer/WaveSpinnerContainer';
import Reviews from '../../components/reviews/reviews2';
import { VersionAppHistory } from '../../components/versionHistory/versionHistory';
import useRouterQuery from '../../hooks/useRouterQuery';
import { IIntervalBucket } from '../../models/aggModels/intervalBucket.entity';
import { DateTypeValue } from '../../models/dateTypeValue.model';
import {
  IntervalAndDate,
  ReportCategory,
  ReviewExtraColFromList,
  ReviewExtraColSectionFromList,
  ServerBrand,
  ServerDataCounts,
  ServerReport,
  ServerReportApp,
  ServerUser,
  trpc,
} from '../../utils/trpc';
import { ConceptReviewDetailPage } from 'src/PoseidonComponents/ConceptReviewDetailPage';

interface ReportBrandAppContainerProps {
  report: ServerReport;
  conceptsById: Record<string, ReportCategory>;
  dateType?: string;
  dateValue?: number;
  intervalBucket: IIntervalBucket;
  reportConcepts?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  dataCounts?: ServerDataCounts;
  user: ServerUser;
}

export const ReportBrandAppContainer: React.FC<ReportBrandAppContainerProps> = ({
  report,
  intervalBucket,
  reportConcepts,
  conceptsById,

  extraCols,
  extraColsSections,
  dataCounts,
  user,
}) => {
  const reportId = report._id.toString();
  const { brandId, metaId } = useParams();
  const [query] = useRouterQuery();

  const reportBrandQuery = trpc.reportBrand.id.useQuery(
    { brandId: brandId || '', reportId },
    { enabled: Boolean(brandId) },
  );
  const brand = reportBrandQuery.data;
  const app = report.apps?.find((app) => app.metaId.toString() === metaId);
  const appMetaId = app?.metaId.toHexString();

  const hasDetail =
    query.pinnedConcept != null ||
    query.rId != null ||
    query.category != null ||
    query.addConcept != null;

  if (brand == null || app == null) {
    return (
      <WithLeftNav hasDetail={hasDetail}>
        <PageHeader icon={<BrandLogo brand={brand} shape="circle" size={24} />} title={'???'}>
          <ContentDivider end={<ReportFilters />} />
        </PageHeader>
        <Flex gap="0" grow={1}>
          <FullPageWaveSpinnerContainer />
        </Flex>
      </WithLeftNav>
    );
  }

  return (
    <WithBrandAndApp
      brand={brand}
      app={app}
      report={report}
      conceptsById={conceptsById}
      intervalBucket={intervalBucket}
      reportConcepts={reportConcepts}
      extraCols={extraCols}
      extraColsSections={extraColsSections}
      dataCounts={dataCounts}
      user={user}
    />
  );
};

interface WithBrandAndAppProps {
  report: ServerReport;
  conceptsById: Record<string, ReportCategory>;
  intervalBucket: IIntervalBucket;
  reportConcepts?: ReportCategory[];
  extraCols: ReviewExtraColFromList[] | undefined;
  extraColsSections: ReviewExtraColSectionFromList[] | undefined;
  dataCounts?: ServerDataCounts;
  brand: ServerBrand;
  app: ServerReportApp;
  user: ServerUser;
}
const WithBrandAndApp: React.FC<WithBrandAndAppProps> = ({
  brand,
  app,
  report,
  conceptsById,
  intervalBucket,
  reportConcepts,
  extraCols,
  extraColsSections,
  dataCounts,
  user,
}) => {
  const reportId = report._id.toHexString();
  const appId = app.metaId.toHexString();
  const [query] = useRouterQuery();

  const versionHistoryIntervalType = intervalBucket?.type ?? 'month';

  const [dateTypeValue] = useLocalStorage<DateTypeValue>('dateTypeValue', {
    type: undefined,
    value: undefined,
  });
  const dateValue = dateTypeValue.value;
  const dateType = dateTypeValue.type;

  const intervalAndDate: IntervalAndDate =
    dateValue != null && dateType != null
      ? {
          type: dateType as any,
          dateMillis: dateValue,
        }
      : { type: 'infinity', dateMillis: 0 };

  const reviewsFacetsRes = trpc.reportReviews.facetsForApp.useQuery(
    {
      reportId,
      appId,
      query: query.q,
      filters: {
        category: query.category,
        rating: query.rating != null ? parseFloat(query.rating) : undefined,
        sRating: query.sRating != null ? parseInt(query.sRating, 10) : undefined,
        conceptAspect: query.conceptAspect != null ? parseInt(query.conceptAspect) : undefined,
      },
      extrasFilters: query.extras,
      intervalAndDate: intervalAndDate,
      pagination: {
        perPage: 30,
        page: query.page || 0,
      },
    },
    { enabled: Boolean(app?.metaId.toHexString()) },
  );
  const reviewsFacets = reviewsFacetsRes.data;

  const reportReviewsQuery = trpc.reportReviews.forAppOld.useInfiniteQuery(
    {
      reportId,
      appId,
      intervalAndDate,
      query: query.q,
      filters: {
        rating: query.rating ? Number.parseFloat(query.rating) : undefined,
        sRating: query.sRating ? Number.parseFloat(query.sRating) : undefined,
        conceptAspect: query.conceptAspect ? Number.parseFloat(query.conceptAspect) : undefined,
        category: query.category,
      },
      extrasFilters: query.extras,
      limit: query.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: query.page,
    },
  );

  const firstReviewId = reportReviewsQuery.data?.pages?.[0]?.reviews?.[0]?._id.toHexString();

  const hasDetail =
    query.pinnedConcept != null ||
    query.rId != null ||
    query.category != null ||
    query.addConcept != null ||
    query.machine != null;

  return (
    <WithLeftNav hasDetail={hasDetail}>
      <PageHeader
        icon={<BrandLogo brand={brand} shape="circle" size={24} />}
        title={brand?.name ?? 'Brand'}>
        <ContentDivider end={<ReportFilters firstReviewId={firstReviewId} />}>
          <Breadcrumbs showStartingChevron>
            <ReportProviderPicker
              reportId={reportId}
              brand={brand}
              appId={app.metaId.toHexString()}
            />
          </Breadcrumbs>
        </ContentDivider>
      </PageHeader>

      <Flex gap="0" grow={1}>
        {app && (
          <Filters
            report={report}
            reportCategories={reportConcepts}
            app={app}
            intervalBucket={intervalBucket}
            extraCols={extraCols}
            extraColsSections={extraColsSections}
            appIdsFilter={new Set<string>(appId ? [appId] : [])}
            facets={reviewsFacets}
          />
        )}

        <WithFilters>
          {app && query.section == 'versionHistory' && (
            <VersionAppHistory
              intervalType={versionHistoryIntervalType}
              categoryId={query.category}
              reportId={reportId}
              metaId={appId}
            />
          )}
          {query.section != 'versionHistory' && (
            <Reviews
              report={report}
              reviews={reportReviewsQuery.data?.pages}
              conceptsById={conceptsById}
              fetchNextPage={
                reportReviewsQuery.hasNextPage ? reportReviewsQuery.fetchNextPage : undefined
              }
              fetchPreviousPage={
                reportReviewsQuery.hasPreviousPage
                  ? reportReviewsQuery.fetchPreviousPage
                  : undefined
              }
              isFetchingNextPage={reportReviewsQuery.isFetchingNextPage}
              isFetchingPreviousPage={reportReviewsQuery.isFetchingPreviousPage}
            />
          )}
        </WithFilters>
      </Flex>

      {hasDetail && (
        <ConceptReviewDetailPage
          report={report}
          user={user}
          dataCounts={dataCounts}
          conceptsById={conceptsById}
        />
      )}
    </WithLeftNav>
  );
};
