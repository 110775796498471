import React from 'react';
import { Link } from 'react-router-dom';
import { AndroidServiceAccountIntegration } from 'src/components/integrations/androidIntegration';
import { AppleIntegration } from 'src/components/integrations/appleIntegration';
import { ReportProps } from '../../types';
import { ContentDivider } from '../ContentDivider/ContentDivider';
import { ContentSection } from '../ContentSection/ContentSection';

export const IntegrationsSection: React.FC<ReportProps> = ({ report }) => {
  return (
    <ContentSection>
      <ContentDivider
        end={
          <Link to="/integrations-info" target="_blank">
            Learn how
          </Link>
        }>
        Integrations
      </ContentDivider>
      <AppleIntegration report={report} />
      <AndroidServiceAccountIntegration report={report} />
    </ContentSection>
  );
};
