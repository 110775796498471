import React, { CSSProperties } from 'react';
import Icon from '../../components/icon/icon';
import { Market } from '../../components/icons/icons';
import { ServerBrandDataCounts, ServerReportBrand } from '../../utils/trpc';
import { getColor } from '../../utils2';
import styles from './PolarCard2.module.scss';

interface BrandPickerProps {
  selectedBrands: (ServerReportBrand | undefined | null)[];
  setShowingPopupIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  brandCounts?: Record<string, ServerBrandDataCounts>;
}

export const BrandPicker: React.FC<BrandPickerProps> = ({
  selectedBrands,
  setShowingPopupIndex,
  brandCounts,
}) => {
  return (
    <div className={styles.brandPicker}>
      {selectedBrands.map((brand, i) => {
        const color = getColor(i);
        const style = { '--color': color } as CSSProperties;
        const onClick = setShowingPopupIndex ? () => setShowingPopupIndex(i) : undefined;

        if (brand === undefined) {
          return (
            <div key={i} className={styles.iconBucketWrap} style={style} onClick={onClick}>
              <div className={styles.brandPlaceholderIconBucket} />
            </div>
          );
        }
        if (brand === null) {
          return (
            <div key={i} className={styles.iconBucketWrap} style={style} onClick={onClick}>
              <div className={styles.iconBucket}>
                <Icon src={Market} rounded />
              </div>
              <div className={styles.iconBucketContent}>
                <div className={styles.iconBucketContentHeader}>Market benchmark</div>
              </div>
            </div>
          );
        }

        const brandDataId = brand._id.toHexString();
        const brandCount = brandDataId ? brandCounts?.[brandDataId] : undefined;

        const rankValue = brandCount?.ratingRank?.value;
        const outOf = brandCount?.ratingRank?.outOf;
        return (
          <div key={i} className={styles.iconBucketWrap} style={style} onClick={onClick}>
            <div className={styles.iconBucket}>
              <Icon src={brand?.imageUrl} rounded background={brand?.dominantColor} />
            </div>
            <div className={styles.iconBucketContent}>
              <div className={styles.iconBucketContentHeader}>{brand?.name}</div>
              {rankValue > 1 && (
                <div className={styles.iconBucketContentSubHeader}>
                  #{rankValue} out of {outOf}
                </div>
              )}
              {rankValue === 1 && (
                <div className={styles.iconBucketContentSubHeader}>Market Leader</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
